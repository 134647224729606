import Checkbox from "@/components/inputs/Checkbox";
import {
  CALCULATED_FACT_LABELS,
  CalculatedFact,
  CSVExportConfig,
  WHITELISTED_FACTS,
} from "@/types/export";
import { FACT_GROUPS, FactType, getFactLabel } from "@/types/facts";
import React, { useEffect, useRef, useState } from "react";

export interface CSVExportSidebarProps {
  onClose: () => void;
  onExport: (config: CSVExportConfig) => void;
  hasAssessmentContexts?: boolean;
}

export const CSVExportSidebar: React.FC<CSVExportSidebarProps> = ({
  onClose,
  onExport,
  hasAssessmentContexts = false, // Default to false if not provided
}) => {
  const [config, setConfig] = useState<CSVExportConfig>({
    includeAssessmentQuestions: false, // Default to false
  });

  // Refs for group checkboxes to handle indeterminate state
  const personCheckboxRef = useRef<HTMLInputElement>(null);
  const companyCheckboxRef = useRef<HTMLInputElement>(null);
  const calculatedFactsCheckboxRef = useRef<HTMLInputElement>(null);

  // Memoize these arrays to ensure stable references for comparison
  const personFieldsRef = useRef<FactType[]>([]);
  const companyFieldsRef = useRef<FactType[]>([]);

  // Initialize field references
  useEffect(() => {
    // Filter person facts based on whitelist
    personFieldsRef.current = filterWhitelistedFacts(FACT_GROUPS.person);

    // Filter company facts based on whitelist
    companyFieldsRef.current = filterWhitelistedFacts(FACT_GROUPS.company);

    // Force a rerender after initialization to ensure all fact categories are visible
    setConfig((prev) => ({ ...prev }));
  }, []);

  // Only allow facts from the whitelist
  const filterWhitelistedFacts = (facts: string[]): FactType[] => {
    return facts.filter((fact) => WHITELISTED_FACTS.includes(fact as FactType)) as FactType[];
  };

  const getFilteredFactGroup = (groupName: keyof typeof FACT_GROUPS): FactType[] => {
    if (groupName === "person") return personFieldsRef.current;
    if (groupName === "company") return companyFieldsRef.current;
    return []; // Return empty array for other groups as they're not in the whitelist
  };

  const getDeduplicatedCompanyFacts = (): FactType[] => {
    return companyFieldsRef.current;
  };

  // Get calculated facts, excluding the tenure fact which will be handled separately
  const getCommonCalculatedFacts = (): CalculatedFact[] => {
    return Object.values(CalculatedFact).filter(
      (fact) => fact !== CalculatedFact.TenureAtCurrentCompany,
    );
  };

  const toggleFact = (factKey: FactType | CalculatedFact) => {
    setConfig((prev) => ({
      ...prev,
      [factKey]: !prev[factKey],
    }));
  };

  const isPersonFieldsGroup = (group: FactType[]): boolean => {
    const personFields = getFilteredFactGroup("person");
    if (!Array.isArray(group) || !Array.isArray(personFields)) return false;
    if (group.length !== personFields.length) return false;

    return group.every((item, index) => item === personFields[index]);
  };

  const toggleGroup = (group: FactType[], value: boolean) => {
    const newConfig = { ...config };
    group.forEach((factKey) => {
      newConfig[factKey] = value;
    });

    // If toggling person fields, also toggle Tenure at Current Company
    if (isPersonFieldsGroup(group)) {
      newConfig[CalculatedFact.TenureAtCurrentCompany] = value;
    }

    setConfig(newConfig);
  };

  const toggleCalculatedFactsGroup = (value: boolean) => {
    const newConfig = { ...config };
    getCommonCalculatedFacts().forEach((factKey) => {
      newConfig[factKey] = value;
    });
    setConfig(newConfig);
  };

  const toggleAll = (value: boolean) => {
    const newConfig = { ...config };

    const allFacts = [...getFilteredFactGroup("person"), ...getDeduplicatedCompanyFacts()];

    allFacts.forEach((factKey) => {
      newConfig[factKey] = value;
    });

    // Toggle all calculated facts, including tenure
    Object.values(CalculatedFact).forEach((factKey) => {
      newConfig[factKey] = value;
    });

    setConfig(newConfig);
  };

  const isGroupSelected = (group: FactType[]) => {
    // For person fields, also check Tenure at Current Company
    if (isPersonFieldsGroup(group)) {
      return (
        group.every((factKey) => config[factKey]) && !!config[CalculatedFact.TenureAtCurrentCompany]
      );
    }
    return group.every((factKey) => config[factKey]);
  };

  const isSomeGroupSelected = (group: FactType[]) => {
    // For person fields, also check Tenure at Current Company
    if (isPersonFieldsGroup(group)) {
      const someRegularFields = group.some((factKey) => config[factKey]);
      const allRegularFields = group.every((factKey) => config[factKey]);
      const tenureSelected = !!config[CalculatedFact.TenureAtCurrentCompany];

      return (someRegularFields || tenureSelected) && !(allRegularFields && tenureSelected);
    }
    return group.some((factKey) => config[factKey]) && !isGroupSelected(group);
  };

  const countSelectedInGroup = (group: FactType[]) => {
    const regularCount = group.filter((factKey) => config[factKey]).length;

    // Include tenure option in person fields count
    if (isPersonFieldsGroup(group) && config[CalculatedFact.TenureAtCurrentCompany]) {
      return regularCount + 1;
    }

    return regularCount;
  };

  // Get the total count of person fields including tenure
  const getPersonFieldsTotalCount = () => {
    return getFilteredFactGroup("person").length + 1; // +1 for Tenure
  };

  const areCalculatedFactsSelected = () => {
    return getCommonCalculatedFacts().every((factKey) => config[factKey]);
  };

  const areSomeCalculatedFactsSelected = () => {
    const commonFactsSelected = getCommonCalculatedFacts().filter(
      (factKey) => config[factKey],
    ).length;
    const totalCommonFacts = getCommonCalculatedFacts().length;

    // Check if some but not all facts are selected
    return commonFactsSelected > 0 && !(commonFactsSelected === totalCommonFacts);
  };

  const countSelectedCalculatedFacts = () => {
    const commonFactsCount = getCommonCalculatedFacts().filter((factKey) => config[factKey]).length;
    return commonFactsCount;
  };

  const handleExport = () => {
    // With whitelist, we don't need to filter the config
    onExport(config);
    onClose();
  };

  // Update indeterminate state of checkboxes
  React.useEffect(() => {
    if (personCheckboxRef.current) {
      personCheckboxRef.current.indeterminate = isSomeGroupSelected(getFilteredFactGroup("person"));
    }
    if (companyCheckboxRef.current) {
      companyCheckboxRef.current.indeterminate = isSomeGroupSelected(getDeduplicatedCompanyFacts());
    }
    if (calculatedFactsCheckboxRef.current) {
      calculatedFactsCheckboxRef.current.indeterminate = areSomeCalculatedFactsSelected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <div className="flex flex-col gap-4 w-full">
      <p className="text-sm">
        Select the fields you want to include in your CSV export. You can select individual fields
        or entire categories.
      </p>

      <div className="flex gap-2 mb-4">
        <button
          className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-md"
          onClick={() => toggleAll(true)}
        >
          Select All
        </button>
        <button
          className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-md"
          onClick={() => toggleAll(false)}
        >
          Deselect All
        </button>
      </div>

      <div className="border-t border-gray-200 pt-4">
        {/* Calculated Facts */}
        <div className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <Checkbox
              ref={calculatedFactsCheckboxRef}
              checked={areCalculatedFactsSelected()}
              onChange={() => toggleCalculatedFactsGroup(!areCalculatedFactsSelected())}
              label="Common Fields"
              labelClassName="font-medium"
            />
            <span className="text-xs text-gray-500">
              {countSelectedCalculatedFacts()}/{getCommonCalculatedFacts().length + 1}
            </span>
          </div>
          <div className="ml-6 grid grid-cols-1 gap-1">
            {getCommonCalculatedFacts().map((factKey) => (
              <Checkbox
                key={factKey}
                checked={!!config[factKey]}
                onChange={() => toggleFact(factKey)}
                label={CALCULATED_FACT_LABELS[factKey]}
                labelClassName="text-sm font-normal"
              />
            ))}
          </div>
        </div>

        {/* Person Facts */}
        <div className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <Checkbox
              ref={personCheckboxRef}
              checked={isGroupSelected(getFilteredFactGroup("person"))}
              onChange={() =>
                toggleGroup(
                  getFilteredFactGroup("person"),
                  !isGroupSelected(getFilteredFactGroup("person")),
                )
              }
              label="Person Fields"
              labelClassName="font-medium"
            />
            <span className="text-xs text-gray-500">
              {countSelectedInGroup(getFilteredFactGroup("person"))}/{getPersonFieldsTotalCount()}
            </span>
          </div>
          <div className="ml-6 grid grid-cols-1 gap-1">
            {/* Add Tenure at Current Company here */}
            <Checkbox
              key={CalculatedFact.TenureAtCurrentCompany}
              checked={!!config[CalculatedFact.TenureAtCurrentCompany]}
              onChange={() => toggleFact(CalculatedFact.TenureAtCurrentCompany)}
              label={CALCULATED_FACT_LABELS[CalculatedFact.TenureAtCurrentCompany]}
              labelClassName="text-sm font-normal"
            />
            {getFilteredFactGroup("person").map((factKey) => (
              <Checkbox
                key={factKey}
                checked={!!config[factKey]}
                onChange={() => toggleFact(factKey)}
                label={getFactLabel(factKey)}
                labelClassName="text-sm font-normal"
              />
            ))}
          </div>
        </div>

        {/* Company Facts */}
        <div className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <Checkbox
              ref={companyCheckboxRef}
              checked={isGroupSelected(getDeduplicatedCompanyFacts())}
              onChange={() =>
                toggleGroup(
                  getDeduplicatedCompanyFacts(),
                  !isGroupSelected(getDeduplicatedCompanyFacts()),
                )
              }
              label="Company Fields"
              labelClassName="font-medium"
            />
            <span className="text-xs text-gray-500">
              {countSelectedInGroup(getDeduplicatedCompanyFacts())}/
              {getDeduplicatedCompanyFacts().length}
            </span>
          </div>
          <div className="ml-6 grid grid-cols-1 gap-1">
            {getDeduplicatedCompanyFacts().map((factKey) => (
              <Checkbox
                key={factKey}
                checked={!!config[factKey]}
                onChange={() => toggleFact(factKey)}
                label={getFactLabel(factKey)}
                labelClassName="text-sm font-normal"
              />
            ))}
          </div>
        </div>
      </div>

      {/* Assessment Questions Option */}
      {hasAssessmentContexts && (
        <div className="border-t border-gray-200 pt-4 mt-4 mb-20">
          <div className="flex items-center justify-between mb-2">
            <Checkbox
              checked={!!config.includeAssessmentQuestions}
              onChange={() =>
                setConfig((prev) => ({
                  ...prev,
                  includeAssessmentQuestions: !prev.includeAssessmentQuestions,
                }))
              }
              label="Include Assessment Questions"
              labelClassName="font-medium"
            />
          </div>
          <p className="text-xs text-gray-500 ml-6">
            Include assessment questions and answers for each entity in the export. This may make
            the export process take longer.
          </p>
        </div>
      )}

      <div className="fixed bg-white shadow-card bottom-0 right-0 w-full p-3">
        <div className="flex justify-end gap-2">
          <button
            className="px-4 py-2 text-gray-600 hover:text-gray-700 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-brand-600 text-white hover:bg-brand-700 rounded-md"
            onClick={handleExport}
          >
            Export to CSV
          </button>
        </div>
      </div>
    </div>
  );
};

export default CSVExportSidebar;
