"use client";
import API from "@/client/api";
import EntityContent, { EntityViewType } from "@/components/entities/EntityContent";
import EntityStoresProvider from "@/components/providers/EntityStoresProvider";
import { useSidebar } from "@/components/providers/SidebarProvider";
import Loader from "@/components/ui/Loader";
import { useCachedAPI } from "@/hooks/swr";
import { prettyError } from "@/lib/miscUtils";
import { uiStore } from "@/stores/uiStore";
import { useEffect } from "react";
import { toast } from "react-toastify";

type CommonProps = {
  onError?: (error: any) => void;
  children: React.ReactNode;
};
export type SelfLoadingEntityContentProps = (
  | {
      entityId: string;
    }
  | {
      entityUrl: string;
    }
) &
  CommonProps;

const isEntityId = (
  props: SelfLoadingEntityContentProps,
): props is { entityId: string } & CommonProps => "entityId" in props;
const isEntityUrl = (
  props: SelfLoadingEntityContentProps,
): props is { entityUrl: string } & CommonProps => "entityUrl" in props;

// The component is just like EntityContent, but it fetches the entity data itself
// based on the entityId or entityUrl provided.
export default function SelfLoadingEntityContent(props: SelfLoadingEntityContentProps) {
  const {
    data: entityData,
    error,
    isLoading,
  } = useCachedAPI(API.resolveWithSnapshot, "resolveWithSnapshot", () => {
    return (
      isEntityId(props) ?
        ([{ entityId: props.entityId, refresh: true }] as [{ entityId: string; refresh?: boolean }])
      : isEntityUrl(props) ?
        ([{ query: props.entityUrl, fetchMissing: true }] as [
          { query: string; fetchMissing: boolean },
        ])
      : false
    );
  });

  useEffect(() => {
    if (error) {
      props.onError?.(error);
      if (error.status === 403) {
        uiStore.showVisitLimitReachedModal.set(true);
      } else {
        toast.error(prettyError(error));
      }
    }
  }, [error]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full p-4">
        <Loader />
      </div>
    );
  }
  if (error || !entityData?.entity) {
    return null;
  }

  return (
    <EntityStoresProvider
      entity={entityData.entity}
      snapshot={entityData.snapshot}
      props={{
        attributes: entityData.entity.attributes,
      }}
    >
      {props.children}
    </EntityStoresProvider>
  );
}
