import moment from "moment";

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { favicon as favFunc, favicon, prettyUrl, smartTruncate } from "@/lib/utils";
import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { ProfilePageSection, References } from "@/types";
import { ArrowTopRightOnSquareIcon, StarIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { Fragment, useState } from "react";
import { twJoin } from "tailwind-merge";
import { profilePageUiStore } from "@/stores/profilePageUiStore";
import AISummaryBox from "@/components/ui/AISummaryBox";

export default withErrorBoundary(function SourcesV3() {
  const entityStore = useEntityStore();
  const user = useStore(uiStore.user);
  const snapshot = useStore(entityStore.snapshot);
  const canViewAllSources = !!user;
  const importantSources =
    snapshot.references?.filter(({ id }) => snapshot.notableLinkIds.includes(id)) || [];
  const sourcesExpanded = useStore(profilePageUiStore.sourcesExpanded);

  if (!snapshot) return null;

  return (
    <ProfileBubble title="Cited Sources" section={ProfilePageSection.Sources}>
      <div role="list" className="flex flex-col">
        {snapshot?.references
          ?.filter((e) => !snapshot?.notableLinkIds?.includes(e.id))
          .slice(0, sourcesExpanded ? undefined : 1)
          .map(
            (
              {
                id,
                url,
                notes,
                summary,
                title,
                datePublished,
                description,
                favicon,
                createdAt,
                updatedAt,
                author,
              },
              idx,
            ) => {
              return (
                <SourceItem
                  key={id}
                  source={{
                    id,
                    url,
                    notes,
                    summary,
                    title,
                    datePublished,
                    description,
                    favicon,
                    createdAt,
                    updatedAt,
                    author,
                  }}
                  index={idx}
                  isHighlighted={false}
                />
              );
            },
          )}
        {!snapshot?.allReferencesLength && (
          <div className="text-gray-400 text-center">No sources yet</div>
        )}
        {importantSources.length > 0 && (
          <div className="mt-4">
            <div className="flex mb-4">
              <div className="text-gray-400 text-base">{"Other Important Sources"}</div>
            </div>
            <div role="list" className="flex flex-col">
              {importantSources.slice(0, sourcesExpanded ? undefined : 1).map((source) => (
                <SourceItem key={source.id} source={source} isHighlighted={true} />
              ))}
            </div>
          </div>
        )}
      </div>

      <div
        className={twJoin(
          "text-blue-600 hover:text-blue-800 font-medium py-4 block w-[100%] cursor-pointer",
        )}
        onClick={() => profilePageUiStore.sourcesExpanded.set(!sourcesExpanded)}
      >
        {sourcesExpanded ? "Hide details" : "Expand all"}
      </div>

      {canViewAllSources && (
        <>
          {!!snapshot?.allReferencesLength && <hr />}
          <Link
            href={`${snapshot?.slug}/sources`}
            target="_blank"
            className={twJoin(
              "text-blue-600 hover:text-blue-800 font-medium py-4 -mb-4 block w-[100%]",
            )}
          >
            {snapshot?.allReferencesLength ?
              `Manage Sources (${snapshot?.allReferencesLength})`
            : "Add Sources"}
          </Link>
        </>
      )}
    </ProfileBubble>
  );
});

const SourceItem = ({
  source,
  isHighlighted,
  index,
}: {
  source: References;
  isHighlighted: boolean;
  index?: number;
}) => {
  const currentHash = useStore(uiStore.currentHash);

  return (
    <Fragment>
      <div
        className={twJoin(
          "flex gap-2 sm:gap-4 -mx-4 p-2 rounded-md",
          currentHash === `source${source.id}` && "bg-yellow-50",
        )}
      >
        <a id={`source${source.id}`} />
        <a
          href={source.url}
          title={source.url}
          target="_blank"
          className="flex flex-1 gap-4"
          rel="noreferrer"
        >
          <img
            src={favFunc({
              url: source.url,
              favicon: source.favicon,
            })}
            className="w-10 h-10 rounded border border-gray-100"
            alt="favicon"
            onError={(e) =>
              ((e.target as HTMLImageElement).src =
                favFunc({
                  url: source.url,
                  favicon: null,
                }) ?? "")
            }
          />
          <div className="flex-1">
            <div className="flex items-center">
              <span className="font-semibold line-clamp-2">
                {(source.title !== "undefined" ? source.title : undefined) ||
                  prettyUrl(source.url, true) ||
                  ""}
              </span>
              <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4 text-gray-400" />
              <span className="ml-2 text-gray-400">{prettyUrl(source.url, true)}</span>
            </div>
          </div>
        </a>
        {source.datePublished && (
          <div className="hidden sm:block text-gray-400">
            {moment(source.datePublished).format("ll")}
          </div>
        )}
      </div>
      <div className="mb-4">{source.summary && <AISummaryBox content={source.summary} />}</div>
    </Fragment>
  );
};
