import { WorkExperienceSummary } from "@/utils/relationshipUtils";
import { BriefcaseIcon, CalendarIcon } from "@heroicons/react/24/outline";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { UsersIcon } from "@heroicons/react/24/outline";

export default function WorkExperienceSummaryHeader({
  summary,
}: {
  summary: WorkExperienceSummary;
}) {
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {summary.fulltimeWorkDuration.as("months") > 1 && (
        <span className="flex items-center">
          <BriefcaseIcon className="h-4 w-4 inline-block mr-1" />
          {summary.fulltimeWorkDuration.locale("en").humanize()} of full-time work experience
        </span>
      )}
      {!!summary.fulltimeCompaniesCount && (
        <span className="flex items-center">
          <BuildingOfficeIcon className="h-4 w-4 inline-block mr-1" />
          has worked at {summary.fulltimeCompaniesCount} companies full time
        </span>
      )}
      {!!summary.fulltimePositionsCount && (
        <span className="flex items-center">
          <UsersIcon className="h-4 w-4 inline-block mr-1" />
          has had {summary.fulltimePositionsCount} full-time positions
        </span>
      )}
      {summary.fulltimeAverageDuration.as("months") > 1 && (
        <span className="flex items-center">
          <CalendarIcon className="h-4 w-4 inline-block mr-1" />
          {summary.fulltimeAverageDuration.locale("en").humanize()} average tenure per company
        </span>
      )}
    </div>
  );
}
