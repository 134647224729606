"use client";

import { discoverStore } from "@/stores/discoverStore";
import { Entity } from "@/types";
import { useStore } from "@nanostores/react";
import { ListSelector } from "./ListSelector";

export type AddEntityToListProps = {
  entity: {
    id: string;
    name: string;
  };
};

export const AddEntityToList = ({ entity }: AddEntityToListProps) => {
  const searchId = useStore(discoverStore.searchId);
  const isOnSearchPage = window.location.pathname.includes("search/advanced");

  return (
    <ListSelector
      entity={{
        ...entity,
        sourceSearchId: isOnSearchPage ? searchId : undefined,
      }}
    />
  );
};
