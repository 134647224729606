import { IconProps } from "@/components/icons/types";

export default function FacebookIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_7357_18244)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H7.03512V13.2232H5V10.2105H7.03512V8.91211C7.03512 5.55647 8.55375 4 11.8531 4C12.4775 4 13.5567 4.12328 14 4.24657V6.97424C13.7687 6.95112 13.364 6.93571 12.8591 6.93571C11.2403 6.93571 10.6158 7.54828 10.6158 9.13942V10.2105H13.8381L13.2831 13.2232H10.612V20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_7357_18244">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
