import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import clsx from "clsx";
import EntityAIChat from "./EntityAIChat";
import EntityHighlights from "./EntityHighlights";

export default function EntityTabs({ main }: { main: boolean }) {
  const entityStore = useEntityStore();
  const user = useStore(uiStore.user);

  const canGiveFeedback = !!user && main;
  const isHighlightsTab = (selectedTabIndex: number) => tabs[selectedTabIndex].id === "highlights";

  const tabs = [
    { name: "Highlights", id: "highlights", component: <EntityHighlights /> },
    { name: "AI Chat", id: "ai-chat", component: <EntityAIChat /> },
  ];

  return (
    <TabGroup>
      {({ selectedIndex }) => (
        <>
          <div className="flex items-center border-b border-gray-200">
            <TabList className="flex flex-1">
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  className={({ selected }) =>
                    clsx(
                      "px-4 py-2 text-sm font-medium leading-[18.2px]",
                      "focus:outline-none focus:ring-0",
                      selected ?
                        "text-brand-600 border-b-2 border-brand-600"
                      : "text-gray-500 hover:text-gray-700",
                    )
                  }
                >
                  {tab.name}
                </Tab>
              ))}
            </TabList>
            {isHighlightsTab(selectedIndex) && canGiveFeedback && (
              <button
                className="w-5 h-5 p-1 group flex border items-center justify-center mr-2 rounded-md bg-gray-100 hover:bg-gray-200"
                onClick={entityStore.updateHighlightsFromFeedback}
              >
                <PencilIcon className="text-gray-500 group-hover:text-gray-600" />
              </button>
            )}
          </div>
          <TabPanels className="mt-4">
            {tabs.map((tab) => (
              <TabPanel key={tab.id}>{tab.component}</TabPanel>
            ))}
          </TabPanels>
        </>
      )}
    </TabGroup>
  );
}
