import API from "@/client/api";
import errorTracker from "@/lib/errorTracker";
import { loggerWithPrefix } from "@/lib/logger";
import { prettyError } from "@/lib/utils";
import { MessageStore, messageStore, MessageStoreType } from "@/stores/messageStore";
import { uiStore } from "@/stores/uiStore";
import { ChatRole, MessageAttachments, UIMessage } from "@/types";
import { Conversation, Entity } from "@prisma/client";
import { atom } from "nanostores";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const logger = loggerWithPrefix("[entityChatStore]");

// Manages entity-based chat state and actions, including conversation initialization, message loading, sending, and pagination.

// While MessageStore provides general message operations such as sending, updating, and deleting messages,
// applicable across different contexts, EntityChatStore focuses on entity-specific logic.
class EntityChatStore {
  entity = atom<Entity | undefined>(undefined);

  conversation = atom<Conversation | undefined>(undefined); // Currently we only support one conversation per entity

  isLoadingMessages = atom<boolean>(false);

  pagination = atom<{ cursor: string | undefined; hasMore: boolean }>({
    cursor: undefined,
    hasMore: true,
  });

  // --- actions

  init = (entity: Entity) => {
    this.entity.set(entity);
    this.conversation.set(undefined);
    this.pagination.set({ cursor: undefined, hasMore: true });

    // TODO @ayush - make multiple message stores
    if (!location.pathname.includes("agent")) {
      messageStore.init(MessageStoreType.Entity);
      messageStore.onSendMessage = this.sendMessage;
      messageStore.onDeleteMessage = this.deleteMessage;
      messageStore.onUpdateMessage = this.updateMessage;
      this.loadConversation()
        .then(() => {
          return this.loadMessages();
        })
        .catch((error: unknown) => {
          messageStore.error.set(
            error instanceof Error ? error.message : "Failed to load conversation",
          );
        });
    }
  };

  private async loadConversation() {
    const entity = this.entity.get();
    if (!entity) {
      messageStore.error.set("Failed to load conversation");
      return {
        id: "",
        entityId: "",
        userId: "",
        type: "",
        context: "",
        meta: {},
        archivedAt: null,
        deletedAt: null,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
    }

    const conversations = await API.conversations.list({
      entityId: entity.id,
    });
    const conversation = conversations[0];
    if (conversation) {
      this.conversation.set(conversation);
      logger.info("loaded entityChat conversation", conversation);
    } else {
      await this.startConversation();
    }
  }

  private async startConversation() {
    const entity = this.entity.get();
    const user = uiStore.user.get();
    if (!user || !entity) {
      messageStore.error.set("Failed to start conversation");
      return;
    }

    try {
      const conversation = await API.conversations.create({
        userId: user.id,
        entityId: entity.id,
        type: "entity",
        context: "chat",
      });
      if (!conversation) {
        messageStore.error.set("Failed to start conversation");
        return;
      }

      this.conversation.set(conversation);
      await API.messages.create(
        this.createMessage(
          `Hello ${user.name}, I'm here to help you with any questions regarding ${entity.name}`,
          ChatRole.Assistant,
        ),
      );

      return conversation;
    } catch (error: unknown) {
      messageStore.error.set(
        error instanceof Error ? error.message : "Failed to start conversation",
      );
    }
  }

  loadMessages = async (limit: number = 10) => {
    messageStore.error.set(undefined);
    if (!this.canLoadMoreMessages()) {
      return;
    }

    this.isLoadingMessages.set(true);

    const currentPagination = this.pagination.get();
    const entity = this.entity.get();
    const conversation = this.conversation.get();
    if (!entity || !conversation) {
      messageStore.error.set("Failed to load messages");
      this.isLoadingMessages.set(false);
      return;
    }

    try {
      const response = await API.messages.list({
        conversationId: conversation.id,
        cursor: currentPagination.cursor,
        limit,
      });

      const mappedMessages = response.messages.map((m) => ({
        id: m.id,
        content: m.content ?? "",
        role: m.role as ChatRole,
        userId: m.userId ?? undefined,
        createdAt: new Date(m.createdAt),
        attachments: m.attachments as MessageAttachments | undefined,
      }));

      messageStore.prependMessages(mappedMessages);

      this.pagination.set({
        cursor: mappedMessages[mappedMessages.length - 1]?.id,
        hasMore: response.hasMore,
      });
    } catch (error: unknown) {
      messageStore.error.set(error instanceof Error ? error.message : "Failed to load messages");
    } finally {
      logger.info("loaded entityChat messages");
      this.isLoadingMessages.set(false);
    }
  };

  sendMessage = async (store: MessageStore, message: string) => {
    const entity = this.entity.get();
    const conversation = this.conversation.get();
    if (!entity || !conversation) {
      messageStore.error.set("Failed to send message");
      return;
    }

    try {
      await API.messages.create(this.createMessage(message, ChatRole.User));

      const aiResponse = await API.exchangeEntityMessage({
        conversationId: conversation.id,
        message,
      });

      if (aiResponse) {
        store.pushMessage({ role: ChatRole.Assistant, content: aiResponse });
        await API.messages.create(this.createMessage(aiResponse, ChatRole.Assistant));
      }
    } catch (e) {
      errorTracker.sendError(e);
      toast.error(prettyError(e));
    }
  };

  deleteMessage = async (store: MessageStore, message: UIMessage) => {
    if (message.id) {
      try {
        await API.messages.delete(message.id);
        logger.debug("deleted entityChat message", message);
      } catch (e) {
        logger.error("failed to delete entityChat message", e);
      }
    }
  };

  updateMessage = async (store: MessageStore, message: UIMessage, updates: Partial<UIMessage>) => {
    if (message.id) {
      await API.messages.update(message.id, updates);
      logger.debug("updated entityChat message", message);
    }
  };

  canLoadMoreMessages = () => {
    const currentPagination = this.pagination.get();
    const isLoading = this.isLoadingMessages.get();
    return currentPagination.hasMore && !isLoading;
  };

  private createMessage = (message: string, role: ChatRole) => {
    const user = uiStore.user.get();
    const conversation = this.conversation.get();

    if (!conversation) {
      throw new Error("Missing conversation");
    }

    const messageTimestamp = new Date();
    return {
      id: uuidv4(),
      conversationId: conversation.id,
      userId: role === ChatRole.User ? user?.id : undefined,
      role: role,
      content: message.trim(),
      attachments: {},
      deletedAt: undefined,
      createdAt: messageTimestamp,
      updatedAt: messageTimestamp,
    };
  };
}

export const entityChatStore = new EntityChatStore();

// Hook to use within components to get the correct store instance
export const useEntityChatStore = () => {
  return entityChatStore;
};
