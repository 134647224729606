import AuthoredMediaRow from "@/components/media/AuthoredMediaRow";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoList from "@/components/ui/ExpandoList";
import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { pluralize } from "@/lib/stringUtils";
import { useEffect, useState } from "react";
import IconList from "@/components/ui/IconList";
import { uniq } from "lodash";
import AISummaryBox from "@/components/ui/AISummaryBox";

export default withErrorBoundary(function AuthoredMediaV2() {
  const entityStore = useEntityStore();
  const media = useStore(entityStore.authoredMedia);
  const summary = useStore(entityStore.authoredMediaSummary);
  const crawlResults = useStore(entityStore.scrapedAuthoredMedia);
  const canEdit = useStore(entityStore.canEdit);
  const [expanded, setExpanded] = useState(false);

  const addMedia = () => {
    entityStore.addAuthoredMediaModal();
  };

  if (!media.length) return null;

  const iconUrls = uniq(media.map((m) => m.url && crawlResults[m.url]?.favicon).filter(Boolean));

  return (
    <ProfileBubble
      title={`What they've been publishing • ${pluralize(media.length, "source")}`}
      section={ProfilePageSection.AuthoredMedia}
      className="flex flex-col group"
      titleButtons={
        <>
          {canEdit && (
            <DocumentPlusIcon
              className="-mt-1 w-4 h-4 text-gray-400 hover:text-brand-600 hidden group-hover:block cursor-pointer"
              onClick={addMedia}
            />
          )}
        </>
      }
    >
      {media.length == 0 && <div className="text-gray-500">No authored media</div>}
      {summary && (
        <>
          <AISummaryBox content={summary} />
          <hr className="my-4" />
        </>
      )}
      Recent article
      {media.slice(0, expanded || media.length < 3 ? undefined : 1).map((m, idx) => (
        <AuthoredMediaRow media={m} idx={idx} key={m.id} crawlResult={crawlResults[m.url || ""]} />
      ))}
      {media.length > 3 && (
        <>
          <div
            className="text-blue-600 cursor-pointer mt-6 font-medium flex flex-row gap-1 items-center"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ?
              "Show less"
            : <>
                <span className="mr-2">
                  {`Show ${pluralize(media.length - 1, "more article")}`}
                </span>

                <IconList iconUrls={iconUrls} count={15} />
              </>
            }
          </div>
        </>
      )}
    </ProfileBubble>
  );
});
