import { forwardRef, InputHTMLAttributes, PropsWithChildren } from "react";
import { twJoin, twMerge } from "tailwind-merge";

export type CheckboxProps = {
  id?: string;
  label?: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  radio?: boolean;
  description?: string;
  tooltip?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export default forwardRef<HTMLInputElement, PropsWithChildren<CheckboxProps>>(
  function Checkbox(props, ref) {
    const {
      label,
      radio,
      className,
      inputClassName,
      labelClassName,
      description,
      tooltip,
      children,
      ...rest
    } = props;
    return (
      <div
        className={twMerge("relative flex items-center", className)}
        data-tooltip-id="tooltip"
        data-tooltip-content={tooltip}
      >
        <input
          type={radio ? "radio" : "checkbox"}
          ref={ref}
          {...rest}
          className={twMerge(
            "h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600",
            inputClassName,
          )}
        />
        {Boolean(label || children || description) && (
          <label
            htmlFor={rest.id}
            className={twMerge(
              "ml-3 text-sm select-none",
              labelClassName,
              rest.id && !rest.disabled ? "cursor-pointer" : "",
            )}
          >
            <span
              className={twJoin(
                labelClassName ? "" : "font-medium",
                rest.disabled ? "text-gray-400" : "",
              )}
            >
              {label}
            </span>
            {description && <span className="text-gray-500"> {description}</span>}
            {children}
          </label>
        )}
      </div>
    );
  },
);
