import API from "@/client/api";
import { useCachedAPI } from "@/hooks/swr";

const useEntityCardData = ({ entityId }: { entityId?: string }) => {
  const { data: cardData, isLoading } = useCachedAPI(API.getCardData, "getCardData", () => {
    return entityId ? ([{ entityId }] as [{ entityId: string }]) : false;
  });

  return { isLoading, data: cardData };
};

export default useEntityCardData;
