import { useEntitySidebarClick } from "@/hooks/useEntitySidebarClick";
import { relationshipToLabel } from "@/lib/stringUtils";
import {
  ConnectionScore,
  GraphPathSegment,
  GraphScoreOutput,
  ScoredGraphPath,
} from "@/types/graph";
import { entityUrl } from "@/utils/entityUtils";
import Link from "next/link";
import { HTMLAttributes } from "react";
import GradientProgressBar from "../ui/GradientProgressBar";

interface ConnectionScoreProps {
  score: GraphScoreOutput;
  className?: string;
}

const ConnectionPathsUnit = ({
  score,
  className = "",
  ...rest
}: ConnectionScoreProps & Omit<HTMLAttributes<HTMLDivElement>, "className">) => {
  return (
    <div className={className} {...rest}>
      <div className="mb-2 flex justify-between text-sm">
        <span>Strongest Intro Paths</span>
      </div>

      {score.bestPaths && score.bestPaths.length > 0 ?
        <div className="space-y-2">
          {score.bestPaths.map((path, index) => (
            <div key={index} className="border border-gray-200 rounded-md p-2">
              <div className="flex items-center">
                {path.segments.map((segment, segmentIndex) => (
                  <Segment
                    key={segmentIndex}
                    segmentIndex={segmentIndex}
                    segment={segment}
                    path={path}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      : <div className="text-sm text-gray-500">No connection paths found</div>}
    </div>
  );
};

const tooltipContent = (segment: GraphPathSegment) => `
${
  segment.direction == "from" ?
    `${segment.target.name} ${relationshipToLabel(segment.relationship)} ${segment.source.name}`
  : `${segment.source.name} ${relationshipToLabel(segment.relationship)} ${segment.target.name}`
}
${
  (
    segment.relationship.startedDate &&
    segment.relationship.endedDate &&
    segment.relationship.startedDate !== segment.relationship.endedDate
  ) ?
    ` from ${segment.relationship.startedDate} to ${segment.relationship.endedDate}`
  : segment.relationship.startedDate ? ` on ${segment.relationship.startedDate}`
  : ""
}`;

export default ConnectionPathsUnit;

function Segment({
  segmentIndex,
  segment,
  path,
}: {
  segmentIndex: number;
  segment: GraphPathSegment;
  path: ScoredGraphPath;
}) {
  const onCardClick = useEntitySidebarClick(segment.source.id, undefined);
  return (
    <div key={segmentIndex} className="flex items-center">
      <span className="font-medium">
        {segmentIndex == 0 ?
          "You"
        : <Link
            href={entityUrl(segment.source)}
            className="hover:underline text-blue-700 cursor-pointer"
            onClick={onCardClick}
          >
            {segment.source.name}
          </Link>
        }
      </span>
      <div
        className="mx-2 text-gray-500 text-sm"
        data-tooltip-id="tooltip"
        data-tooltip-content={tooltipContent(segment)}
      >
        →
      </div>

      {segmentIndex == path.segments.length - 1 && (
        <span className="font-medium">{segment.target.name}</span>
      )}
    </div>
  );
}
