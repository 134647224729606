import EntityContent, { EntityViewType } from "@/components/entities/EntityContent";
import SelfLoadingEntityContent from "@/components/entities/SelfLoadingEntityContent";
import { useSidebar } from "@/components/providers/SidebarProvider";

export type EntitySidebarProps =
  | {
      entityId: string;
    }
  | {
      entityUrl: string;
    };

export default function EntitySidebar(props: EntitySidebarProps) {
  const sidebar = useSidebar();

  return (
    <SelfLoadingEntityContent
      {...props}
      onError={() => {
        sidebar.closeSidebar();
      }}
    >
      <EntityContent viewType={EntityViewType.Sidebar} />
    </SelfLoadingEntityContent>
  );
}
