import { IconProps } from "@/components/icons/types";

export default function ThreadsIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_47_369)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM10.0838 17.0972H10.0881H10.0924C11.9899 17.0843 13.3279 16.595 14.4313 15.5133C15.8737 14.0972 15.8294 12.3225 15.3543 11.2335C15.0123 10.4516 14.3626 9.81743 13.4739 9.39849C13.4109 9.3698 13.3465 9.3411 13.2822 9.31384C13.1691 7.27367 12.0343 6.10437 10.1296 6.0929H10.1038C8.96475 6.0929 8.01741 6.57209 7.43355 7.44153L8.48106 8.14598C8.91609 7.49605 9.60012 7.35689 10.1038 7.35689H10.121C10.7478 7.36119 11.2215 7.53909 11.5277 7.88917C11.751 8.14311 11.8998 8.49605 11.9742 8.93938C11.419 8.84756 10.8165 8.81886 10.174 8.85473C9.30104 8.90495 8.56263 9.17181 8.03744 9.62661C7.48793 10.1029 7.20601 10.7572 7.24465 11.4688C7.28472 12.2005 7.65536 12.8289 8.2893 13.2407C8.82451 13.5893 9.51569 13.7586 10.2326 13.7199C11.18 13.6682 11.9284 13.3081 12.4565 12.6496C12.8514 12.156 13.1076 11.5018 13.222 10.694C13.6757 10.9623 14.012 11.3153 14.1966 11.74C14.5114 12.4602 14.5286 13.6438 13.5469 14.608C12.6868 15.453 11.6522 15.8189 10.0881 15.8303C8.3537 15.8189 7.04145 15.2722 6.18855 14.2062C5.39004 13.2091 4.97791 11.7686 4.96217 9.9236C4.97791 8.07855 5.39004 6.63809 6.18712 5.64096C7.04002 4.5764 8.35227 4.02977 10.0867 4.01686C12.714 4.03551 14.3797 5.24498 15.038 7.61227L16.2544 7.27224C15.8666 5.87482 15.1625 4.77582 14.1622 4.00395C13.099 3.18329 11.7295 2.76148 10.0924 2.75H10.0881H10.0838C7.95015 2.76435 6.30733 3.47023 5.20544 4.849C4.22376 6.07568 3.71717 7.78013 3.7 9.9193V9.92934C3.71717 12.0671 4.22376 13.773 5.20401 14.9996C6.30733 16.377 7.94872 17.0829 10.0838 17.0972ZM10.2455 10.1216C10.3958 10.113 10.5417 10.1087 10.6863 10.1087C11.1614 10.1087 11.6036 10.1532 12.0071 10.2407C11.8468 12.1345 10.8966 12.42 10.1668 12.4587C9.37259 12.5018 8.54689 12.1532 8.50682 11.4028C8.46818 10.6869 9.15078 10.1833 10.2455 10.1216Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_369">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
