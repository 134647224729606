import { SidebarVariant, useSidebar } from "@/components/providers/SidebarProvider";
import { getSupportModalConfig } from "@/components/support/SupportButton";
import Loader from "@/components/ui/Loader";
import PopoverMenu, { PopoverOption } from "@/components/ui/PopoverMenu";
import { useErrorTracker } from "@/hooks/useErrorTracker";
import { useDevTools } from "@/hooks/useUIStore";
import { prettyError } from "@/lib/utils";
import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { CompanyFact, Entity, EntityUIType, PipelineRunStatus, UserWithMeta } from "@/types";
import { entityIsUser } from "@/utils/entityUtils";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { User } from "@prisma/client";
import moment from "moment/moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

const UpdateButton = ({
  update,
  disabled,
  inProgress,
}: {
  update: () => Promise<void>;
  disabled?: boolean;
  inProgress?: boolean;
}) => {
  const disabledProps = (disabled || inProgress) && {
    "data-tooltip-content": disabled ? "Log in to update profile" : undefined,
    "data-tooltip-id": "tooltip",
    disabled: true,
  };

  return (
    <div className="text-gray-400 flex-row items-start" onClick={update}>
      <div className="flex flex-row gap-1 flex-wrap items-center">
        <button
          className={twMerge(
            "sm:ml-1 text-blue-600 font-semibold",
            disabledProps && "text-gray-400 cursor-not-allowed",
          )}
          {...disabledProps}
        >
          {inProgress ? "Refreshing..." : "Update Now"}
        </button>
      </div>
    </div>
  );
};

const ProfilePopoverMenu = ({
  entity,
  isRefreshing,
  initiallyOpen,
}: {
  entity: Entity;
  isRefreshing: boolean;
  initiallyOpen?: boolean;
}) => {
  const errorTracker = useErrorTracker();
  const user = useStore(uiStore.user);
  const entityStore = useEntityStore();
  const canEdit = useStore(entityStore.canEdit);
  const isAdmin = useDevTools();
  const slugPrefix = entity.slug?.split("/")[1];
  const slugWithoutPrefix =
    entity.slug && slugPrefix ?
      decodeURIComponent(entity.slug.substring(slugPrefix.length + 2))
    : "";
  const [isDownloading, setIsDownloading] = useState(false);

  const { openSidebar } = useSidebar();
  return (
    <PopoverMenu
      buttonLabel={<EllipsisVerticalIcon className="h-5 w-5 text-gray-500 ml-auto" />}
      floatingOptions={{ placement: "bottom-end" }}
      keepOpenOnClick
      initiallyOpen={initiallyOpen}
    >
      <div className="px-4 py-2">
        <UpdatedAt refreshedAt={entity.refreshedAt} isRefreshing={isRefreshing} />
      </div>
      {canEdit && (
        <>
          <PopoverOption>
            <div
              className="sm:ml-1 text-blue-600 font-semibold"
              onClick={() => {
                uiStore.showInputModal.set({
                  title: "Edit Name",
                  type: "edit",
                  fields: [
                    {
                      currentValue: entity.name,
                    },
                  ],
                  onSubmit: async (values: string[]) => {
                    try {
                      if (!user) return;

                      const originalEntityName = entity.name;
                      const originalUserName = user.name || "";

                      // Perform both updates
                      await Promise.all([
                        entityStore.updateEntityFields(entity.id, { name: values[0] }),
                        updateNameIfUserIsEntity(entity, user, values[0]),
                      ]).catch(async (error: unknown) => {
                        // If either update fails, revert both changes
                        await Promise.all([
                          entityStore.updateEntityFields(entity.id, { name: originalEntityName }),
                          updateNameIfUserIsEntity(entity, user, originalUserName),
                        ]);
                        throw error;
                      });
                    } catch (error) {
                      errorTracker.sendError(error, { source: "update-name" });
                    }
                    uiStore.reloadPage();
                  },
                });
              }}
            >
              Edit Name
            </div>
          </PopoverOption>
        </>
      )}

      {user && (
        <>
          <PopoverOption>
            <div
              className="sm:ml-1 text-blue-600 font-semibold"
              onClick={() => {
                openSidebar(SidebarVariant.AddEntityToList, {
                  entity,
                  title: `Add ${entity.name} to list`,
                });
              }}
            >
              Add to List
            </div>
          </PopoverOption>
          <PopoverOption>
            <div
              className="sm:ml-1 text-blue-600 font-semibold "
              onClick={async () => {
                setIsDownloading(true);
                try {
                  await entityStore.getEntityText();
                } catch (error) {
                  errorTracker.sendError(error, { source: "download-text" });
                  toast.error(prettyError(error));
                } finally {
                  setIsDownloading(false);
                }
              }}
            >
              {isDownloading ?
                <Loader />
              : "Download as Text"}
            </div>
          </PopoverOption>
        </>
      )}

      <PopoverOption>
        <div
          className="sm:ml-1 text-blue-600 font-semibold"
          onClick={() => {
            uiStore.showInputModal.set(
              getSupportModalConfig(user, document.title, decodeURIComponent(window.location.href)),
            );
          }}
        >
          Report a Bug
        </div>
      </PopoverOption>
      {isAdmin && (
        <>
          <PopoverOption>ADMIN STUFF</PopoverOption>
          <PopoverOption>
            <div
              className="sm:ml-1 text-blue-600 font-semibold"
              onClick={() => {
                entityStore.showFactEditModal.set({
                  type: CompanyFact.Website,
                  currentValue: entityStore.facts.get()[CompanyFact.Website]?.value || "",
                });
              }}
            >
              Edit Website URL
            </div>
          </PopoverOption>
          <PopoverOption
            className="sm:ml-1 text-blue-600 font-semibold"
            onClick={() => {
              uiStore.showInputModal.set({
                title: "Edit Slug URL",
                type: "edit",
                fields: [
                  {
                    currentValue: slugWithoutPrefix || "",
                  },
                ],
                onSubmit: async (values: string[]) => {
                  const fullSlug = `/${slugPrefix}/${values[0]}`;
                  await entityStore.updateEntityFields(entity.id, { slug: fullSlug });
                  uiStore.routeTo(fullSlug);
                },
              });
            }}
          >
            Edit Slug URL
          </PopoverOption>
          <PopoverOption
            className="sm:ml-1 text-blue-600 font-semibold"
            onClick={() => {
              uiStore.showInputModal.set({
                title: "Edit canonical url URL",
                type: "edit",
                fields: [
                  {
                    currentValue: entity.url,
                  },
                ],
                onSubmit: async (values: string[]) => {
                  await entityStore.updateEntityFields(entity.id, { url: values[0] });
                  uiStore.reloadPage();
                },
              });
            }}
          >
            Edit Canonical URL
          </PopoverOption>
          <PopoverOption
            className="sm:ml-1 text-blue-600 font-semibold"
            onClick={() => entityStore.deleteHighlights()}
          >
            Delete Highlights
          </PopoverOption>
        </>
      )}
    </PopoverMenu>
  );
};

const UpdatedAt = ({
  refreshedAt,
  isRefreshing,
}: {
  refreshedAt?: Date | null;
  isRefreshing: boolean;
}) => {
  const user = useStore(uiStore.user);
  if (!user || !refreshedAt) {
    return null;
  }
  const age = moment(refreshedAt).fromNow();
  return (
    <div className="hidden sm:flex flex-row gap-1 flex-wrap sm:justify-end items-center">
      <p>Updated {age}</p>
      {isRefreshing && (
        <>
          <Loader className="mx-2" /> <p>Updating...</p>
        </>
      )}
    </div>
  );
};

async function updateNameIfUserIsEntity(entity: Entity, user: User | UserWithMeta, name: string) {
  if (entityIsUser(entity, user)) {
    return uiStore.updateUser({ name: name });
  }
  return null;
}

export default ProfilePopoverMenu;
