import { SocialService } from "@/utils/socialUtils";
import BlueskyIcon from "./BlueskyIcon";
import CrunchbaseIcon from "./CrunchbaseIcon";
import FacebookIcon from "./FacebookIcon";
import GithubIcon from "./GithubIcon";
import InstagramIcon from "./InstagramIcon";
import LinkedinIcon from "./LinkedinIcon";
import MediumIcon from "./MediumIcon";
import PinterestIcon from "./PinterestIcon";
import QuoraIcon from "./QuoraIcon";
import RedditIcon from "./RedditIcon";
import SubstackIcon from "./SubstackIcon";
import ThreadsIcon from "./ThreadsIcon";
import TiktokIcon from "./TiktokIcon";
import TwitterIcon from "./TwitterIcon";
import YoutubeIcon from "./YoutubeIcon";
import { IconProps } from "./types";

interface SocialServiceIconProps extends IconProps {
  service: SocialService;
}

export default function SocialServiceIcon({ service, ...props }: SocialServiceIconProps) {
  const icon = getSocialServiceIcon(service, props);
  if (!icon) {
    console.error(`No icon found for service: ${service}`);
  }
  return icon;
}

export const getSocialServiceIcon = (service: SocialService, props?: IconProps) => {
  switch (service) {
    case SocialService.Facebook:
      return <FacebookIcon {...props} />;
    case SocialService.Twitter:
      return <TwitterIcon {...props} />;
    case SocialService.GitHub:
      return <GithubIcon {...props} />;
    case SocialService.LinkedIn:
      return <LinkedinIcon {...props} />;
    case SocialService.Instagram:
      return <InstagramIcon {...props} />;
    case SocialService.YouTube:
      return <YoutubeIcon {...props} />;
    case SocialService.Reddit:
      return <RedditIcon {...props} />;
    case SocialService.Medium:
      return <MediumIcon {...props} />;
    case SocialService.Pinterest:
      return <PinterestIcon {...props} />;
    case SocialService.Quora:
      return <QuoraIcon {...props} />;
    case SocialService.Bluesky:
      return <BlueskyIcon {...props} />;
    case SocialService.TikTok:
      return <TiktokIcon {...props} />;
    case SocialService.Threads:
      return <ThreadsIcon {...props} />;
    case SocialService.Crunchbase:
      return <CrunchbaseIcon {...props} />;
    case SocialService.Substack:
      return <SubstackIcon {...props} />;
    default:
      return null;
  }
};
