import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import AssociateSearchResultRow from "@/components/search/AssociateSearchResultRow";
import AssociateSearchResults from "@/components/search/AssociateSearchResults";
import SearchBox from "@/components/search/SearchBox";
import Loader from "@/components/ui/Loader";
import errorTracker from "@/lib/errorTracker";
import { prettyError } from "@/lib/miscUtils";
import { emailStore } from "@/stores/emailStore";
import { searchStore } from "@/stores/searchStore";
import { AutocompleteEntity, EmailResolutionStatus, EmailSearchResult, EntityType } from "@/types";

export type AssociateAliasSearchProps = {
  alias: string;
  onSelect: (url: string) => Promise<void>;
  suggestedResults?: EmailSearchResult[];
  onClose?: () => void;
  shouldResolveEmail?: boolean;
  autoSearch?: boolean;
  type?: "people" | "companies" | "all";
};

export default function AssociateAliasSearch({
  alias,
  onSelect,
  suggestedResults,
  onClose,
  shouldResolveEmail = false,
  autoSearch = true,
  type,
}: AssociateAliasSearchProps) {
  const [error, setError] = useState<string>();
  const [hasSearched, setHasSearched] = useState(false);
  const [suggested, setSuggested] = useState<EmailSearchResult[] | undefined>(suggestedResults);

  const searching = useStore(searchStore.searching);
  const searchResults = useStore(searchStore.entities);

  const isEmail = alias.includes("@");

  const noResults = searchResults.length === 0 && hasSearched && !searching;

  const resolveEmail = useCallback(async () => {
    if (isEmail) {
      const res = await emailStore.resolveEmail({ address: alias }, "search");
      if (res?.status === EmailResolutionStatus.CANDIDATE) {
        setSuggested(res.candidates);
      }
    }
  }, [alias, isEmail]);

  useEffect(() => {
    searchStore.reset();
    if (autoSearch) {
      void searchStore
        .searchAll({
          query: alias,
          type:
            type ? type
            : isEmail ? "people"
            : "all",
          page: 0,
        })
        .finally(() => {
          setHasSearched(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldResolveEmail) {
      void resolveEmail();
    }
  }, [alias, resolveEmail, shouldResolveEmail]);

  if (!onSelect) {
    return null;
  }

  const clearError = () => {
    setError(undefined);
  };

  const handleSelect = async (url: string) => {
    clearError();
    try {
      await onSelect(url);
      onClose?.();
    } catch (e) {
      setError(`Sorry, can't load this profile: ${prettyError(e)}. We'll look into it!`);
      errorTracker.sendError(e, { source: "associate-alias-search" });
    }
  };

  const handleAutocompleteSelect = async (entity: AutocompleteEntity) => {
    if (!entity) return;

    // If it's a URL, handle it directly
    if (entity.url?.startsWith("http")) {
      await handleSelect(entity.url);
      return;
    }

    // Otherwise trigger a search with the entity name
    void searchStore
      .searchAll({
        query: entity.name,
        type:
          type ? type
          : isEmail ? "people"
          : "all",
        page: 0,
      })
      .finally(() => {
        setHasSearched(true);
      });
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex flex-col gap-2">
        <p className="text-sm text-brand-600">{alias}</p>
        <SearchBox
          placeholder="Search by name or paste a profile URL"
          onSelect={handleAutocompleteSelect}
          type={
            type === "people" ? EntityType.Person
            : type === "companies" ?
              EntityType.Company
            : undefined
          }
          autoFocus
        />
        {error && <p className="text-xs text-red-500">{prettyError(error)}</p>}
        <p className="text-sm text-gray-500">
          <b>Hint:</b> Add a link to their <b>LinkedIn</b> or <b>Distill</b> profile above to
          immediately find the right profile
        </p>
      </div>
      {suggested && suggested.length > 0 && (
        <div>
          <p className="text-sm font-semibold mb-2">Suggested</p>
          {suggested.map((entity) => {
            const { url, profileImage: imageUrl, summary: description, type, ...rest } = entity;
            return (
              <AssociateSearchResultRow
                key={url}
                foundEntity={{
                  ...rest,
                  pageUrl: url,
                  displayUrl: url,
                  entityUrl: url,
                  type: type || EntityType.Unknown,
                  imageUrl,
                  description,
                }}
                onSelect={handleSelect}
              />
            );
          })}
        </div>
      )}
      {searching ?
        <div className="flex justify-center w-full h-full align-middle items-center">
          <Loader />
        </div>
      : <AssociateSearchResults onSelect={handleSelect} hideCompanies={isEmail} />}
      {noResults && (
        <div className="flex flex-col h-full items-center justify-center gap-4 py-8">
          <Image
            src="/images/plant.svg"
            width={150}
            height={210}
            alt="plant"
            priority
            className=""
          />{" "}
          <p className="text-sm text-gray-500">Uh oh! No results found.</p>
        </div>
      )}
    </div>
  );
}
