"use client";
/* eslint-disable @next/next/no-img-element */
import CompanySubtitle from "@/components/companies/CompanySubtitle";
import ProfilePopoverMenu from "@/components/entities/ProfilePopoverMenu";
import EntityTag from "@/components/entityTags/EntityTag";
import EntityImageEditModal from "@/components/modals/EntityImageEditModal";
import FactsList from "@/components/people/FactsList";
import PersonSubtitle from "@/components/people/PersonSubtitle";
import SocialButtons from "@/components/people/SocialButtons";
import { SidebarVariant, useSidebar } from "@/components/providers/SidebarProvider";
import EntityHighlights from "@/components/sections/EntityHighlights";
import EntityTabs from "@/components/sections/EntityTabs";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { SmallButton } from "@/components/ui/Button";
import ConditionalLink from "@/components/ui/ConditionalLink";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { useDevTools } from "@/hooks/useUIStore";
import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import {
  CompanyCharacteristic,
  CompanyFact,
  EntityType,
  NO_DESCRIPTION,
  PersonProfile,
  PipelineRunStatus,
  ProfilePageSection,
} from "@/types";
import { maybeEntityUrl } from "@/utils/entityUtils";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";
import { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

export default withErrorBoundary(function EntityHeader({
  main,
  showAIChat,
}: {
  main: boolean;
  showAIChat?: boolean;
}) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const initialStatus = useStore(entityStore.initialStatus);
  const attributes = useStore(entityStore.attributes);
  const facts = useStore(entityStore.facts);
  const snapshot = useStore(entityStore.snapshot);
  const companyDescription =
    snapshot?.characteristics?.[CompanyCharacteristic.Description] ||
    facts[CompanyFact.BrandedBlurb]?.value;
  const isDev = useDevTools();

  const isRefreshing =
    initialStatus == PipelineRunStatus.IN_PROGRESS || initialStatus == PipelineRunStatus.REFRESHING;
  const entityProps = { entity, attributes };

  const entityDescription =
    entity.type === EntityType.Company ? companyDescription
    : entity.description?.startsWith(NO_DESCRIPTION) ? undefined
    : entity.description;

  const sidebar = useSidebar();

  return (
    <ProfileBubble className="flex flex-col gap-3" section={ProfilePageSection.Highlights}>
      <div className="flex">
        <div className="flex gap-4 items-center flex-1 max-w-full">
          <ProfileEntityImage entity={entity} main={main} closeSidebar={sidebar.closeSidebar} />
          <div className="flex-1 flex flex-col gap-1 max-w-full overflow-hidden">
            <div className="flex">
              <ConditionalLink
                className={twJoin(
                  "text-lg md:text-2xl font-semibold flex-1 mr-2",
                  !main && "cursor-pointer hover:underline",
                )}
                href={(!main || undefined) && maybeEntityUrl(entity)}
                onClick={() => {
                  sidebar.closeSidebar();
                }}
              >
                {entity.name}
              </ConditionalLink>
              <div className="flex text-gray-400 flex-row flex-wrap sm:justify-end items-center gap-4 max-w-full">
                <div className="hidden sm:flex items-center gap-3">
                  <div className="hidden md:flex items-center gap-3">
                    {main && <SocialButtons />}
                    <SmallButton
                      className="py-0.5 text-brand-600 hover:text-brand-800 bg-brand-100 hover:bg-brand-200"
                      onClick={() => {
                        sidebar.openSidebar(SidebarVariant.AddEntityToList, {
                          entity,
                          title: `Add ${entity.name} to list`,
                        });
                      }}
                    >
                      Add to List
                    </SmallButton>
                  </div>
                </div>
                <ProfilePopoverMenu entity={entity} isRefreshing={isRefreshing} />
              </div>
            </div>
            {entity.type === EntityType.Person ?
              <PersonSubtitle
                skipLastUpdated
                snapshot={snapshot as PersonProfile}
                {...entityProps}
              />
            : <CompanySubtitle skipLastUpdated {...entityProps} />}
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          "flex flex-row gap-2 items-center justify-between md:hidden",
          !main && "md:flex",
        )}
      >
        <SocialButtons />
      </div>

      <div className="text-sm">{entityDescription}</div>

      <FactsList />
      {snapshot?.tags && snapshot.tags.length > 0 && (
        <EntityTag tags={snapshot.tags} main={main}></EntityTag>
      )}

      {isDev && showAIChat ?
        <EntityTabs main={main} />
      : <EntityHighlights />}
    </ProfileBubble>
  );
});

function ProfileEntityImage({
  entity,
  main,
  closeSidebar,
}: {
  entity: Entity;
  main: boolean;
  closeSidebar: () => void;
}) {
  const [open, setOpen] = useState(false);

  const isAdmin = useStore(uiStore.user)?.email?.includes("@distill.fyi");

  return (
    <>
      {isAdmin && <EntityImageEditModal open={open} setOpen={setOpen} />}
      <div
        className={twJoin("h-16 w-16 overflow-hidden flex-shrink-0", isAdmin && "cursor-pointer")}
        onClick={main && isAdmin ? () => setOpen(true) : undefined}
      >
        <ConditionalLink
          href={(!main || undefined) && maybeEntityUrl(entity)}
          onClick={() => {
            closeSidebar();
          }}
        >
          <EntityIconWithPlaceholder
            className="h-16 w-16"
            imageClassName="h-16 w-16"
            {...{ entity }}
          />
        </ConditionalLink>
      </div>
    </>
  );
}
