import states from "us-state-converter";

type Abbreviation = {
  fullName: string;
  abbr: string;
};

const locations: Abbreviation[] = [
  { fullName: "New York City", abbr: "NYC" },
  { fullName: "NY City", abbr: "NYC" },
  { fullName: "New York", abbr: "NY" },
  { fullName: "San Francisco Bay Area", abbr: "SF/Bay Area" },
  { fullName: "San Francisco", abbr: "SF" },
  { fullName: "Los Angeles", abbr: "LA" },
  { fullName: "Washington, D.C.", abbr: "DC" },
  { fullName: "Philadelphia", abbr: "Phila" },
  { fullName: "Dallas-Fort Worth", abbr: "DFW" },
  { fullName: "Minneapolis-Saint Paul", abbr: "MSP" },
  { fullName: "United States of America", abbr: "USA" },
  { fullName: "United States", abbr: "US" },
];

const positions: Abbreviation[] = [
  { fullName: "Chief Executive Officer", abbr: "CEO" },
  { fullName: "Chief Financial Officer", abbr: "CFO" },
  { fullName: "Chief Operating Officer", abbr: "COO" },
  { fullName: "Chief Technology Officer", abbr: "CTO" },
  { fullName: "Chief Marketing Officer", abbr: "CMO" },
  { fullName: "Chief Information Officer", abbr: "CIO" },
  { fullName: "Executive Assistant", abbr: "EA" },
];

function replaceAllCaseInsensitive(str: string, find: string, replace: string): string {
  return str.replace(new RegExp(find, "gi"), replace);
}

export function abbreviateLocation(
  origLocation: string | undefined | null,
): string | undefined | null {
  if (!origLocation) return origLocation;
  let location = origLocation;
  for (const place of locations) {
    if (location.toLowerCase().includes(place.fullName.toLowerCase())) {
      location = replaceAllCaseInsensitive(location, place.fullName, place.abbr);
    }
  }
  const stateList = states();
  for (const state of stateList) {
    if (location.toLowerCase().includes(state.name.toLowerCase())) {
      location = replaceAllCaseInsensitive(location, state.name, state.usps);
    }
    const abbrIndex = location.indexOf(state.usps);
    if (location.substring(abbrIndex + 1).includes(state.usps)) {
      // We have duplicate state name, best to remove one of it
      location = location.replace(new RegExp(state.usps + ",? "), "");
    }
  }
  return location;
}

export function abbreviatePosition(
  origPosition: string | undefined | null,
): string | undefined | null {
  if (!origPosition) return origPosition;
  let position = origPosition;
  for (const place of positions) {
    if (position.toLowerCase().includes(place.fullName.toLowerCase())) {
      position = replaceAllCaseInsensitive(position, place.fullName, place.abbr);
    }
  }
  return position;
}
