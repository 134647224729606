import { SoftwareCompanyTags } from "@/tags/tags/softwareCo";
import { StartupCompanyTags, StartupPersonTags } from "@/tags/tags/startup";
import { VcCompanyTags, VcPersonTags } from "@/tags/tags/vc";
import { Tag } from "./tags";

export const CompanyTags: Record<string, Tag> = {
  ...VcCompanyTags,
  ...StartupCompanyTags,
  ...SoftwareCompanyTags,
} as const;

export const PersonTags: Record<string, Tag> = {
  ...VcPersonTags,
  ...StartupPersonTags,
} as const;

export const AllTags: Record<string, Tag> = {
  ...CompanyTags,
  ...PersonTags,
} as const;

export const TagsById = Object.fromEntries(
  Object.entries(AllTags).map(([_, tag]) => [tag.id, tag]),
);

export type { TagId } from "./tags";
