import CompanyPeople from "@/components/people/CompanyPeople";
import HeadcountChart from "@/components/people/HeadcountChart";
import EntityConnectionScore from "@/components/sections/EntityConnectionScore";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { formatNumber } from "@/lib/stringUtils";
import { useEntityStore } from "@/stores/entityStore";
import { CompanyFact, ProfilePageSection } from "@/types";
import { aggregateHeadcount, getHeadcountFromFacts } from "@/utils/facts";
import { useStore } from "@nanostores/react";

interface Props {
  noHeadcount?: boolean;
}

export default function CompanyPeopleSection(props: Props) {
  const entityStore = useEntityStore();
  const facts = useStore(entityStore.facts);
  const headcount = getHeadcountFromFacts(facts);

  const peopleTitle = headcount > 0 ? `People (${formatNumber(headcount)})` : "People";

  return (
    <ProfileBubble title={peopleTitle} section={ProfilePageSection.People}>
      {!props.noHeadcount && (
        <HeadcountChart historicalHeadcount={facts[CompanyFact.HistoricalHeadcount]} />
      )}
      <CompanyPeople
        peopleNumberStep={4}
        cols={2}
        showMoreLink
        showConnectionScore
        showMoreButton={false}
      />
    </ProfileBubble>
  );
}
