import { CompanyFact, FactPick, FactType, FactValueSet, GenericProfile } from "@/types";
import { getFactLabel } from "@/types/facts";
import { formatValue } from "@/utils/facts";
import { uniq } from "lodash";
import { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

export default function FactInFactTable<T extends FactType>({
  fact,
  type,
  snapshot,
  highlight,
  ...rest
}: {
  fact: FactPick<T> | undefined;
  type: T;
  snapshot: GenericProfile;
  highlight?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  if (!fact) return null;
  if (type == CompanyFact.State && (fact.value == "POSSIBLY_INACTIVE" || fact.value == "ACTIVE"))
    return null;

  const label = getFactLabel(type);
  const value = formatValue(type, fact.value);
  if (!value || (Array.isArray(value) && value.length === 0)) return null;

  return (
    <div
      className={twMerge(
        "flex w-full lg:w-[45%] border-b-[1px] py-2 items-center",
        highlight ? "text-brand-600" : "",
      )}
      {...rest}
    >
      <div className="flex w-full flex-row items-center justify-between text-center">
        <div className="mt-1 text-md self-baseline">{label}</div>
        <div className="flex max-w-[50%] flex-wrap gap-x-1 justify-end font-semibold">
          <FactValue value={value} />
        </div>
      </div>
    </div>
  );
}

function FactValue({ value }: { value: NonNullable<unknown> }) {
  if (!Array.isArray(value)) {
    return <div>{safeStringify(value)}</div>;
  }
  const filtered = value.filter((val) => val !== null) as NonNullable<unknown>[];
  return uniq(filtered.map((val) => safeStringify(val))).map((val, index) => (
    <div key={index} className="text-sm leading-2 whitespace-nowrap overflow-hidden text-ellipsis">
      {index > 0 && <span> • </span>}
      {val}
    </div>
  ));
}

function safeStringify(value: NonNullable<unknown>): string {
  if (typeof value === "object") {
    return JSON.stringify(value);
  }
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  return String(value);
}
