import { IconProps } from "@/components/icons/types";

export default function MediumIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_47_366)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM16.1502 6.17261L17 5.21739V5H13.7343L10.3365 12.0361L6.7786 5H3.46667V5.21739L4.33373 6.30435C4.37667 6.35174 4.4 6.4113 4.4 6.47304V13.0435C4.4 13.1396 4.36407 13.233 4.29873 13.3078L3 14.8026V15H6.73333V14.8026L5.43367 13.2991C5.3688 13.2243 5.33333 13.1317 5.33333 13.0361V7.33696L9.20853 15H9.92907L13.7333 7.1213V13.587C13.7333 13.6565 13.7035 13.7235 13.6498 13.773L12.3333 14.8026V15H17V14.8026L16.1502 13.773C16.0965 13.7235 16.0667 13.6565 16.0667 13.587V6.3587C16.0667 6.28913 16.0965 6.22217 16.1502 6.17261Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_366">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
