"use client";
import EntityTagTooltip from "@/components/entities/EntityTagTooltip";
import { useCallback, useEffect, useState } from "react";

export default function EntityTag({ tags, main }: { tags?: string[]; main: boolean }) {
  const [visibleTags, setVisibleTags] = useState<string[]>([]);
  const [hiddenTags, setHiddenTags] = useState<string[]>([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!tags || tags.length === 0) return;

    const limit =
      expanded ? tags.length
      : main ? 7
      : 3;
    setVisibleTags(tags.slice(0, limit));
    setHiddenTags(tags.slice(limit));
  }, [tags, main, expanded]);

  const handleExpand = useCallback(() => {
    if (!tags || tags.length === 0) return;
    setExpanded(true);
    setVisibleTags(tags.slice(0));
    setHiddenTags([]);
  }, [tags]);

  if (!tags || tags.length === 0) return null;

  return (
    <div className="flex flex-wrap items-center gap-2 relative">
      <div className="flex flex-wrap gap-2">
        <EntityTagTooltip />
        {visibleTags.map((tag, index) => (
          <div
            key={index}
            className="bg-gray-100 text-gray-700 px-3 py-1 rounded-md flex items-center hover:bg-gray-200 transition"
            data-tooltip-id="entity-tag-tooltip"
            data-tooltip-content={tag}
          >
            {tag}
          </div>
        ))}
      </div>

      {!expanded && hiddenTags.length > 0 && (
        <>
          <button
            className="ml-auto bg-gray-200 text-gray-700 px-3 py-1 rounded-md"
            onClick={handleExpand}
          >
            {hiddenTags.length} More
          </button>
          <button className="bg-gray-200 text-gray-700 px-3 py-1 rounded-md" onClick={handleExpand}>
            +
          </button>
        </>
      )}
    </div>
  );
}
