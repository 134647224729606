import { FactType } from "@/types";

import { ReactNode } from "react";

export default function EditableFact({
  factType,
  canEdit,
  onEdit,
  children,
}: {
  factType: FactType;
  canEdit: boolean;
  onEdit: (factType: FactType) => void;
  children: ReactNode;
}) {
  return (
    <span
      onClick={() => canEdit && onEdit(factType)}
      className={canEdit ? "hover:underline cursor-pointer" : undefined}
    >
      {children}
    </span>
  );
}
