import { EntityType } from "@/types";

// create a branded tag id type
export type TagId = string & { readonly __tagId: unique symbol };

export const tagId = (name: string): TagId => {
  return name as TagId;
};

export interface Tag {
  id: TagId;
  name: string;
  description: string;
  type?: EntityType;
}
