import CompanyStats from "@/components/companies/CompanyStats";
import ShowPipelineStatus from "@/components/entities/ShowPipelineStatus";
import PastExperiences, { InvestmentList } from "@/components/experiences/PastExperiences";
import AuthoredMedia from "@/components/sections/AuthoredMedia";
import CompanyFunding from "@/components/sections/CompanyFunding";
import CompanyPeopleSection from "@/components/sections/CompanyPeopleSection";

import FactEditModal from "@/components/modals/FactEditModal";
import EntityHeader from "@/components/sections/EntityHeader";
import MediaCoverage from "@/components/sections/MediaCoverage";
import MutualConnections from "@/components/sections/MutualConnections";
import SocialProfiles from "@/components/sections/SocialProfiles";
import Sources from "@/components/sections/Sources";
import { useEntityStore } from "@/stores/entityStore";
import { EntityType } from "@/types";
import { useStore } from "@nanostores/react";
import CharacteristicsCard from "./CharacteristicsCard";

export enum EntityViewType {
  Main = "main",
  Sidebar = "sidebar",
  Search = "search",
}

interface Props {
  viewType?: EntityViewType;
  extraContent?: React.ReactNode;
}

export default function EntityContent({ viewType, extraContent }: Props) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);

  const main = viewType === EntityViewType.Main;

  if (viewType === EntityViewType.Search) {
    return (
      <>
        <ShowPipelineStatus />
        <EntityHeader main={main} showAIChat={true} />

        {extraContent}

        {entity.type === EntityType.Person && <MutualConnections wide={main} />}
        {entity.type === EntityType.Company && <CompanyPeopleSection noHeadcount />}

        {entity.type === EntityType.Company && <CompanyStats />}
        <CharacteristicsCard />
        {entity.type === EntityType.Company && <CompanyFunding />}
        <AuthoredMedia />
        <MediaCoverage />
        {entity.type === EntityType.Person && <PastExperiences />}
        {entity.type === EntityType.Company && <InvestmentList hideStats />}
        <SocialProfiles />
      </>
    );
  }

  return (
    <>
      <ShowPipelineStatus />
      <EntityHeader main={main} showAIChat />
      {entity.type === EntityType.Person && <MutualConnections wide={main} />}
      {entity.type === EntityType.Company && <CompanyStats />}
      <CharacteristicsCard />
      {entity.type === EntityType.Company && <CompanyPeopleSection />}
      {entity.type === EntityType.Company && <CompanyFunding />}
      <AuthoredMedia />
      <MediaCoverage />
      {entity.type === EntityType.Person && <PastExperiences />}
      {entity.type === EntityType.Company && <InvestmentList />}
      <SocialProfiles />
      {extraContent}
      <Sources />
      <FactEditModal />
    </>
  );
}
