import { EntityType } from "@/types";
import { Tag, tagId } from "./tags";

export const SoftwareCompanyTags = {
  SoftwareCo: {
    id: tagId("software-co"),
    name: "Software Company",
    description: "A company that primarily produces software products.",
  },
} as const;

Object.values(SoftwareCompanyTags).forEach((tag) => {
  (tag as Tag).type = EntityType.Company;
});
