import { InvestmentSummary } from "@/utils/relationshipUtils";
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  ChartBarSquareIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export default function InvestmentSummaryHeader({ summary }: { summary: InvestmentSummary }) {
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {!!summary.firstInvestmentYear && !!summary.lastInvestmentYear && (
        <span className="flex items-center">
          <CalendarIcon className="h-4 w-4 inline-block mr-1" />
          Investments from {summary.firstInvestmentYear} to {summary.lastInvestmentYear}
        </span>
      )}
      {(!!summary.preSeedCount ||
        !!summary.seedCount ||
        !!summary.seriesACount ||
        !!summary.seriesBPlusCount) && (
        <span className="flex items-center">
          <ChartBarSquareIcon className="h-4 w-4 inline-block mr-1" />
          {!!summary.preSeedCount && (
            <span className="separated-comma">{summary.preSeedCount} Pre-Seed</span>
          )}
          {!!summary.seedCount && <span className="separated-comma">{summary.seedCount} Seed</span>}
          {!!summary.seriesACount && (
            <span className="separated-comma">{summary.seriesACount} Series A</span>
          )}
          {!!summary.seriesBPlusCount && (
            <span className="separated-comma">{summary.seriesBPlusCount} Series B+</span>
          )}
          &nbsp;rounds
        </span>
      )}
    </div>
  );
}
