import ChatInput from "@/components/messages/ChatInput";
import { Messages } from "@/components/messages/Messages";
import { useEntityChatStore } from "@/stores/entityChatStore";
import { useEntityStore } from "@/stores/entityStore";
import { useMessageStore } from "@/stores/messageStore";
import { useStore } from "@nanostores/react";
import { useEffect, useLayoutEffect, useState } from "react";

const SCROLL_THRESHOLD = 600;

export default function EntityAIChat() {
  const entityStore = useEntityStore();
  const entityChatStore = useEntityChatStore();
  const messageStore = useMessageStore();

  const messages = useStore(messageStore.messages);
  const entity = useStore(entityStore.entity);
  const error = useStore(messageStore.error);

  const [chatContainer, setChatContainer] = useState<HTMLDivElement | null>(null);
  const [prevMessageId, setPrevMessageId] = useState<string | undefined>(undefined);

  const isMessagesLoaded = messages.length > 0;

  // Scroll to bottom on initial load
  useLayoutEffect(() => {
    if (!isMessagesLoaded || !chatContainer) return;

    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [isMessagesLoaded, chatContainer]);

  // Load more messages as you scroll to the top
  useEffect(() => {
    if (!chatContainer) return;

    const handleScroll = () => {
      if (chatContainer.scrollTop < SCROLL_THRESHOLD && entityChatStore.canLoadMoreMessages()) {
        void entityChatStore.loadMessages();
      }
    };

    chatContainer.addEventListener("scroll", handleScroll);
    return () => {
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, [chatContainer, entity.id, entityChatStore]);

  // Scroll smoothly to the bottom when there is a new message (from the user or the AI)
  useEffect(() => {
    if (!chatContainer) return;

    const latestMessage = messages[0];
    const isNewMessage = latestMessage && (!prevMessageId || latestMessage.id !== prevMessageId);

    if (isNewMessage) {
      setPrevMessageId(latestMessage.id);
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, chatContainer, messageStore, prevMessageId]);

  return (
    <div className="flex flex-col h-[350px]">
      <div ref={setChatContainer} className="flex-1 overflow-y-auto">
        <Messages hasActions className="px-4" variant="entity">
          {error && <div className="text-red-500 text-sm p-2">{error}</div>}
        </Messages>
      </div>
      <ChatInput variant="entity" />
    </div>
  );
}
