import useAutosizeTextArea from "@/components/hooks/useAutosizeTextArea";
import { forwardRef, TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
type Props = { minHeightPx?: number } & TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = forwardRef<HTMLTextAreaElement, Props>(function TextArea(props, ref) {
  const { children, className, ...rest } = props;

  return (
    <textarea
      ref={ref}
      className={twMerge(
        "border border-gray-300 rounded-md p-2 text-black text-sm leading-6 min-h-[4rem]",
        className,
      )}
      {...rest}
    />
  );
});

export default TextArea;

export const AutoResizeTextArea = forwardRef<HTMLTextAreaElement, Props>(
  function AutoResizeTextArea(props, forwardedRef) {
    const [value, setValue] = useState((props.defaultValue as string) || "");
    const localRef = useRef<HTMLTextAreaElement>(null);
    const ref = (forwardedRef || localRef) as React.RefObject<HTMLTextAreaElement>;

    const { minHeightPx, ...restProps } = props;
    useAutosizeTextArea(ref.current, value, minHeightPx);

    useEffect(() => {
      setValue(props.defaultValue as string);
    }, [props.defaultValue]);

    return (
      <TextArea
        {...restProps}
        ref={ref}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange?.(e);
        }}
        defaultValue={undefined}
      />
    );
  },
);
