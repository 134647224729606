import moment from "moment";

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import AISummaryBox from "@/components/ui/AISummaryBox";
import IconList from "@/components/ui/IconList";
import { favicon, pluralize, smartTruncate, unescapeHtmlCharacterEntities } from "@/lib/utils";
import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection, References } from "@/types";
import { useStore } from "@nanostores/react";
import { uniq } from "lodash";
import { useCallback, useState } from "react";

export default function MediaCoverage() {
  const entityStore = useEntityStore();
  const snapshot = useStore(entityStore.snapshot);
  const [expanded, setExpanded] = useState(false);
  const summary = useStore(entityStore.mediaCoverageSummary);
  const authoredMedia = useStore(entityStore.authoredMedia);
  const authoredMediaUrls = new Set(authoredMedia?.map((m) => m.url) || []);
  const authoredMediaTitles = new Set(authoredMedia?.map((m) => m.title) || []);
  const crawlResults = useStore(entityStore.scrapedAuthoredMedia);

  const mediaArticles =
    snapshot?.references
      ?.filter((r) => snapshot.mediaLinkIds.includes(r.id))
      .filter((r) => !authoredMediaUrls.has(r.url) && !authoredMediaTitles.has(r.title)) || [];

  if (!mediaArticles.length) return null;

  const sortedMediaList = mediaArticles.toSorted((a, b) => {
    if (a.datePublished && b.datePublished) return b.datePublished.localeCompare(a.datePublished);
    return 0;
  });

  const iconUrls = uniq(sortedMediaList.map((m) => crawlResults[m.url]?.favicon).filter(Boolean));

  return (
    <ProfileBubble
      title={`In the News • ${pluralize(sortedMediaList.length, "source")}`}
      section={ProfilePageSection.MediaCoverage}
    >
      {sortedMediaList.length == 0 && <div className="text-gray-500">No media coverage</div>}
      {summary && (
        <>
          <AISummaryBox content={summary} />
          <hr className="my-4" />
        </>
      )}

      <div role="list" className="flex flex-col gap-4">
        Recent article
        {sortedMediaList
          .slice(0, expanded || sortedMediaList.length < 3 ? undefined : 1)
          .map((s, idx) => (
            <div key={idx}>
              <MediaArticleRow media={s} />
            </div>
          ))}
        {sortedMediaList.length > 3 && (
          <>
            <div
              className="text-blue-600 cursor-pointer mt-6 font-medium flex flex-row gap-1 items-center"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ?
                "Show less"
              : <>
                  <span className="mr-2">
                    {`Show ${pluralize(sortedMediaList.length - 1, "more article")}`}
                  </span>

                  <IconList iconUrls={iconUrls} count={15} />
                </>
              }
            </div>
          </>
        )}
      </div>
    </ProfileBubble>
  );
}

function MediaArticleRow({ media }: { media: Partial<References> }) {
  const [summaryShown, setSummaryShown] = useState<boolean>(false);
  const summaryClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setSummaryShown(!summaryShown);
    },
    [summaryShown],
  );
  return (
    <div className="flex flex-col gap-2">
      <a href={media.url} target="_blank" className="flex gap-4" rel="noreferrer">
        <img
          src={favicon({
            url: media.url || "",
            favicon: media.favicon,
          })}
          className="w-8 h-8 mt-1 rounded-md border border-gray-100"
          alt="favicon"
          onError={(e) => ((e.target as HTMLImageElement).style.display = "none")}
        />

        <div>
          <div className="font-semibold line-clamp-1">{media.title}</div>
          <div className="text-gray-400 text-sm">
            {media.datePublished && moment(media.datePublished).format("ll")}
            {media.author &&
              ` by ${smartTruncate(String(unescapeHtmlCharacterEntities(media.author)), 100)}`}
            {media.summary && (
              <>
                {" "}
                <span className="text-brand-500 hover:underline" onClick={summaryClick}>
                  {summaryShown ? "Hide summary" : "Read summary"}
                </span>
              </>
            )}
          </div>
        </div>
      </a>

      {summaryShown && media.summary && <AISummaryBox content={media.summary} />}
    </div>
  );
}
