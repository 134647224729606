import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { TagId, TagsById } from "@/tags/tags";
import { useStore } from "@nanostores/react";
import { useCallback } from "react";
import { Tooltip } from "react-tooltip";

export default function EntityTagTooltip() {
  return (
    <Tooltip
      id="entity-tag-tooltip"
      className="bg-white border !rounded-md z-10 p-2 text-sm w-40"
      opacity={1}
      variant="light"
      place="bottom"
      positionStrategy="fixed"
      clickable
      render={({ content }) => {
        return !content ? null : <EntityTagTooltipContent tagId={content} />;
      }}
    />
  );
}

export function EntityTagTooltipContent({ tagId }: { tagId: string }) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const isDev = useStore(uiStore.isDev);

  const handleRemoveTag = useCallback(() => {
    entityStore.deleteTag(tagId, entity);
  }, [tagId, entity, entityStore]);

  return (
    <div className="text-gray-800">
      <p className="text-sm">{TagsById[tagId as TagId]?.description || ""}</p>
      {isDev ?
        <>
          <hr className="my-2 border-gray-300" />
          <button
            onClick={handleRemoveTag}
            className="text-red-500 hover:text-red-700 text-sm font-medium"
          >
            Remove tag
          </button>
        </>
      : undefined}
    </div>
  );
}
