import { IconProps } from "@/components/icons/types";

export default function YoutubeIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_47_373)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM15.4698 5.09265C16.0718 5.25365 16.5464 5.72825 16.7074 6.33025C17 7.42225 17 10.4 17 10.4C17 10.4 17 13.3778 16.7074 14.4698C16.5464 15.0718 16.0725 15.5464 15.4698 15.7074C14.3778 16 10 16 10 16C10 16 5.6222 16 4.5302 15.7074C3.9282 15.5464 3.4536 15.0718 3.2926 14.4698C3 13.3778 3 10.4 3 10.4C3 10.4 3 7.42225 3.2926 6.33025C3.4536 5.72825 3.9282 5.25365 4.5302 5.09265C5.6222 4.80005 10 4.80005 10 4.80005C10 4.80005 14.3778 4.80005 15.4698 5.09265ZM9.125 8.27835C8.8919 8.14395 8.6 8.31195 8.6 8.58145V12.2186C8.6 12.4881 8.8919 12.6568 9.125 12.5217L12.275 10.7031C12.5081 10.5687 12.5081 10.2313 12.275 10.0969L9.125 8.27835Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_373">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
