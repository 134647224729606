import { twJoin } from "tailwind-merge";

interface Props {
  iconUrls: (string | undefined)[];
  count: number;
}

export default function IconList({ iconUrls, count }: Props) {
  return (
    <div className="flex flex-row gap-1 overflow-hidden">
      {iconUrls.filter(Boolean).map((pic, index) => (
        <img
          key={pic as string}
          src={pic as string}
          alt=""
          className={twJoin(
            "h-6 w-6 rounded-full border border-white",
            index < count ? "" : "hidden",
          )}
          onError={(e) => {
            (e.target as HTMLImageElement).style.display = "none";
          }}
        />
      ))}
    </div>
  );
}
