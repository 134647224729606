import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

import { prettyUrl } from "@/lib/utils";
import { messageStore } from "@/stores/messageStore";
import { AnchorHTMLAttributes } from "react";

/* eslint-disable react/no-children-prop */
export function MessageBody({ content }: { content: string }) {
  return (
    <ReactMarkdown
      className="message-body"
      children={content}
      remarkPlugins={[remarkBreaks]}
      components={{
        a: ({ children, ...props }: AnchorHTMLAttributes<HTMLAnchorElement>) => {
          // if the link is a wiki link, send it as a message
          if (props.href?.startsWith("#qq=")) {
            const question = children?.toString();
            const handleClick = (e: React.MouseEvent) => {
              e.preventDefault();
              if (question) {
                messageStore.setCurrentMessageContent(question);
                void messageStore.sendCurrentMessage();
              }
            };
            return (
              <a
                {...props}
                href="#"
                className="text-blue-500 cursor-pointer hover:underline"
                onClick={handleClick}
              >
                {children}
              </a>
            );
          }

          if (props.href?.includes("http")) {
            props.target = "_blank";
            props.rel = "noopener noreferrer";
            props["data-tooltip-content"] = prettyUrl(props.href, true);
          }
          return (
            <a data-tooltip-id="tooltip" {...props}>
              {children}
            </a>
          );
        },
      }}
    />
  );
}
