import {
  CompanyFact,
  CorporateFact,
  EntityFact,
  FactType,
  PersonFact,
  VCFact,
} from "@/types/facts";

/**
 * Facts that are calculated or derived from other data
 * These aren't stored directly as facts in the database
 */
export enum CalculatedFact {
  DistillURL = "distillURL",
  LinkedInURL = "linkedInURL",
  CrunchbaseURL = "crunchbaseURL",
  ShortBio = "shortBio",
  Highlights = "highlights",
  InvestmentsCount = "investmentsCount",
  TenureAtCurrentCompany = "tenureAtCurrentCompany",
  MutualConnectionCount = "mutualConnectionCount",
}

/**
 * Human-readable labels for calculated facts
 */
export const CALCULATED_FACT_LABELS: Record<CalculatedFact, string> = {
  [CalculatedFact.DistillURL]: "Distill URL",
  [CalculatedFact.LinkedInURL]: "LinkedIn URL",
  [CalculatedFact.CrunchbaseURL]: "Crunchbase URL",
  [CalculatedFact.ShortBio]: "Short Bio",
  [CalculatedFact.Highlights]: "Highlights",
  [CalculatedFact.InvestmentsCount]: "Investments Count",
  [CalculatedFact.TenureAtCurrentCompany]: "Tenure at Current Company",
  [CalculatedFact.MutualConnectionCount]: "Mutual Connection Count",
};

/**
 * Configuration for CSV exports
 * Each fact type can be toggled on/off
 */
export type CSVExportConfig = {
  [K in FactType]?: boolean;
} & {
  [K in CalculatedFact]?: boolean;
} & {
  includeAssessmentQuestions?: boolean;
};

/**
 * Whitelist of facts that can be exported
 * This is the source of truth for what facts are available in the CSV export UI
 */
export const WHITELISTED_FACTS: FactType[] = [
  // Person facts
  PersonFact.Location,

  // Company facts
  CompanyFact.FoundedYear,
  CompanyFact.Headquarters,
  CompanyFact.Industry,
  CompanyFact.CompanySize,
  CompanyFact.CompanyType,
  CompanyFact.TotalFunding,
  CompanyFact.TotalFundingRounds,
  CompanyFact.LatestRound,

  // VC facts
  VCFact.LatestFundSize,
  VCFact.InvestmentSectors,
  VCFact.RecentInvestments,
  VCFact.RecentLeadInvestmentsRollup, // Target Investment Stage
];
