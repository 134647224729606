import { IconProps } from "@/components/icons/types";

export default function GithubIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_7357_18243)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H13.5564C13.4993 20 13.4474 19.9932 13.4004 19.9811H13.7333C13.6699 19.994 13.6114 20 13.5574 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM7.51807 19.9811H13.4004C13.1361 19.9125 13.0279 19.6705 13.0279 19.4681C13.0279 19.3509 13.0293 19.1482 13.0312 18.8783C13.0349 18.3409 13.0406 17.5374 13.0406 16.6131C13.0406 15.6398 12.7223 15.0169 12.3531 14.6925C14.6193 14.4329 17 13.5505 17 9.56649C17 8.4245 16.6053 7.50312 15.956 6.7764C16.0579 6.51685 16.4144 5.45272 15.8542 4.02523C15.8542 4.02523 15.0012 3.73974 13.0533 5.08936C12.2385 4.85577 11.3728 4.73898 10.5071 4.73898C9.64135 4.73898 8.77563 4.85577 7.96083 5.08936C6.01295 3.75271 5.15996 4.02523 5.15996 4.02523C4.59979 5.45272 4.95626 6.51685 5.05811 6.7764C4.40882 7.50312 4.01415 8.43748 4.01415 9.56649C4.01415 13.5375 6.38216 14.4329 8.64832 14.6925C8.3555 14.952 8.08814 15.4062 7.99902 16.081C7.41339 16.3536 5.9493 16.7948 5.03265 15.2246C4.84168 14.9131 4.26877 14.1474 3.46671 14.1604C2.61371 14.1734 3.12296 14.6536 3.47944 14.8482C3.9123 15.0948 4.40882 16.0162 4.5234 16.3146C4.7271 16.8986 5.38912 18.0146 7.9481 17.5345C7.9481 18.1018 7.95352 18.6471 7.95729 19.026C7.95929 19.2278 7.96083 19.3824 7.96083 19.4681C7.96083 19.6762 7.81415 19.9185 7.51807 19.9811ZM7.2 19.9811H7.51807C7.42639 20.0004 7.3204 20.0025 7.2 19.9811Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_7357_18243">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
