import { useSidebar } from "@/components/providers/SidebarProvider";
import Avatar from "@/components/ui/Avatar";
import ConditionalLink from "@/components/ui/ConditionalLink";
import ProgressCircle from "@/components/ui/ProgressCircle";
import { OnboardingLocalStorage } from "@/stores/onboardingV2Store";
import { uiStore } from "@/stores/uiStore";
import { COLOR_ACTIVABLE, COLOR_ACTIVE, COLOR_INACTIVE } from "@/styles/constants";
import { userMeta, WAITLIST_URL } from "@/types";
import {
  ArrowTopRightOnSquareIcon,
  BriefcaseIcon,
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

export type MobileNavSidebarProps = {
  title?: string;
};

type NavItem = {
  href?: string;
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  isExternal?: boolean;
};

type UserMenuItem = {
  text: string;
  href?: string;
  onClick?: () => void;
  isExternal?: boolean;
  className?: string;
  icon?: React.ReactNode;
};

export default function MobileNavSidebar({ title }: MobileNavSidebarProps) {
  const user = useStore(uiStore.user);

  const isImpersonating = useStore(uiStore.isImpersonating);
  const { closeSidebar } = useSidebar();
  const [userSectionExpanded, setUserSectionExpanded] = useState(false);
  const [adminSectionExpanded, setAdminSectionExpanded] = useState(false);

  // Update admin check to properly detect admin users
  const isDev = useStore(uiStore.isDev);
  const isAdmin =
    isDev || isImpersonating || user?.email?.endsWith("@distill.fyi") || user?.meta?.dev;

  const entityVisitCount = useStore(uiStore.entityVisitCount);
  const profileVisits = (entityVisitCount?.visitedIds as string[])?.length;
  const maxVisits = useStore(uiStore.maxEntityVisits);
  const cappedProfileVisits = Math.min(profileVisits, maxVisits);

  if (!user) {
    return null;
  }
  const toggleUserSection = () => {
    setUserSectionExpanded(!userSectionExpanded);
  };

  const toggleAdminSection = () => {
    setAdminSectionExpanded(!adminSectionExpanded);
  };

  const handleLogout = () => {
    uiStore.logOut();
    closeSidebar();
  };

  const handleStopImpersonating = () => {
    uiStore.clearImpersonatingUser();
    closeSidebar();
  };

  const handleContactUs = () => {
    uiStore.showSupportModal(user);
    closeSidebar();
  };

  const handleResetOnboarding = () => {
    for (const key of Object.values(OnboardingLocalStorage)) {
      localStorage.removeItem(key);
    }
    closeSidebar();
  };

  // Get user LinkedIn profile
  const liProfile = user ? userMeta(user).li_profile : null;

  const userOptionClassName =
    "block w-full py-2 px-4 text-left text-sm text-gray-700 hover:bg-gray-100 rounded-md";

  // Main navigation items config
  const navItems: NavItem[] = [
    {
      href: "/dashboard",
      text: "Dashboard",
      icon: <HomeIcon className="w-5 h-5 " />,
    },
    {
      href: "/search/basic",
      text: "Search",
      icon: <MagnifyingGlassIcon className="w-5 h-5 " />,
    },
    {
      href: "/search/advanced",
      text: "Discover",
      icon: <BriefcaseIcon className="w-5 h-5 " />,
    },
    {
      href: "/recents",
      text: "Recents",
      icon: <ClockIcon className="w-5 h-5 " />,
    },
    {
      href: "/lists",
      text: "Lists",
      icon: <QueueListIcon className="w-5 h-5 " />,
    },
    {
      text: "Feedback",
      icon: <ChatBubbleLeftRightIcon className="w-5 h-5" />,
      onClick: handleContactUs,
    },
    {
      text: `Profile Views (${cappedProfileVisits} / ${maxVisits})`,
      className: "hover:text-gray-400 text-gray-400",
      icon: (
        <ProgressCircle
          progress={Math.min((cappedProfileVisits || 0) / maxVisits, 1)}
          size={20}
          color={"#bdbdbd"}
        />
      ),
    },
  ];

  // User menu items config
  const userMenuItems: UserMenuItem[] = [
    ...(isImpersonating ?
      [
        {
          text: "Stop impersonating",
          onClick: handleStopImpersonating,
          className: "text-red-600 font-bold hover:bg-red-50",
        },
      ]
    : []),
    ...(liProfile ?
      [
        {
          text: "View my Profile",
          href: `/profile?url=${encodeURIComponent(`https://www.linkedin.com/in/${liProfile}`)}`,
        },
      ]
    : []),
    {
      text: "Settings",
      href: "/settings",
    },
    {
      text: "Manage Calendars",
      href: "/settings/connect",
    },
    {
      text: "Get Chrome Extension",
      href: "https://chromewebstore.google.com/detail/distill/kidifhledoijjifmngjkaclhdoffdneg",
      isExternal: true,
      icon: <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />,
    },
    {
      text: "Meetings",
      href: "/meetings",
    },
    {
      text: "Help Center",
      href: "https://help.distill.fyi/hc/en-us",
      isExternal: true,
      icon: <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />,
    },
    {
      text: "Contact Us",
      onClick: handleContactUs,
    },
    ...(!isImpersonating ?
      [
        {
          text: "Log Out",
          onClick: handleLogout,
          className: "text-red-600 hover:bg-red-50",
        },
      ]
    : []),
  ];

  // Admin menu items config
  const adminMenuItems: UserMenuItem[] = [
    { text: "User List", href: "/admin/users" },
    { text: "Invite List", href: "/admin/invites" },
    { text: "Email Resolutions", href: "/admin/emails" },
    { text: "All Entities", href: "/admin/entities" },
    { text: "AI Playground", href: "/admin/playground" },
    { text: "Evaluations", href: "/admin/evals" },
    { text: "Reset Onboarding", onClick: handleResetOnboarding },
    { text: "Dogfood Profiles", href: "/search?personal_connections=true&type=pe" },
    { text: "Profile Quality Dashboard", href: "/feedback/profile_quality" },
    { text: "Profile Review List", href: "/feedback/profile_reviews" },
    { text: "Global Cookies", href: "/admin/cookies" },
    { text: "Blocked Sources", href: "/admin/blocked_sources" },
    { text: "Source Notes", href: "/admin/source_notes" },
  ];

  return (
    <div className="flex flex-col bg-white h-full relative">
      <div className="flex-1 overflow-y-auto">
        {user && (
          <div className="px-2 py-2">
            <div className="flex flex-col space-y-1">
              {navItems.map((item, index) => (
                <ConditionalNavLink
                  key={index}
                  href={item.href}
                  onClick={item.onClick ?? closeSidebar}
                  className={item.className}
                >
                  <div className="flex items-center">
                    {item.icon}
                    <span className="ml-3">{item.text}</span>
                  </div>
                </ConditionalNavLink>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="border-t border-gray-200 absolute bottom-14 w-full pt-4 bg-white">
        {user ?
          <div>
            <div
              className="flex items-center justify-between cursor-pointer px-4"
              onClick={toggleUserSection}
            >
              <div className="flex items-center">
                <div className="mr-3">
                  <Avatar user={user} className="w-8 h-8" />
                </div>
                <div>
                  <p className="text-base font-medium text-gray-900">{user.name}</p>
                  <p className="text-sm text-gray-500">{user.email}</p>
                </div>
              </div>

              {userSectionExpanded ?
                <ChevronUpIcon className="h-5 w-5 text-gray-400 transition-transform duration-300" />
              : <ChevronDownIcon className="h-5 w-5 text-gray-400 transition-transform duration-300" />
              }
            </div>

            {/* Expandable user section */}
            <div
              className={`overflow-hidden transition-all duration-300 ease-in-out ${
                userSectionExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
              }`}
            >
              <div className="border-t border-gray-100 pt-4 mt-4">
                <div className="mt-1 space-y-2 pb-2">
                  {userMenuItems.map((item, index) => {
                    const className = twMerge(userOptionClassName, item.className);

                    if (item.href) {
                      return (
                        <Link
                          key={index}
                          href={item.href}
                          className={item.isExternal ? `flex items-center ${className}` : className}
                          onClick={closeSidebar}
                          {...(item.isExternal ?
                            { target: "_blank", rel: "noopener noreferrer" }
                          : {})}
                        >
                          {item.text}
                          {item.icon}
                        </Link>
                      );
                    } else {
                      return (
                        <button
                          key={index}
                          type="button"
                          className={className}
                          onClick={() => {
                            item.onClick?.();
                            closeSidebar();
                          }}
                        >
                          {item.text}
                        </button>
                      );
                    }
                  })}

                  {/* Admin section toggle */}
                  {isAdmin && (
                    <div className="pt-1">
                      <button
                        type="button"
                        onClick={toggleAdminSection}
                        className="flex justify-between items-center w-full py-2 px-4 text-left text-sm font-bold bg-brand-100 hover:bg-brand-200 rounded-md"
                      >
                        {adminSectionExpanded ? "Hide" : "Show"} Admin Links
                        {adminSectionExpanded ?
                          <ChevronUpIcon className="h-4 w-4" />
                        : <ChevronDownIcon className="h-4 w-4" />}
                      </button>

                      {/* Admin links */}
                      <div
                        className={`overflow-hidden transition-all duration-300 ease-in-out ${
                          adminSectionExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
                        }`}
                      >
                        <div className="space-y-1 mt-1 bg-brand-50 rounded-md py-1 max-h-[40vh] overflow-y-auto">
                          {/* Regular admin menu items */}
                          {adminMenuItems.map((item, index) => {
                            if (item.href) {
                              return (
                                <Link
                                  key={index}
                                  href={item.href}
                                  className={userOptionClassName}
                                  onClick={closeSidebar}
                                >
                                  {item.text}
                                </Link>
                              );
                            } else {
                              return (
                                <button
                                  key={index}
                                  type="button"
                                  className={userOptionClassName}
                                  onClick={() => {
                                    item.onClick?.();
                                    closeSidebar();
                                  }}
                                >
                                  {item.text}
                                </button>
                              );
                            }
                          })}

                          {/* Special case for Feature Flags */}
                          <button
                            type="button"
                            className={userOptionClassName}
                            data-attr="posthog-early-access-features-button"
                            data-tooltip-content="Feature Flags"
                            data-tooltip-id="tooltip"
                          >
                            Feature Flags
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        : <div className="flex flex-col space-y-4 p-4">
            <Link
              href="/signin"
              className="inline-flex justify-center rounded-md bg-brand-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-500"
              onClick={closeSidebar}
            >
              Sign in
            </Link>
            <Link
              href={WAITLIST_URL}
              className="inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={closeSidebar}
            >
              Join Waitlist
            </Link>
          </div>
        }
      </div>
    </div>
  );
}

const ConditionalNavLink = ({
  href,
  onClick,
  children,
  className,
}: {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
}) => {
  const pathname = usePathname();

  const classNames = twMerge(
    pathname === href ? COLOR_ACTIVE : COLOR_INACTIVE,
    COLOR_ACTIVABLE,
    "flex items-center px-3 py-3 text-base font-medium rounded-md w-full",
    className,
  );

  return (
    <ConditionalLink className={classNames} href={href} onClick={onClick}>
      {children}
    </ConditionalLink>
  );
};
