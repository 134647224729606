/* eslint-disable @next/next/no-img-element */
import FactInFactTable from "@/components/companies/FactInFactTable";
import SourceTooltipLink, {
  getReferenceDataToShowFromURL,
  ReferenceData,
} from "@/components/entities/SourceTooltipLink";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { useEntityStore } from "@/stores/entityStore";
import { FactType, ProfilePageSection } from "@/types";
import { buildStatsToShow } from "@/utils/entityUtils";
import { useStore } from "@nanostores/react";
import { uniqWith } from "lodash";
import { useState } from "react";

export default withErrorBoundary(function CompanyStats() {
  const entityStore = useEntityStore();
  const snapshot = useStore(entityStore.snapshot);
  const [refHovered, setRefHovered] = useState<number | undefined>(undefined);
  const [factHovered, setFactHovered] = useState<FactType | undefined>(undefined);

  if (!snapshot.facts) return null;
  const facts = snapshot.facts;

  const statsToShow = buildStatsToShow(facts);

  if (!statsToShow.length) {
    return null;
  }

  const factToRef: Partial<Record<FactType, number>> = {};
  const refToFact: Record<number, FactType[]> = {};
  const references: Array<ReferenceData> = uniqWith(
    (
      statsToShow
        .map((type) => {
          const ref = getReferenceDataToShowFromURL(snapshot, facts[type]?.sourceUrl || undefined);
          if (ref) {
            factToRef[type] = ref.idx;
            refToFact[ref.idx] = (refToFact[ref.idx] || []).concat([type]);
          }
          return ref;
        })
        .filter(Boolean) as ReferenceData[]
    ).toSorted((a, b) => a.idx - b.idx),
    (a, b) => a.idx === b.idx,
  );
  const factsToHighlight = (refHovered && refToFact[refHovered]) || [];
  const refToHighlight = (factHovered && factToRef[factHovered]) || undefined;

  return (
    <ProfileBubble title="Details" section={ProfilePageSection.Stats}>
      <div className="flex flex-wrap justify-between">
        {statsToShow.map((type) => (
          <FactInFactTable
            onMouseOver={() => setFactHovered(type)}
            onMouseOut={() => setFactHovered(undefined)}
            key={type}
            fact={facts[type]}
            type={type}
            snapshot={snapshot}
            highlight={factsToHighlight.includes(type)}
          />
        ))}
      </div>
      {references.length > 0 && (
        <div className="mt-4 w-full items-center justify-end flex flex-row">
          <div className="mr-1">Citations:</div>
          {references.map((reference) => (
            <div
              className="mr-1"
              key={reference.idx}
              onMouseOver={() => setRefHovered(reference.idx)}
              onMouseOut={() => setRefHovered(undefined)}
            >
              <SourceTooltipLink
                highlight={refToHighlight === reference.idx}
                title={reference.url}
                href={reference?.citationUrl}
              >
                {reference.idx}
              </SourceTooltipLink>
            </div>
          ))}
        </div>
      )}
    </ProfileBubble>
  );
});
