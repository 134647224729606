import { EntityType } from "@/types";
import { Tag, tagId } from "./tags";

export const VcCompanyTags = {
  VcFirm: {
    id: tagId("vc-firm"),
    name: "VC Firm",
    description: "Firm investing in startups for high-growth returns.",
  },

  AngelSyndicate: {
    id: tagId("angel-syndicate"),
    name: "Angel Syndicate",
    description: "Invests in startups through an angel syndicate.",
  },

  StartupAccelerator: {
    id: tagId("startup-accelerator"),
    name: "Startup Accelerator",
    description: "Invests in startups through an accelerator program.",
  },

  StartupIncubator: {
    id: tagId("startup-incubator"),
    name: "Startup Incubator",
    description: "Invests in startups through an incubator program.",
  },

  VentureStudio: {
    id: tagId("venture-studio"),
    name: "Venture Studio",
    description: "A venture studio invests in startups it develops internally.",
  },
} as const;

Object.values(VcCompanyTags).forEach((tag) => {
  (tag as Tag).type = EntityType.Company;
});

export const VcPersonTags = {
  VCInvestor: {
    id: tagId("vc-investor"),
    name: "VC Investor",
    description: "Professional investor at a venture capital firm",
  },

  AngelInvestor: {
    id: tagId("angel-investor"),
    name: "Angel Investor",
    description: "Invests in startups through personal angel checks.",
  },
} as const;

Object.values(VcPersonTags).forEach((tag) => {
  (tag as Tag).type = EntityType.Person;
});
