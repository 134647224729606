import { format, isValid, parseISO } from "date-fns";
import { groupBy, startCase, toLower } from "lodash";

import { CurrencyFormatters } from "@/extractors/currency";
import { formatNumber } from "@/lib/stringUtils";
import {
  CompanyFact,
  EntityFact,
  FactsPick,
  FactType,
  FactValueSet,
  PersonFact,
  VCFact,
} from "@/types";

export function getLabel(type: FactType) {
  switch (type) {
    case EntityFact.Name:
      return "Name";
    case EntityFact.Description:
      return "Description";
    case EntityFact.About:
      return "About";
    case EntityFact.ImageUrl:
      return "Image URL";
    case PersonFact.Birthday:
      return "Birthday";
    case PersonFact.Birthyear:
      return "Birth Year";
    case PersonFact.Gender:
      return "Gender";
    case PersonFact.Pronouns:
      return "Pronouns";
    case PersonFact.Height:
      return "Height";
    case PersonFact.AltNames:
      return "Alternative Names";
    case PersonFact.LanguagesSpoken:
      return "Languages Spoken";
    case PersonFact.Location:
      return "Location";
    case PersonFact.LocationGeocoded:
      return "Location (Geocoded)";
    case CompanyFact.Employees:
      return "Employees";
    case CompanyFact.FoundedYear:
      return "Founded Year";
    case CompanyFact.Headquarters:
      return "Headquarters";
    case CompanyFact.HeadquartersGeocoded:
      return "Headquarters (Geocoded)";
    case CompanyFact.Industry:
      return "Industry";
    case CompanyFact.CompanySize:
      return "Company Size";
    case CompanyFact.CompanyType:
      return "Company Type";
    case CompanyFact.TotalFunding:
      return "Total Funding";
    case CompanyFact.TotalFundingRounds:
      return "Total Funding Rounds";
    case CompanyFact.LatestFundDate:
      return "Latest Fund Date";
    case CompanyFact.LatestRound:
      return "Latest Round";
  }
  return type;
}

type LatestRound = FactValueSet[typeof CompanyFact.LatestRound];

export function formatValue<T extends FactType>(type: T, value: FactValueSet[T]) {
  switch (type) {
    case CompanyFact.InvestmentRounds:
    case CompanyFact.InvestmentGeographies:
      return value as string[];
    case CompanyFact.InvestmentSectors:
      return (value as string[]).filter((s) => s.length < 25).slice(0, 5);
    case CompanyFact.FamousInvestments:
      return (value as string[]).slice(0, 3);
    case CompanyFact.LatestFundSize:
      return CurrencyFormatters.Dollars(value as number | string);
    case CompanyFact.LatestFundDate:
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const date = parseISO(String(value));
      if (!isValid(date)) {
        return "";
      }
      return format(date, "MMMM d, yyyy");
    case CompanyFact.RecentInvestments:
      // For CSV export, convert complex objects to a simplified list of company names
      const investments = value as any[];
      if (!investments || !Array.isArray(investments)) return [];
      return investments
        .sort((a, b) => (b.year || 0) - (a.year || 0)) // Sort by year descending
        .slice(0, 10) // Limit to 10 investments for readability
        .map((investment) => {
          const company = investment.company?.name || "";
          const year = investment.year?.toString() || "";
          const stage = investment.stage || "";
          const isLead = investment.lead ? " (Lead)" : "";
          return `${company} - ${year} ${stage}${isLead}`;
        });
    case CompanyFact.RecentLeadInvestmentsRollup:
      // This is the "Target Investment Stage" - convert object to readable text
      const stages = value as Record<string, number>;
      if (!stages || typeof stages !== "object") return [];
      return Object.entries(stages)
        .map(([stage, count]) => `${stage} (${count})`)
        .sort((a, b) => b.length - a.length);
    case CompanyFact.State:
      return formatCompanyState(value as FactValueSet[typeof CompanyFact.State]);
    case CompanyFact.Employees:
      return formatNumber(value as number);
    case CompanyFact.LatestRound:
      const typedValue = value as LatestRound;
      const amount = Number(typedValue.moneyRaised);
      const details = [formatFundingType(typedValue), CurrencyFormatters.Dollars(amount)].filter(
        Boolean,
      );
      return details;
    default:
      return value;
  }
}

function formatFundingType({ fundingType }: LatestRound) {
  if (!fundingType) {
    return undefined;
  }

  const fundingTypeLower = toLower(fundingType);
  return !fundingTypeLower.includes("unknown") ? startCase(fundingTypeLower) : undefined;
}

function formatCompanyState(state: FactValueSet[typeof CompanyFact.State]) {
  switch (state) {
    case "ACTIVE":
      return "Active";
    case "ACQUIRED":
      return "Acquired";
    case "POSSIBLY_ACQUIRED":
      return "Possibly Acquired";
    case "INACTIVE":
      return "Inactive";
    case "POSSIBLY_INACTIVE":
      return "Unsure";
    default: {
      const _exhaustiveCheck: never = state;
      throw new Error(`Unhandled variant: ${String(state)}`);
    }
  }
  return state;
}

export function aggregateHeadcount(
  historicalHeadcount: { date: string; headcount: number }[] | undefined,
) {
  if (!historicalHeadcount) return [];
  const groupedByYear = groupBy(historicalHeadcount, (item) => new Date(item.date).getFullYear());
  return Object.entries(groupedByYear)
    .map(([year, data]) => ({
      date: year,
      headcount: Math.max(...data.map((d) => d.headcount)),
    }))
    .sort((a, b) => Number(a.date) - Number(b.date));
}

export function getHeadcountFromFacts(facts: FactsPick) {
  const headcount = facts[CompanyFact.Employees]?.value;

  const aggregatedHeadcount = aggregateHeadcount(facts[CompanyFact.HistoricalHeadcount]?.value);
  const mostRecentHeadcount =
    aggregatedHeadcount.length > 0 ?
      aggregatedHeadcount[aggregatedHeadcount.length - 1]?.headcount
    : 0;
  return headcount || mostRecentHeadcount || 0;
}
