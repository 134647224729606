import { IconProps } from "@/components/icons/types";

export default function CrunchbaseIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_47_365)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM16.6683 14.0635C16.3567 14.3268 15.993 14.5431 15.596 14.693L15.5712 14.7011C15.1618 14.8607 14.6878 14.9538 14.1923 14.9538C13.4025 14.9538 12.6691 14.7191 12.0609 14.317V14.7011H10.4972V4H12.0501V8.16085C12.5755 7.80925 13.2128 7.57836 13.9006 7.52408L13.9146 7.52327H14.1939H14.1997C16.2987 7.52327 18 9.18648 18 11.2385C18 12.3671 17.4854 13.3773 16.6733 14.0586L16.6683 14.0635ZM5.82598 13.4C5.49782 13.4 5.18624 13.3295 4.92024 13.208V13.2096L4.90698 13.2039C4.15952 12.8645 3.6416 12.124 3.6416 11.2653C3.6416 10.0857 4.62026 9.12896 5.82681 9.12896C6.70437 9.12896 7.46178 9.63529 7.81479 10.379H9.53346L9.52766 10.3531C9.11166 8.73037 7.61011 7.52894 5.82101 7.52894C3.71121 7.52894 2 9.20106 2 11.2645C2 13.3279 3.71038 15 5.82101 15C7.61011 15 9.11166 13.7986 9.53346 12.1499H7.81479L7.80899 12.1629C7.46178 12.8937 6.70437 13.4 5.82598 13.4ZM16.4367 11.3161C16.4367 11.3077 16.4365 11.2992 16.4363 11.2912C16.4361 11.2837 16.4359 11.2765 16.4359 11.2699L16.4367 11.2675C16.4185 10.4987 15.9835 9.82951 15.3338 9.46414L15.3446 9.46982C15.0247 9.28916 14.6534 9.18627 14.2582 9.18627C13.0549 9.18627 12.0796 10.1398 12.0796 11.3161C12.0796 12.4924 13.0549 13.446 14.2582 13.446C15.4614 13.446 16.4367 12.4924 16.4367 11.3161Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_365">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
