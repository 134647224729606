import { IconProps } from "@/components/icons/types";

export default function TiktokIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_47_372)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM15.1839 8.77578C15.2907 8.77578 15.396 8.76929 15.5 8.75957V6.59645C13.8399 6.47602 12.5119 5.16333 12.3388 3.5H10.4991L10.4798 12.5758C10.4798 13.6893 9.50733 14.5666 8.40609 14.5666C7.30486 14.5666 6.41251 13.6643 6.41251 12.5508C6.41251 11.4373 7.30486 10.535 8.40609 10.535C8.46741 10.535 8.5267 10.5432 8.58604 10.5514C8.60649 10.5542 8.62695 10.557 8.6475 10.5595V8.6132C8.62846 8.61199 8.60944 8.61065 8.59042 8.6093C8.52936 8.605 8.46826 8.60069 8.40609 8.60069C6.24897 8.60069 4.5 10.3692 4.5 12.5503C4.5 14.7315 6.24897 16.5 8.40609 16.5C10.5632 16.5 12.3122 14.7315 12.3122 12.5503V7.20648C12.9265 8.15093 13.9824 8.77578 15.1839 8.77578Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_372">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
