import API from "@/client/api";
import CompanyHeader from "@/components/experiences/CompanyHeader";
import EditExperienceRow from "@/components/experiences/EditExperienceRow";
import ExperienceDetailsRow from "@/components/experiences/ExperienceDetailsRow";
import useSubmitButton from "@/components/hooks/useSubmitButton";
import ComboBox, {
  ComboBoxItemType,
  makeComboBoxItemsFromEnum,
} from "@/components/inputs/ComboBox";
import TextField from "@/components/inputs/TextField";
import WithLabel from "@/components/inputs/WithLabel";
import { SidebarVariant, useSidebar } from "@/components/providers/SidebarProvider";
import Button, { ButtonVariant } from "@/components/ui/Button";
import ExpandoList from "@/components/ui/ExpandoList";
import Spinner from "@/components/ui/Spinner";
import { prettyError } from "@/lib/miscUtils";
import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import {
  Entity,
  PersonCompanyRelationshipLabels,
  ProfilePageSection,
  RelationshipWithEntity,
  sectionToExperienceNoun,
  sectionToRelationshipType,
} from "@/types";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { use, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { twJoin } from "tailwind-merge";

interface Props {
  groupedExperiences: RelationshipWithEntity[];
  section: ProfilePageSection;
  // This is used to add a whole grouped experience (e.g. new company with
  // multiple positions).
  addMode?: boolean;
  onCancelAddMode?: () => void;
  mini?: boolean;
  forceExpanded?: boolean;
  // For testability
  initialEditMode?: boolean;
}

function createEmptyPosition(position: RelationshipWithEntity) {
  return {
    fromId: position.fromId,
    toId: position.toId,
    toName: position.toName,
    type: position.type,
    to: position.to,
  };
}

export default function GroupedExperienceRow({
  groupedExperiences,
  section,
  initialEditMode,
  addMode,
  onCancelAddMode,
  mini,
  forceExpanded,
}: Props) {
  const entityStore = useEntityStore();
  const canEdit = useStore(entityStore.canEdit);
  const entity = useStore(entityStore.entity);
  const { openSidebar, closeSidebar, isOpen: sidebarIsOpen } = useSidebar();

  const [editMode, setEditMode] = useState(initialEditMode || addMode || false);
  const [initialized, setInitialized] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [addingNewPosition, setAddingNewPosition] = useState(addMode || false);
  const [addModeCompany, setAddModeCompany] = useState<
    { id?: string; name?: string; company?: Entity } | undefined
  >();
  const [loadingAddModeCompany, setLoadingAddModeCompany] = useState(false);
  // The finish editing button can be blocked by subcomponents - usually
  // when they are in a state that would cancel pending updates if the user finishes
  // editing. We track them here by index.
  const [blockFinishEditing, setBlockFinishEditing] = useState<boolean[]>([]);
  const blockFinishEditingForIndex = (block: boolean, index: number) => {
    setBlockFinishEditing((prev: boolean[]) => {
      const newBlock = [...prev];
      newBlock[index] = block;
      return newBlock;
    });
  };

  const experienceNoun = sectionToExperienceNoun(section);

  useEffect(() => {
    if (addMode && !sidebarIsOpen && !addModeCompany) {
      if (!initialized) {
        openSearchSidebar("");
        setInitialized(true);
      } else {
        onCancelAddMode?.();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMode, sidebarIsOpen]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape" && editMode) {
        setEditMode(false);
        if (addMode) {
          onCancelAddMode?.();
        }
      }
    };

    if (editMode || addMode) {
      document.addEventListener("keydown", handleEscape);
    }
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [editMode, addMode, onCancelAddMode]);

  const willBlockFinishEditing = blockFinishEditing.some((b) => b);

  const { SubmitButton, setSubmitting } = useSubmitButton();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!editMode) {
      setAddingNewPosition(false);
    }
  }, [editMode]);

  // If the source data changes it means that the user has added a new position
  // TODO: It might also mean they updated a position, in which case we might not
  // want to reset the addingNewPosition state.
  useEffect(() => {
    if (!addMode) {
      setAddingNewPosition(false);
    }
  }, [groupedExperiences, addMode]);

  const firstExperience = groupedExperiences[0];
  const lastExperience =
    groupedExperiences.length > 0 ? groupedExperiences[groupedExperiences.length - 1] : undefined;
  const addModeFakePosition =
    addModeCompany ?
      {
        fromId: entity.id,
        toId: addModeCompany.id,
        toName: addModeCompany.name,
        type: sectionToRelationshipType(section),
        to: addModeCompany.company,
      }
    : groupedExperiences.length > 0 ? createEmptyPosition(groupedExperiences[0])
    : undefined;

  const showOnlyCompanyHeader =
    section === ProfilePageSection.Investments || (mini && !editMode && !addMode);

  const relationshipTypes: ComboBoxItemType[] = [
    //{ id: "", name: UNSPECIFIED },
    ...makeComboBoxItemsFromEnum(PersonCompanyRelationshipLabels),
  ];

  const selectCompany = useCallback(
    async ({ id, name, company }: { id?: string; name?: string; company?: Entity }) => {
      if (addMode) {
        setAddModeCompany({ id, name, company });
      } else if (firstExperience) {
        const toId = firstExperience.toId ?? firstExperience.to?.id ?? undefined;
        await entityStore.batchUpdateRelationships(
          {
            toId,
            toName: toId ? undefined : (firstExperience.toName ?? firstExperience.to?.name),
            entityId: firstExperience.fromId,
          },
          {
            toId: company?.id || null,
            to: company,
          },
        );
      }
    },
    [addMode, firstExperience, entityStore],
  );

  const onSelect = useCallback(
    async (url: string) => {
      const entity = await API.createEntity({ url });
      if (!entity) {
        toast.error("Error finding entity");
        return;
      }
      await selectCompany({ company: entity });
    },
    [selectCompany],
  );

  const openSearchSidebar = (query: string) => {
    openSidebar(SidebarVariant.Association, {
      alias: query,
      autoSearch: query.length > 0,
      onSelect,
      title: "Find the right Organization",
      onClose: () => {
        closeSidebar();
      },
      type: "companies",
    });
  };

  if (groupedExperiences.length === 0 && !addMode) {
    return null;
  }

  return (
    <div
      className="group flex flex-col flex-1 gap-2 static"
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      {loadingAddModeCompany && <Spinner />}
      {((!!firstExperience && !!lastExperience) || !!addModeFakePosition) && (
        <CompanyHeader
          firstPosition={
            (firstExperience || addModeFakePosition) as Partial<RelationshipWithEntity>
          }
          lastPosition={(lastExperience || addModeFakePosition) as Partial<RelationshipWithEntity>}
          pageSection={section}
          useFirstPositionDescription={false}
          editMode={editMode}
          mini={mini && !editMode && !addMode && !forceExpanded}
          totalNumRoles={groupedExperiences.length}
          titleButtons={
            <>
              {canEdit && hovering && !editMode && (
                <div
                  className="flex flex-row group rounded-md p-1 bg-gray-100 hover:bg-gray-200 border border-gray-200 h-5 w-5"
                  onClick={() => setEditMode(true)}
                >
                  <PencilIcon className="flex-shrink-0 cursor-pointer group-hover:text-gray-600  text-gray-500" />
                </div>
              )}
              {canEdit && editMode && !addMode && !willBlockFinishEditing && !addingNewPosition && (
                <>
                  <Button variant={ButtonVariant.SmallSecondary} onClick={() => setEditMode(false)}>
                    Finish editing
                  </Button>
                </>
              )}
            </>
          }
        />
      )}
      {editMode && !addMode && (
        <div className="flex flex-row gap-2 pl-14">
          <Button
            variant={ButtonVariant.SmallSecondary}
            onClick={() => {
              openSearchSidebar(firstExperience.to?.name || firstExperience.toName || "");
            }}
          >
            Change organization
          </Button>
          <Button
            variant={ButtonVariant.SmallSecondary}
            onClick={() => {
              uiStore.showConfirmModal.set({
                type: "danger",
                title:
                  "Delete experiences at " +
                  (firstExperience.to?.name || firstExperience.toName || "") +
                  "?",
                subtitle:
                  "Delete all experiences with the organization? The action is not reversible.",
                onClick: async () => {
                  try {
                    await entityStore.batchDeleteRelationships({
                      toId: firstExperience.toId || undefined,
                      toName:
                        firstExperience.toId ? undefined : firstExperience.toName || undefined,
                      entityId: firstExperience.fromId,
                    });
                  } catch (e) {
                    toast.error("Error deleting relationships: " + prettyError(e));
                  }
                },
              });
            }}
          >
            Delete
          </Button>
          <ComboBox
            name="type"
            items={relationshipTypes}
            placeholder={"Reclassify as..."}
            value={undefined}
            onSelect={async (typeOrArray) => {
              const type = Array.isArray(typeOrArray) ? typeOrArray[0] : typeOrArray;
              try {
                const toId = firstExperience.toId ?? firstExperience.to?.id ?? undefined;
                await entityStore.batchUpdateRelationships(
                  {
                    toId,
                    toName: toId ? undefined : (firstExperience.toName ?? firstExperience.to?.name),
                    entityId: firstExperience.fromId,
                  },
                  {
                    type: type.id,
                  },
                );
              } catch (e) {
                setError(prettyError(e));
              }
            }}
          />
        </div>
      )}
      {!showOnlyCompanyHeader && !addMode && (
        <ExpandoList
          items={groupedExperiences}
          limit={1}
          seeMoreClassName="text-left ml-14 mt-2 mb-4"
          className={twJoin("list-inside text-gray-700 gap-4 flex flex-col")}
          forceExpanded={editMode || forceExpanded}
          itemName={experienceNoun}
          renderItem={(position: RelationshipWithEntity, index) => (
            <ExperienceDetailsRow
              key={index}
              position={position}
              showDates={groupedExperiences.length != 1}
              sectionEditMode={editMode}
              blockFinishEditing={(block) => blockFinishEditingForIndex(block, index)}
            />
          )}
        />
      )}
      {!showOnlyCompanyHeader && editMode && !addingNewPosition && !addMode && (
        <>
          <div className="flex justify-start ml-14">
            <Button
              variant={ButtonVariant.SmallSecondary}
              onClick={() => setAddingNewPosition(true)}
            >
              Add another position
            </Button>
          </div>
        </>
      )}
      {!showOnlyCompanyHeader && editMode && addingNewPosition && addModeFakePosition && (
        <>
          <EditExperienceRow
            onFinishEdit={() => {
              setAddingNewPosition(false);
              if (addMode) {
                onCancelAddMode?.();
              }
            }}
            relationship={addModeFakePosition}
          />
        </>
      )}
      {editMode && section === ProfilePageSection.Investments && (
        <>
          <form
            onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              setSubmitting(true);
              const investment = groupedExperiences[0];
              const form = e.target as HTMLFormElement;
              const formData = new FormData(form);
              const investmentDate = formData.get("investmentDate") as string | null;
              try {
                if (investment && investment?.id) {
                  await entityStore.updateRelationship({
                    ...(groupedExperiences[0] as { id: string }),
                    toId: groupedExperiences[0].to?.id,
                    toName: groupedExperiences[0].to?.name,
                    startedDate: investmentDate,
                  });
                  setEditMode(false);
                } else {
                  if (addMode && addModeFakePosition) {
                    await entityStore.createRelationship({
                      ...addModeFakePosition,

                      // API expects toID/toName on top-level json (not just in "to" object)
                      toId: addModeFakePosition.toId || addModeFakePosition.to?.id,
                      toName: addModeFakePosition.toName || addModeFakePosition.to?.name,
                      startedDate: investmentDate,
                      data: {},
                    });
                    onCancelAddMode?.();
                  }
                }
              } catch (e) {
                setError(prettyError(e));
              } finally {
                setSubmitting(false);
              }
            }}
          >
            <WithLabel label={"Investment date"}>
              <TextField
                name="investmentDate"
                placeholder="YYYY-MM"
                defaultValue={groupedExperiences[0]?.startedDate || ""}
              />
            </WithLabel>
            {error && <div className="text-red-500 mt-2">{error}</div>}
            <SubmitButton className="mt-2">Save</SubmitButton>
          </form>
        </>
      )}
    </div>
  );
}
