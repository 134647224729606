import API from "@/client/api";
import TextField from "@/components/inputs/TextField";
import Loader from "@/components/ui/Loader";
import Spinner from "@/components/ui/Spinner";
import { useCachedAPI, useInvalidateCachedAPI } from "@/hooks/swr";
import errorTracker from "@/lib/errorTracker";
import { prettyError } from "@/lib/miscUtils";
import listStore from "@/stores/listStore";
import { ListEntryDetails, ListOverview } from "@/types";
import { CheckIcon, MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";

export type ListSelectorProps = {
  // Optional entity to check/add/remove from lists
  entity?: {
    id: string;
    name: string;
    sourceSearchId?: string;
  };
  // For bulk selection mode
  onListSelected?: (listId: string) => void;
  // Optional class name for the container
  className?: string;
};

export const ListSelector = ({ entity, onListSelected, className }: ListSelectorProps) => {
  const listOverviews = useStore(listStore.listOverviews);
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [createdListsIds, setCreatedListsIds] = useState<string[]>([]);

  useEffect(() => {
    if (!listOverviews) {
      void listStore.loadOverviews();
    }
  }, [listOverviews]);

  const filteredLists = listOverviews?.filter((list) =>
    list.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleCreateNewList = async (name: string) => {
    setIsCreatingList(true);
    try {
      const newList = await listStore.createList({ name });
      setSearchQuery("");

      if (newList) {
        if (entity) {
          setCreatedListsIds([...createdListsIds, newList.id]);
          await listStore.addEntries(
            [
              {
                entityId: entity.id,
                type: "entity",
                sourceSearchId: entity.sourceSearchId,
                data: { name: entity.name },
              },
            ],
            { listId: newList.id },
          );
        } else if (onListSelected) {
          onListSelected(newList.id);
        }
      }

      await listStore.loadOverviews();
    } catch (e) {
      errorTracker.sendError(e, { listName: name });
      toast.error(prettyError(e));
    }
    setIsCreatingList(false);
  };

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <div className="relative my-2">
        <MagnifyingGlassIcon className="absolute w-5 h-5 text-gray-400 left-2 top-[10px]" />
        <TextField
          placeholder="Search for or create a new list"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="pl-8 w-full"
        />
      </div>
      <div className="flex flex-col divide-y divide-gray-200 min-h-[200px]">
        {searchQuery && (
          <button
            className="flex items-center gap-2 p-2 text-brand-500 font-semibold hover:bg-brand-100 w-full"
            disabled={isCreatingList || !listOverviews}
            onClick={() => handleCreateNewList(searchQuery)}
          >
            <div className="flex items-center justify-center w-10 h-10 rounded bg-brand-100">
              {isCreatingList ?
                <Spinner />
              : <PlusIcon className="w-8 h-8 text-brand-500" />}
            </div>
            New List Named "{searchQuery}"
          </button>
        )}
        {filteredLists?.map((listOverview) => (
          <ListOption
            key={listOverview.id}
            listOverview={listOverview}
            entity={entity}
            onListSelected={onListSelected}
            className="animate-fadeIn"
            isCreatedList={createdListsIds.includes(listOverview.id)}
          />
        ))}
      </div>
    </div>
  );
};

type ListOptionProps = {
  listOverview: ListOverview;
  entity?: {
    id: string;
    name: string;
    sourceSearchId?: string;
  };
  onListSelected?: (listId: string) => void;
  className?: string;
  isCreatedList?: boolean;
};

const ListOption = ({
  listOverview,
  entity,
  onListSelected,
  className,
  isCreatedList,
}: ListOptionProps) => {
  const [hasAddedEntity, setHasAddedEntity] = useState(isCreatedList || false);
  const [isLoading, setIsLoading] = useState(false);
  const [matchingEntries, setMatchingEntries] = useState<ListEntryDetails[]>([]);

  const listId = listOverview.id;
  const entityId = entity?.id;

  const { data: entries } = useCachedAPI(API.listEntries.list, "listEntries.list", () =>
    entityId ?
      ([listId, { entityId, page: 0, pageSize: 1 }] as [
        string,
        { entityId: string; page: number; pageSize: number },
      ])
    : false,
  );

  const invalidateCachedAPI = useInvalidateCachedAPI("listEntries.list", () => [
    listId,
    { entityId, page: 0, pageSize: 1 },
  ]);

  useEffect(() => {
    if (entries && entries.result.length) {
      setMatchingEntries(entries.result);
      setHasAddedEntity(true);
    }
  }, [entries]);

  const handleEntityListOperation = async (operation: "add" | "remove") => {
    if (!entity) return;

    setIsLoading(true);
    try {
      if (operation === "add") {
        const newEntries = await listStore.addEntries(
          [
            {
              entityId: entity.id,
              type: "entity",
              sourceSearchId: entity.sourceSearchId,
              data: { name: entity.name },
            },
          ],
          { listId: listOverview.id },
        );
        if (newEntries?.length) setMatchingEntries(newEntries);
        setHasAddedEntity(true);
      } else {
        await listStore.deleteEntries(
          matchingEntries.map((entry) => entry.id),
          listOverview.id,
        );
        setHasAddedEntity(false);
      }
    } catch (e) {
      errorTracker.sendError(e, { listName: listOverview.name });
      toast.error(prettyError(e));
    } finally {
      setIsLoading(false);
      void invalidateCachedAPI();
    }
  };

  const handleClick = () => {
    if (entity) {
      void handleEntityListOperation(hasAddedEntity ? "remove" : "add");
    } else if (onListSelected) {
      onListSelected(listOverview.id);
    }
  };

  const IconWrapper = ({ children }: { children: React.ReactNode }) => (
    <div className="w-8 h-8 animate-fadeIn duration-100">{children}</div>
  );

  const Icon = useMemo(() => {
    if (isLoading) {
      return (
        <IconWrapper>
          <Loader className="w-8 h-8" />
        </IconWrapper>
      );
    }
    if (entity && hasAddedEntity) {
      return (
        <IconWrapper>
          <CheckIcon className="w-8 h-8 text-green-600" />
        </IconWrapper>
      );
    }
    return (
      <IconWrapper>
        <PlusIcon className="w-8 h-8 text-gray-500" />
      </IconWrapper>
    );
  }, [isLoading, hasAddedEntity, entity]);

  return (
    <button
      onClick={handleClick}
      className={`flex flex-row p-2 items-center hover:bg-brand-100 w-full justify-between ${className}`}
      disabled={isLoading}
    >
      <div className="flex flex-col items-start justify-start content-start">
        <p className="font-semibold text-lg">{listOverview.name}</p>
        <p className="text-sm text-gray-500">
          Updated {formatDistanceToNow(listOverview.updatedAt, { addSuffix: true })}
        </p>
      </div>
      <div>{Icon}</div>
    </button>
  );
};
