import { type Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

import {
  DstCorporateProfile as CorporateProfileSchema,
  DstSourceType,
  DstLocation as LocationSchema,
  DstProfileDataSource as ProfileDataSourceSchema,
  DstProfile as ProfileSchema,
  DstSource as SourceSchema,
} from "./profile.typebox";

export type DstLocation = Static<typeof LocationSchema>;
export type DstProfile = Static<typeof ProfileSchema>;
export { DstSourceType };
export type DstSource = Static<typeof SourceSchema>;
export type DstProfileDataSource = Static<typeof ProfileDataSourceSchema>;
export type DstCorporateProfile = Static<typeof CorporateProfileSchema>;

export function isDstLocation(u: unknown): u is DstLocation {
  return Value.Check(LocationSchema, u);
}

export function isDstProfile(u: unknown): u is DstProfile {
  return Value.Check(ProfileSchema, u);
}

export function isDstProfileDataSource(u: unknown): u is DstProfileDataSource {
  return Value.Check(ProfileDataSourceSchema, u);
}
