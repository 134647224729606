import ConnectionPathsUnit from "@/components/discover/ConnectionPathsUnit";
import ConnectionScoreBar from "@/components/discover/ConnectionScoreBar";
import ConnectionScoreExplainer from "@/components/discover/ConnectionScoreExplainer";
import { useDevTools } from "@/hooks/useUIStore";
import { useEntityStore } from "@/stores/entityStore";
import { graphStore } from "@/stores/graphStore";
import { useStore } from "@nanostores/react";
import { twMerge } from "tailwind-merge";

export default function EntityConnectionScore({ className }: { className?: string }) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const connectionScore = useStore(graphStore.connectionScores)[entity.id];
  if (!connectionScore) return null;
  if (!connectionScore.bestPaths || connectionScore.bestPaths.length === 0) return null;

  return <ConnectionPathsUnit className={className} score={connectionScore} />;
}
