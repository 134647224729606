/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/layout/ProfileBubble";
import Button, { ButtonLink, ButtonVariant } from "@/components/ui/Button";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import Loader from "@/components/ui/Loader";
import ProgressSteps from "@/components/ui/ProgressSteps";
import { useDevTools } from "@/hooks/useUIStore";
import { useEntityStore } from "@/stores/entityStore";
import { PipelineProgress, PipelineRunStatus } from "@/types";
import { PauseIcon, PlayCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import moment from "moment";

export default withErrorBoundary(function ShowPipelineStatus() {
  const entityStore = useEntityStore();
  const status = useStore(entityStore.initialStatus);
  const entity = useStore(entityStore.entity);
  const progress = useStore(entityStore.progress);
  const isDev = useDevTools();

  if (entity.generatedAt && moment(entity.generatedAt).isAfter(moment().subtract(3, "months"))) {
    return null;
  }

  if (status == PipelineRunStatus.COMPLETED || status == PipelineRunStatus.ABORTED) {
    // only show progress for completed entities if in dev mode
    if (!isDev || !progress) return null;
  }

  // don't show progress for other statuses outside of dev mode
  if (
    !isDev &&
    status != PipelineRunStatus.COMPLETED &&
    status != PipelineRunStatus.IN_PROGRESS &&
    status != PipelineRunStatus.ERROR
  )
    return null;

  return (
    <ProfileBubble className="bg-brand-50 flex flex-col gap-2 rounded-lg">
      <div className="flex items-center gap-2">
        <PipelineLabel status={status} progress={progress} />
      </div>
      {progress && (
        <div className="flex items-center">
          <ProgressSteps
            stepsDone={progress.step}
            stepsLeft={progress.stepsLeft}
            currentStepEstimatedSecs={progress.estimatedSecs}
            barClassName="h-2.5"
          />
          <div className="w-10 text-end ml-1">
            <span className="text-sm text-gray-800">
              {Math.round((100 * progress.step) / (progress.step + progress.stepsLeft))}%
            </span>
          </div>
        </div>
      )}
    </ProfileBubble>
  );
});

const TIPS = [
  "You can keep searching and find the completed profile in the “Recents” tab at the top of the page",
  "It takes 1-2 minutes to create or update a profile, depending on how much info we find.",
  "You can leave this page and we will continue to distill this profile for you",
];

function PipelineLabel({ status, progress }: { status: string; progress?: PipelineProgress }) {
  const entityStore = useEntityStore();
  const tipIndex = Math.floor(Math.random() * 3);
  if (progress) status = PipelineRunStatus.IN_PROGRESS;
  switch (status) {
    case PipelineRunStatus.IN_PROGRESS:
      return (
        <div className="flex flex-col mb-2">
          <div className="font-medium text-lg text-brand-600">
            {progress?.label || "Please be patient as we fetch data from across the web..."}
          </div>
          <div className="text-brand-600">Pro Tip: {TIPS[tipIndex]}</div>
        </div>
      );
    case PipelineRunStatus.ERROR:
      return (
        <div className="flex flex-col items-center w-full p-4 gap-2">
          <div className="font-semibold text-xl">
            We&apos;ve run into an issue creating this profile
          </div>
          <div className="text-gray-600 mb-4">Reach out to our support team if this continues</div>
          <ButtonLink
            href="mailto:support@distill.fyi"
            className="font-medium"
            variant={ButtonVariant.Secondary}
          >
            Contact support
          </ButtonLink>
        </div>
      );
    case PipelineRunStatus.PAUSED:
      return (
        <>
          <PauseIcon className="w-4 h-4 text-yellow-500" />
          Pipeline is paused.
        </>
      );
    case PipelineRunStatus.NOT_STARTED:
      return (
        <>
          <PlayCircleIcon className="w-4 h-4 text-green-500" />
          Click to start loading data:
          <Button onClick={() => entityStore.triggerPipeline()}>Start Pipeline</Button>
        </>
      );
    default:
      return (
        <>
          <QuestionMarkCircleIcon className="w-6 h-6 text-blue-500" />
          Unusual pipeline status: {status}
        </>
      );
  }
}
