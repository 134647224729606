import { Tab, TabGroup, TabList, TabPanel, TabPanels, Textarea } from "@headlessui/react";
import { useStore } from "@nanostores/react";
import { twMerge } from "tailwind-merge";

import AssessmentQuestions from "@/components/discover/AssessmentQuestions";
import { assessmentStores } from "@/stores/assessmentStore";
import { EntityType } from "@/types";
import { useState } from "react";
import { toast } from "react-toastify";

export interface AssessmentContextProps {
  listId: string;
  onClose: () => void;
  selectedContext: EntityType;
  setSelectedContext: (context: EntityType) => void;
}

export default function AssessmentContext({
  listId,
  onClose,
  selectedContext,
  setSelectedContext,
}: AssessmentContextProps) {
  const personStore = assessmentStores[`person-${listId}`];
  const companyStore = assessmentStores[`company-${listId}`];
  const personDescriptions = useStore(personStore.descriptions);
  const companyDescriptions = useStore(companyStore.descriptions);
  const personQuestions = useStore(personStore.questions);
  const companyQuestions = useStore(companyStore.questions);
  const [personDescriptionSimilarity, setPersonDescriptionSimilarity] = useState(
    personDescriptions.description,
  );
  const [companyDescriptionSimilarity, setCompanyDescriptionSimilarity] = useState(
    companyDescriptions.description,
  );
  const [personInvestmentSimilarity, setPersonInvestmentSimilarity] = useState(
    personDescriptions.keywords,
  );
  const [companyInvestmentSimilarity, setCompanyInvestmentSimilarity] = useState(
    companyDescriptions.keywords,
  );

  const handleSave = async () => {
    try {
      await Promise.all([
        personStore.updateDescriptions({
          description: personDescriptionSimilarity,
          keywords: personInvestmentSimilarity,
        }),
        companyStore.updateDescriptions({
          description: companyDescriptionSimilarity,
          keywords: companyInvestmentSimilarity,
        }),
      ]);
      onClose();
    } catch (error) {
      toast.error("Failed to save assessment context");
    }
  };

  return (
    <div className="p-2 flex flex-col gap-4 w-full mb-20">
      <TabGroup onChange={(index) => setSelectedContext(index === 0 ? "person" : "company")}>
        <TabList className="flex space-x-8 border-b border-gray-200">
          <Tab
            className={({ selected }) =>
              twMerge(
                "py-4 text-sm font-medium relative",
                "focus:outline-none",
                selected && "text-brand-600",
                selected &&
                  "after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-brand-600",
              )
            }
          >
            Person
          </Tab>
          <Tab
            className={({ selected }) =>
              twMerge(
                "py-4 text-sm font-medium relative",
                "focus:outline-none",
                selected && "text-brand-600",
                selected &&
                  "after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-brand-600",
              )
            }
          >
            Companies
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="flex flex-col gap-4 mt-4">
              <p className="text-sm">Context about me or my company</p>
              <Textarea
                className="w-full h-32 p-2 rounded-md bg-gray-100 border-transparent focus:border-brand-600 focus:ring-brand-600"
                value={personDescriptionSimilarity}
                onChange={(e) => setPersonDescriptionSimilarity(e.target.value)}
                placeholder="Innovative AI-powered platform revolutionizing personalized marketing..."
              />
              <p className="text-sm">Context about what I&apos;m looking for</p>
              <Textarea
                className="w-full h-32 p-2 rounded-md bg-gray-100 border-transparent focus:border-brand-600 focus:ring-brand-600"
                value={personInvestmentSimilarity}
                onChange={(e) => setPersonInvestmentSimilarity(e.target.value)}
                placeholder="Visionary, tech-savvy, value-driven, hands-on..."
              />
              <AssessmentQuestions
                questions={personQuestions}
                removeQuestion={personStore.removeQuestion}
                addQuestion={personStore.addQuestion}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="flex flex-col gap-4 mt-4">
              <p className="text-sm">Context about me or my company</p>
              <Textarea
                className="w-full h-32 p-2 rounded-md bg-gray-100 border-transparent focus:border-brand-600 focus:ring-brand-600"
                value={companyDescriptionSimilarity}
                onChange={(e) => setCompanyDescriptionSimilarity(e.target.value)}
                placeholder="Innovative AI-powered platform revolutionizing personalized marketing..."
              />
              <p className="text-sm">Context about what I&apos;m looking for</p>
              <Textarea
                className="w-full h-32 p-2 rounded-md bg-gray-100 border-transparent focus:border-brand-600 focus:ring-brand-600"
                value={companyInvestmentSimilarity}
                onChange={(e) => setCompanyInvestmentSimilarity(e.target.value)}
                placeholder="Visionary, tech-savvy, value-driven, hands-on..."
              />
              <AssessmentQuestions
                questions={companyQuestions}
                removeQuestion={companyStore.removeQuestion}
                addQuestion={companyStore.addQuestion}
              />
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
      <div className="fixed bg-white shadow-card bottom-0 right-0 w-full p-3">
        <div className="flex justify-end gap-2">
          <button
            className="px-4 py-2 text-gray-600 hover:text-gray-700 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-brand-600 text-white hover:bg-brand-700 rounded-md"
            onClick={handleSave}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}
