import EntityCard, {
  EntityForCard,
  makeEntityCardFromMutualConnection,
} from "@/components/cards/EntityCard";
import Spinner from "@/components/ui/Spinner";
import { MutualConnectionsData, useEntityStore } from "@/stores/entityStore";
import { ConnectionAnalysis } from "@/types/mutualConnections";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { Tooltip } from "react-tooltip";

export default function ConnectionCard({
  connection,
}: {
  connection: MutualConnectionsData;
}): React.JSX.Element | null {
  const entityForCard = makeEntityCardFromMutualConnection(connection);
  const posthog = usePostHog();

  if (entityForCard.name && entityForCard.url) {
    const overallHeight = connection.resolved ? 100 : 80;
    return (
      <>
        <div className={`h-[${overallHeight}px] flex flex-col justify-center`}>
          <EntityCard
            entity={entityForCard}
            contextMenu={false}
            border={false}
            entityIconClassName="h-[60px] w-[60px] rounded"
            entityDetailsClassName="mt-0"
            onClick={() => {
              posthog?.capture("show-mutual-connection", { url: entityForCard.url });
            }}
          />
          {connection.resolved && (
            <AnalyzeButton
              height={20}
              entityForCard={entityForCard}
              analysis={connection.analysis}
            />
          )}
        </div>
      </>
    );
  } else {
    return null;
  }
}

function AnalyzeButton({
  height,
  entityForCard,
  analysis,
}: {
  height: number;
  entityForCard: EntityForCard;
  analysis: ConnectionAnalysis | null;
}) {
  const entityStore = useEntityStore();
  const [analyzing, setAnalyzing] = useState(false);

  const entity = entityStore.entity.get();
  const tooltipId = `analysis-tooltip-${entityForCard.id}`;
  return (
    <div
      className={`h-[${height}px] flex flex-row items-center justify-between cursor-pointer text-xs text-gray-400 hover:text-gray-500 px-4`}
      onClick={async () => {
        if (analysis) return;
        setAnalyzing(true);
        await entityStore.analyzeMutualConnection(entityForCard.url);
        setAnalyzing(false);
      }}
      data-tooltip-id={tooltipId}
      data-tooltip-place="bottom"
      data-tooltip-position-strategy={"fixed"}
    >
      <p>Analyze: how they know each other</p>
      {analyzing ?
        <Spinner size={12} />
      : <ChevronRightIcon className="w-3 h-3 block" />}
      <Tooltip className="z-[9999] flex flex-col max-w-xs break-words" id={tooltipId}>
        {(analysis || analyzing) && (
          <div className="flex flex-col">
            {analysis ?
              <>
                <h3 className="font-semibold">
                  How {entity.name} and {entityForCard.name} know each other
                </h3>
                <ul className="list-disc list-inside">
                  {analysis.strength ?
                    <>
                      <li>{analysis.summary}</li>
                      <li>{connectionStrength(analysis)} Connection</li>
                    </>
                  : <li>Unknown</li>}
                </ul>
              </>
            : <p>Analyzing...</p>}
          </div>
        )}
      </Tooltip>
    </div>
  );
}

function connectionStrength(analysis: ConnectionAnalysis): string {
  if (analysis.strength > 4) {
    return "Very Strong";
  } else if (analysis.strength > 2) {
    return "Strong";
  } else if (analysis.strength > 0) {
    return "Weak";
  } else {
    return "No";
  }
}
