import { PropsWithChildren, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";

export default function ProfileBubble({
  title,
  section,
  className,
  titleButtons,
  children,
}: PropsWithChildren<{
  title?: string;
  section?: ProfilePageSection;
  className?: string;
  titleButtons?: React.ReactElement;
}>) {
  const divRef = useRef<HTMLDivElement>(null);
  const entityStore = useEntityStore();
  useEffect(() => {
    const currentDiv = divRef.current;
    const parentContainer = document.querySelector("#container");
    if (!parentContainer || !section || !currentDiv) return;

    const onScroll = () => {
      const top = currentDiv.getBoundingClientRect().top;
      if (top > -50 && top < 200) {
        entityStore.currentSection.set(section);
      }
    };
    onScroll();
    parentContainer.addEventListener("scroll", onScroll);
    return () => {
      parentContainer.removeEventListener("scroll", onScroll);
    };
  }, [section]);

  return (
    <div
      ref={divRef}
      className={twMerge(
        `bg-white sm:rounded-md border-y sm:border border-gray-200 p-4 text-sm mb-4 text-gray-800 relative flex-1`,
        className,
      )}
      id={section}
    >
      {section && <a id={section} className="absolute -top-[18px]" />}
      {(title || titleButtons) && (
        <div className="flex mb-4" id="section-header">
          {title && <div className="text-gray-400 text-base">{title}</div>}
          <div className="ml-2 flex items-center justify-center">{titleButtons}</div>
        </div>
      )}
      {children}
    </div>
  );
}
