export function isFreeDomain(domain: string) {
  return freemailDomains.includes(domain.toLowerCase());
}

const freemailDomains = `163.com
icloud.com
mac.com
me.com
123mail.org
150mail.com
150ml.com
16mail.com
2-mail.com
4email.net
50mail.com
airpost.net
allmail.net
bestmail.us
cluemail.com
elitemail.org
emailcorner.net
emailengine.net
emailengine.org
emailgroups.net
emailplus.org
emailuser.net
eml.cc
f-m.fm
fast-email.com
fast-mail.org
fastem.com
fastemail.us
fastemailer.com
fastest.cc
fastimap.com
fastmail.cn
fastmail.co.uk
fastmail.com
fastmail.com.au
fastmail.de
fastmail.es
fastmail.fm
fastmail.im
fastmail.in
fastmail.jp
fastmail.mx
fastmail.net
fastmail.nl
fastmail.org
fastmail.se
fastmail.to
fastmail.tw
fastmail.us
fastmailbox.net
fastmessaging.com
fea.st
fmail.co.uk
fmailbox.com
fmgirl.com
fmguy.com
ftml.net
h-mail.us
hailmail.net
hey.com
imap-mail.com
imap.cc
imapmail.org
inoutbox.com
internet-e-mail.com
internet-mail.org
internetemails.net
internetmailing.net
jetemail.net
justemail.net
letterboxes.org
mail-central.com
mail-page.com
mailandftp.com
mailas.com
mailbolt.com
mailc.net
mailcan.com
mailforce.net
mailftp.com
mailhaven.com
mailhouse.work
mailhost.biz
mailingaddress.org
mailite.com
mailmight.com
mailnew.com
mailsent.net
mailservice.ms
mailup.net
mailworks.org
messagebox.email
ml1.net
mm.st
myfastmail.com
mymacmail.com
nospammail.net
offshore.rocks
ownmail.net
petml.com
postinbox.com
postpro.net
proinbox.com
promessage.com
rbox.co
rbox.me
rbx.email
rbx.life
rbx.run
realemail.net
reallyfast.biz
reallyfast.info
rnbx.uk
runbox.at
runbox.biz
runbox.bz
runbox.ch
runbox.co
runbox.co.in
runbox.com
runbox.dk
runbox.email
runbox.eu
runbox.is
runbox.it
runbox.ky
runbox.li
runbox.me
runbox.nl
runbox.nospammail
runbox.us
rushpost.com
sent.as
sent.at
sent.com
speedpost.net
speedymail.org
ssl-mail.com
swift-mail.com
the-fastest.net
the-quickest.com
theinternetemail.com
veryfast.biz
veryspeedy.net
warpmail.net
xobnur.uk
xsmail.com
yepmail.net
your-mail.com
2trom.com
about.me
accountant.com
acdcfan.com
activist.com
adexec.com
africamail.com
aircraftmail.com
allergist.com
alumni.com
alumnidirector.com
angelic.com
aol.com
appraiser.com
archaeoloist.com
arcticmail.com
artlover.com
asia.com
asial-mail.com
atheist.com
auctioneer.com
australiamail.com
bartender.com
bellair.com
berlin.com
bikerider.com
birdlover.com
blader.com
boardermail.com
brazilmail.com
brew-master.com
brew-meister.com
bsdmail.com
californiamail.com
cash4u.com
catlover.com
cheerful.com
chef.net
chemist.com
chinamail.com
clerk.com
clubmember.org
collector.org
columnist.com
comic.com
computer4u.com
consultant.com
contractor.net
coolsite.net
counsellor.com
cutey.com
cyberdevices.com
cyberdude.com
cybergal.com
cyberservices.com
cyber-wizard.com
dallasmail.com
dbzmail.com
deliveryman.com
diplomats.com
disciples.com
discofan.com
disposable.com
doctor.com
doglover.com
doramail.com
dr.com
dublin.com
dutchmail.com
elvisfan.com
email.com
engineer.com
englandmail.com
europe.com
europemail.com
execs.com
fastservice.com
financier.com
fireman.net
free.fr
galaxyhit.com
garderner.com
geologist.com
germanymail.com
gmail.com
graduate.org
graphic-designer.com
greenmail.net
groupmail.com
hackermail.com
hairdresser.net
hilarious.com
hiphopfan.com
homemail.com
hot-shot.com
hotmail.be
hotmail.ca
hotmail.cl
hotmail.co.id
hotmail.co.il
hotmail.co.in
hotmail.co.jp
hotmail.co.kr
hotmail.co.th
hotmail.co.uk
hotmail.co.za
hotmail.com
hotmail.com.br
hotmail.com.hk
hotmail.com.tr
hotmail.com.tw
hotmail.com.vn
hotmail.cz
hotmail.de
hotmail.dk
hotmail.es
hotmail.fr
hotmail.gr
hotmail.hu
hotmail.it
hotmail.lv
hotmail.my
hotmail.nl
hotmail.no
hotmail.ph
hotmail.rs
hotmail.se
hotmail.sg
hotmail.sk
housemail.com
humanoid.net
hushmail.com
iname.com
innocent.com
inorbit.com
instruction.com
instructor.net
insurer.com
intercom.com
intercom.eu
intercom-mail.com
irelandmail.com
israelmail.com
italymail.com
job4.com
journalist.com
keemail.me
keromail.com
kissfans.com
kittymail.com
koreamail.com
legislator.com
linuxmail.org
live.at
live.be
live.ca
live.cl
live.cn
live.co.kr
live.co.uk
live.co.za
live.com
live.com.ar
live.com.au
live.com.my
live.com.mx
live.com.ph
live.com.pt
live.com.sg
live.de
live.dk
live.fi
live.fr
live.hk
live.ie
live.in
live.it
live.jp
live.nl
live.no
live.ru
live.se
livemail.tw
lobbyist.com
lovecat.com
mail.com
mail-me.com
madonnafan.com
marchmail.com
metalfan.com
minister.com
moscowmail.com
munich.com
musician.org
muslim.com
myself.com
net-shopping.com
ninfan.com
nonpartisan.com
null.net
nycmail.com
oath.com
optician.com
orthodonstist.net
outlook.at
outlook.be
outlook.br
outlook.cl
outlook.co.id
outlook.co.il
outlook.co.nz
outlook.co.th
outlook.com
outlook.com.ar
outlook.com.au
outlook.com.br
outlook.com.gr
outlook.com.hk
outlook.com.pe
outlook.com.th
outlook.com.tr
outlook.com.vn
outlook.cz
outlook.de
outlook.dk
outlook.es
outlook.fi
outlook.fr
outlook.gr
outlook.hu
outlook.ie
outlook.in
outlook.it
outlook.jp
outlook.kr
outlook.lv
outlook.my
outlook.ph
outlook.pt
outlook.sa
outlook.sg
outlook.sk
outlook.cz
pasific-ocean.com
pacificwest.com
pediatrician.com
petlover.com
photographer.net
physicist.net
planetmail.com
planetmail.net
politician.com
pollandmail.com
post.com
presidency.com
programmer.net
protonmail.ch
protonmail.com
priest.com
protestant.com
publicist.com
qualityservice.com
radiologist.net
ravemail.com
realtyagent.com
reborn.com
reggaefan.com
registerednurses.com
reincarnate.com
religious.com
repairman.com
representative.com
rescueteam.com
rocketmail.com
rocketship.com
safrica.com
saintly.com
salesperson.net
samerica.com
sanfranmail.com
scotlandmail.com
secretary.net
snakebite.com
socialworker.net
sociologist.com
solition4u.com
songwriter.net
spainmail.com
substack.com
swedenmail.com
swissmail.com
teacher.org
tech-center.com
techie.com
technologist.com
theplate.com
therapist.com
toke.com
toothfairy.com
torontomail.com
tvstar.com
tuta.io
tutamail.com
tutanota.com
tutanota.de
usa.com
iumpire.com
uymail.com
webname.com
windowslive.com
worker.com
workmail.com
writeme.com
yahoo.co.id
yahoo.co.in
yahoo.co.jp
yahoo.co.kr
yahoo.co.ph
yahoo.co.sg
yahoo.com
yahoo.fr
yandex.ru
ymail.com
zoho.com
abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijk.com
aussiemail.com.au
dcemail.com
inbox.com
mail2world.com
openmailbox.org
opmbx.org
rediffmail.com
riseup.net
safe-email.net
teknik.io
vfemail.net
zillamail.com
zmail.ru`.split("\n");
