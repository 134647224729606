import { DstInvestmentStage, DstPortfolioInvestment, DstVCFund } from "@/dst/venture";
import { loggerWithPrefix } from "@/lib/logger";
import similarityUtils, { SimilarityThreshold } from "@/utils/similarityUtils";
import { isBefore } from "date-fns/isBefore";
import _ from "lodash";
import { CoreExtractors } from "./core";

const logger = loggerWithPrefix("[VentureExtractorFn]");

export const VentureExtractors = {
  InvestmentStage: findInvestmentStage,
  LatestFund: (value: unknown): DstVCFund | undefined => {
    const funds = CoreExtractors.Array<DstVCFund>(value);
    return funds?.sort((a, b) => (b.year ?? 0) - (a.year ?? 0))[0];
  },
  LeadInvestmentsRollup: (value: unknown) => filteredInvestmentsRollup(value, { leadOnly: true }),
  RecentInvestments: filteredInvestments,
};

const additionalPreSeedValues = ["Angel", "Convertible Note"];

const stageValues = [...Object.values(DstInvestmentStage), ...additionalPreSeedValues];

function findInvestmentStage(value: unknown): DstInvestmentStage | undefined {
  if (!value || typeof value !== "string") {
    return undefined;
  }
  const rawStage = value
    .split(/(?<!pre)-/i) // split on hyphens not preceded by "pre"
    [0].replace("Round", "")
    .trim();
  if (!rawStage) {
    return undefined;
  }

  // handle common cases before doing fuzzy matching
  const seriesMatch = rawStage.match(/^series[-\s]*([a-z])(.)?/i);
  if (seriesMatch) {
    const stage = seriesMatch[1].toUpperCase();
    const afterStage = seriesMatch[2]; // The character after the stage or undefined
    if (afterStage !== "+" && ["A", "B", "C"].includes(stage)) {
      return `Series ${stage}` as DstInvestmentStage;
    }
    return DstInvestmentStage.SeriesDPlus;
  }

  const seedMatch = rawStage.match(/^(pre[-\s]*)?seed/i);
  if (seedMatch) {
    // seedMatch[1] will be undefined for seed
    return seedMatch[1] ? DstInvestmentStage.PreSeed : DstInvestmentStage.Seed;
  }

  const bestMatch = similarityUtils.findBest({
    source: rawStage,
    targets: stageValues,
    threshold: SimilarityThreshold.LOOSE,
  });
  const target = bestMatch?.target;
  if (!target) {
    logger.debug(`No investment stage match found for ${rawStage}`);
    return DstInvestmentStage.Other;
  }
  if (additionalPreSeedValues.includes(target)) {
    return DstInvestmentStage.PreSeed;
  }
  logger.debug(`Best match for ${rawStage}: ${target}`);
  return target as DstInvestmentStage;
}

type InvestmentsFilter = {
  leadOnly?: boolean;
  yearsThreshold?: number;
};

function filteredInvestments(
  value: unknown,
  options?: InvestmentsFilter,
): DstPortfolioInvestment[] | undefined {
  const investments = CoreExtractors.Array<DstPortfolioInvestment>(value);
  if (!investments) {
    return undefined;
  }
  const { leadOnly = false, yearsThreshold = 2 } = options ?? {};

  const currentYear = new Date().getFullYear();
  const cutoff = currentYear - yearsThreshold;
  const filtered = investments.filter((investment) => {
    if (leadOnly && !investment.lead) {
      return false;
    }
    if (!investment.year) {
      return false;
    }
    const year = new Date(investment.year, 0);
    if (isBefore(year, cutoff)) {
      return false;
    }
    return true;
  });
  return _.orderBy(filtered, ["year"], ["desc"]);
}

function filteredInvestmentsRollup(
  value: unknown,
  options?: InvestmentsFilter,
): Partial<Record<DstInvestmentStage, number>> | undefined {
  const investments = filteredInvestments(value, options);
  if (!investments) {
    return undefined;
  }
  const rollup: Partial<Record<DstInvestmentStage, number>> = {};
  for (const investment of investments) {
    if (!investment.stage) {
      continue;
    }
    rollup[investment.stage] = (rollup[investment.stage] ?? 0) + 1;
  }
  return rollup;
}
