import Link, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, HTMLAttributes, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

type ConditionalLinkProps = PropsWithChildren<
  AnchorHTMLAttributes<HTMLAnchorElement> &
    Omit<LinkProps, "href"> & {
      href?: string;
      prefetch?: undefined;
    }
>;

export default function ConditionalLink({
  children,
  href,
  className,
  ...rest
}: ConditionalLinkProps) {
  if (!href) {
    return (
      <div className={twMerge("inline", className)} {...(rest as HTMLAttributes<HTMLDivElement>)}>
        {children}
      </div>
    );
  }

  return (
    <Link href={href} className={className} {...rest}>
      {children}
    </Link>
  );
}
