import SocialServiceIcon, { getSocialServiceIcon } from "@/components/icons/SocialServiceIcon";
import ConditionalLink from "@/components/ui/ConditionalLink";
import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection, Social } from "@/types";
import { SocialService } from "@/utils/socialUtils";
import { useStore } from "@nanostores/react";
import { capitalize } from "lodash";
import React from "react";

// Type for social object from the store

const SocialButtons = () => {
  const entityStore = useEntityStore();
  const socials = useStore(entityStore.socials) as Social[];

  // Prioritize these social services when displaying icons
  const socialsToPrioritize = [
    SocialService.LinkedIn,
    SocialService.Twitter,
    SocialService.GitHub,
    SocialService.Facebook,
    SocialService.Instagram,
    SocialService.YouTube,
    SocialService.Medium,
    SocialService.TikTok,
    SocialService.Crunchbase,
  ];

  // Filter socials to only include those with valid icons
  // SocialServiceIcon returns null for services without icon implementations
  const socialsWithIcons = socials.filter((social) => {
    const service = social.service as SocialService;
    // Check if this service would return an icon (not null)
    const hasIcon = getSocialServiceIcon(service) !== null;
    return hasIcon;
  });

  // Get prioritized socials that are available and have icons
  const prioritizedSocials = socialsToPrioritize
    .map((service) =>
      socialsWithIcons.find((social) => (social.service as SocialService) === service),
    )
    .filter(Boolean) as Social[];

  // Get remaining socials that are not in the prioritized list but have icons
  const remainingSocials = socialsWithIcons.filter(
    (social) => !prioritizedSocials.some((ps) => ps.service === social.service),
  );

  // Combine prioritized socials with remaining socials that have icons
  const displayableSocials = [...prioritizedSocials, ...remainingSocials];

  // Take the first 4 displayable socials
  const socialsToDisplay = displayableSocials.slice(0, 4);

  // Calculate how many socials are hidden (all socials minus displayed ones)
  const hiddenSocialsCount = socials.length - socialsToDisplay.length;

  const Icons = [
    ...socialsToDisplay.map((social) => (
      <ConditionalLink
        key={social.service}
        href={social.url}
        className="rounded-sm text-gray-600 hover:text-gray-800"
        target="_blank"
        rel="noopener noreferrer"
        data-tooltip-id="tooltip"
        data-tooltip-content={capitalize(social.service)}
      >
        <SocialServiceIcon service={social.service as SocialService} className="h-5 w-5" />
      </ConditionalLink>
    )),
    hiddenSocialsCount > 0 && (
      <a
        href={`#${ProfilePageSection.SocialMedia}`}
        key="hiddenSocialsCount"
        className="flex flex-row text-white bg-gray-600 rounded-sm h-5 w-5 items-center justify-center hover:bg-gray-800"
      >
        <p className="text-md whitespace-nowrap">+{hiddenSocialsCount}</p>
      </a>
    ),
  ].filter(Boolean);

  return <div className="flex flex-row gap-3">{socials.length > 0 && Icons}</div>;
};

export default SocialButtons;
