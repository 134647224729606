import { PropsWithChildren, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import InProgress from "@/components/messages/InProgress";
import Message, {
  GroupableMessageProps,
  GroupedMessage,
  isGroupedMessage,
  MessageUnit,
} from "@/components/messages/Message";
import { messageStore, MessageStoreType } from "@/stores/messageStore";
import { ChatRole } from "@/types";
import { useStore } from "@nanostores/react";

export function Messages({
  className,
  hasActions,
  children,
  variant = "default",
}: PropsWithChildren<{
  className?: string;
  hasActions?: boolean;
  variant?: "default" | "entity";
}>) {
  const inProgress = useStore(messageStore.inProgress);
  const messages = useStore(messageStore.messages);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current) return;
    if (!hasActions) return; // if no actions, we assume it is not your conversation -> no auto scroll
    if (divRef.current.scrollTop == 0) {
      // if you are at the bottom, scroll to the end
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [messages.length, hasActions, inProgress]);

  // group function call type messages into a separate component
  const groupedMessages = messages.reduce<MessageUnit[]>((acc, curr) => {
    if (curr.attachments?.tool_calls) {
      const last = acc[acc.length - 1];
      if (isGroupedMessage(last)) {
        last.messages.push(curr);
      } else {
        acc.push({
          id: curr.id,
          role: curr.role,
          content: curr.content,
          messages: [curr],
        } as GroupedMessage);
      }
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  const messageStyle = variant == "entity" ? "entity" : "bubble";

  return (
    <div ref={divRef} className={twMerge("flex flex-col-reverse mb-4", className)}>
      <InProgress />
      {groupedMessages.map((message, i) => {
        const props: GroupableMessageProps = {
          message: message,
          pos: { i, len: messages.length },
          hasActions,
          style: messageStyle,
          showAuthor:
            message.role != messages[i + 1]?.role || (message.role == "user" && !!message.content),
        };

        return <Message key={message.id || i} {...props} style={messageStyle} />;
      })}
      {children}
    </div>
  );
}
