import moment from "moment";
import { ReactNode } from "react";

import EditableFact from "@/components/entities/EditableFact";
import DelimitedList from "@/components/ui/DelimitedList";
import { abbreviateLocation } from "@/lib/abbreviations";
import { prettyUrl } from "@/lib/utils";
import { useEntityStore } from "@/stores/entityStore";
import { PersonFact, PersonProfile } from "@/types";
import { useStore } from "@nanostores/react";

type Props = {
  snapshot: PersonProfile;
  skipLastUpdated?: boolean;
};

export default function PersonSubtitle(props: Props) {
  const entityStore = useEntityStore();
  const facts = useStore(entityStore.facts);
  const canEditAttrs = useStore(entityStore.canEdit);

  const { snapshot, skipLastUpdated } = props;

  const subtitles: (ReactNode | string)[] = [];

  const title = snapshot?.title;

  if (title) {
    subtitles.push(title);
  }

  const location = abbreviateLocation(facts[PersonFact.Location]?.value);

  if (location) {
    subtitles.push(
      <EditableFact
        factType={PersonFact.Location}
        canEdit={canEditAttrs}
        onEdit={() =>
          entityStore.showFactEditModal.set({
            type: PersonFact.Location,
            currentValue: location,
          })
        }
      >
        {location}
      </EditableFact>,
    );
  }

  if (snapshot.updatedAt && !skipLastUpdated)
    subtitles.push("Last Updated: " + moment(snapshot.updatedAt).fromNow());

  if (
    snapshot.url &&
    !snapshot.url.includes("linkedin.com/") &&
    !snapshot.url.includes("distill://")
  ) {
    subtitles.push(
      <a href={snapshot.url} className="text-blue-500 hover:text-blue-700">
        {prettyUrl(snapshot.url)}
      </a>,
    );
  }

  return (
    <div className="text-13pt md:text-sm inline-flex flex-wrap overflow-hidden max-w-full">
      <DelimitedList
        list={subtitles.filter(Boolean)}
        spacing="mx-1"
        itemClass="whitespace-nowrap overflow-hidden text-ellipsis truncate"
      />
    </div>
  );
}
