import { ButtonHTMLAttributes, PropsWithChildren, useState } from "react";
import { twMerge } from "tailwind-merge";

import { classNames } from "@/lib/utils";
import { messageStore } from "@/stores/messageStore";
import { ChatRole } from "@/types";
import { ClipboardDocumentIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { MessageProps } from "./Message";

let deleteConfirm = false;

export function MessageActions({ message }: MessageProps) {
  const [copied, setCopied] = useState(false);
  if (!message) return <div className="w-8" />;

  if (!message.content && !message.attachments) return null;

  const userMessage = message.role == ChatRole.User;

  const deleteMessage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (deleteConfirm && !confirm("Delete this message? This will affect future AI responess."))
      return;
    deleteConfirm = false;
    void messageStore.deleteMessage(message);
  };

  const copy = () => {
    setCopied(true);
    void navigator.clipboard.writeText(message.content || "");
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div
      className={classNames(
        "flex bg-white -mt-3 rounded-md shadow-md absolute gap-1 right-0 top-0 z-20 invisible group-hover:visible",
      )}
    >
      {userMessage && message.content && (
        <Button
          data-tooltip-content={"Edit"}
          onClick={() => {
            messageStore.requestEdit(message);
          }}
        >
          <PencilIcon className="h-4 w-4 " />
        </Button>
      )}
      {message.content && (
        <Button data-tooltip-content={copied ? "Copied!" : "Copy"} onClick={copy}>
          <ClipboardDocumentIcon className="h-4 w-4 " />
        </Button>
      )}
      <Button data-tooltip-content="Delete" onClick={deleteMessage}>
        <TrashIcon className="h-4 w-4" />
      </Button>
    </div>
  );
}

function Button({ children, ...rest }: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>) {
  return (
    <button
      {...rest}
      data-tooltip-id="tooltip"
      data-tooltip-place="top"
      className={twMerge(
        "p-1 rounded cursor-pointer text-gray-800 hover:text-blue-500 hover:bg-blue-100",
        rest.className,
      )}
    >
      {children}
    </button>
  );
}
