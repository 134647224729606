import { IconProps } from "@/components/icons/types";

export default function TwitterIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_7357_18245)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM16.2069 4H14.061L10.4828 8.15424L7.42719 4H3L8.27158 11.0996L3.27991 17H5.42586L9.2979 12.4524L12.6801 17H17L11.4952 9.51661L16.2069 4ZM14.4995 15.7048H13.3099L5.52383 5.24723H6.80207L14.4995 15.7048Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_7357_18245">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
