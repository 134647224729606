import { IconProps } from "@/components/icons/types";

export default function QuoraIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_47_367)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM3 9.02466C3 5.14403 6.16594 2.5 9.38655 2.5C12.6747 2.5 15.7547 5.12656 15.7517 9.02344C15.7517 11.1959 14.8103 12.9647 13.3823 14.0991C13.8413 14.8154 14.3191 15.2856 14.9813 15.2856C15.7081 15.2856 16.005 14.8225 16.0537 14.375H17C17 15 16.7606 17.5 14.1182 17.5C12.5192 17.5 11.6805 16.4073 11.0438 15.293C7.2419 16.3742 3 13.4728 3 9.02466ZM12.8984 9.02466C12.8984 5.56841 11.8481 3.75 9.38655 3.75C6.96394 3.75 5.91331 5.56764 5.9127 9.03076C5.9127 12.4701 6.96275 14.2285 9.38536 14.2285C9.77249 14.2285 10.119 14.1887 10.4423 14.0894C9.94191 13.0781 9.35398 12.0557 8.2072 12.0557L8.1888 12.0557C7.9803 12.0556 7.85612 12.0555 7.66151 12.1521L7.268 11.3452C7.74339 10.9208 8.41653 10.6262 9.402 10.6262C10.9463 10.6262 11.7275 11.3884 12.3575 12.3572C12.7227 11.5316 12.9015 10.4109 12.8984 9.02466Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_367">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
