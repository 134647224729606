import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

export default function RightPanel({
  open,
  close,
  title,
  children,
  className,
}: PropsWithChildren<{
  open: boolean;
  title: string;
  close: () => void;
  className?: string;
}>) {
  return (
    <Dialog open={open} onClose={close} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-10">
            <DialogPanel
              transition
              className={twMerge(
                "pointer-events-auto relative w-screen max-w-md transform transition duration-500 ease-in-out sm:duration-700",
                "data-[closed]:translate-y-full sm:data-[closed]:translate-y-0 sm:data-[closed]:translate-x-full",
                className,
              )}
            >
              <TransitionChild>
                <div className="sm:absolute flex justify-center left-0 top-0 pr-2 py-2 sm:pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    onClick={close}
                    className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon aria-hidden="true" className="size-6" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl pt-4">
                {title && (
                  <div className="py-2 px-4 sm:px-6">
                    <DialogTitle className="text-2xl font-semibold text-gray-900">
                      {title}
                    </DialogTitle>
                  </div>
                )}
                <div className="relative flex-1 px-4 sm:px-6">{children}</div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
