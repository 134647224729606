import React from "react";

export default function DelimitedList({
  list,
  delimiter = "•",
  spacing = "mx-2",
  itemClass,
}: {
  list: (string | string[] | React.ReactNode)[];
  delimiter?: string;
  spacing?: string;
  itemClass?: string;
}) {
  if (!list?.length) return [];
  list = list.flat();

  return list.map((item, i) => (
    <React.Fragment key={i}>
      <span className={itemClass}>{item}</span>
      {i < list.length - 1 && <span className={spacing}>{delimiter}</span>}
    </React.Fragment>
  ));
}
