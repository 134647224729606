import { IconProps } from "@/components/icons/types";

export default function SubstackIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_63_23)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM5 8.121H14.9995H15V6.703H5V8.121ZM5 16V9.406H15V16L10 13.055L5 16ZM5 4H15V5.418H5V4Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_63_23">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
