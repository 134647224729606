/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { useDevTools } from "@/hooks/useUIStore";
import { useEntityStore } from "@/stores/entityStore";
import { AllCharacteristics, CharacteristicType, ProfilePageSection } from "@/types";
import { buildCharacteristicsToShow } from "@/utils/entityUtils";
import { useStore } from "@nanostores/react";
import { startCase, toLower, uniq } from "lodash";
import { twJoin, twMerge } from "tailwind-merge";

export default withErrorBoundary(function CompanyStats() {
  const entityStore = useEntityStore();
  const snapshot = useStore(entityStore.snapshot);
  const isDev = useDevTools();

  if (!isDev) return null;

  if (!snapshot.characteristics) return null;

  const characteristics = snapshot.characteristics;
  const typesToShow = buildCharacteristicsToShow(characteristics);
  if (typesToShow.length === 0) return null;

  return (
    <ProfileBubble
      title="Characteristics (dev only)"
      section={ProfilePageSection.Characteristics}
      className="bg-blue-50"
    >
      <div className="flex flex-col">
        {typesToShow.map((type) => (
          <StatsBubble key={type} type={type} value={characteristics[type]} />
        ))}
      </div>
    </ProfileBubble>
  );
});

function StatsBubble<T extends CharacteristicType>({
  type,
  value,
}: {
  type: T;
  value: AllCharacteristics[T] | undefined;
}) {
  const label = type;
  if (!value || (Array.isArray(value) && value.length === 0)) return null;

  // use a different format for short vs long answers
  const stringified = typeof value == "string" ? value : JSON.stringify(value);
  const linebreak = stringified.length > 50;
  return (
    <div className="flex border-b last:border-none py-2">
      <div
        className={twJoin(
          "flex w-full",
          linebreak ? "flex-col" : "flex-row items-center justify-between",
        )}
      >
        <div className="mt-1 text-md self-baseline font-semibold">{label}</div>
        <div className="flex flex-wrap gap-x-1 justify-end ">
          {Array.isArray(value) ?
            uniq(
              value.map((val) =>
                typeof val === "object" ? JSON.stringify(val) : startCase(toLower(val)),
              ),
            ).map((val, index) => (
              <div
                key={index}
                className="text-sm leading-2 whitespace-nowrap overflow-hidden text-ellipsis"
              >
                {index > 0 && <span> • </span>}
                {val}
              </div>
            ))
          : <div className="text-sm">{String(value)}</div>}
        </div>
      </div>
    </div>
  );
}
