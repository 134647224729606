import millify from "millify";

const moneyMultipliers = {
  K: 1_000,
  M: 1_000_000,
  B: 1_000_000_000,
  T: 1_000_000_000_000,
};

export const CurrencyExtractors = {
  Dollars: parseDollarAmount,
};

function parseDollarAmount(input: unknown): number | undefined {
  if (!input || typeof input !== "string") {
    return undefined;
  }

  const trimmedInput = input.trim();
  const isNegative = trimmedInput.startsWith("-");
  const sanitizedInput = trimmedInput.replace(/^\-|\$/g, "");

  const match = sanitizedInput.match(/^(\d+(\.\d+)?)([KMBT]?)$/i);
  if (!match) {
    const fallbackAmount = parseInt(sanitizedInput.split(" ")[0].replace(/\D/g, ""), 10);
    return (
      isNaN(fallbackAmount) ? undefined
      : isNegative ? -fallbackAmount
      : fallbackAmount
    );
  }

  const [_, rawAmount, , unit] = match;
  const amount = parseFloat(rawAmount.replace(/,/g, ""));
  if (isNaN(amount)) {
    return undefined;
  }

  const multiplier = moneyMultipliers[unit.toUpperCase() as keyof typeof moneyMultipliers] || 1;
  if (isNaN(multiplier)) {
    return undefined;
  }

  const result = amount * multiplier;
  return isNegative ? -result : result;
}

export const CurrencyFormatters = {
  Dollars: (amount: number | string, options?: { precision?: number }): string | undefined => {
    const parsedAmount = typeof amount === "string" ? parseDollarAmount(amount) : amount;
    if (!parsedAmount || isNaN(parsedAmount)) {
      return undefined;
    }
    const precision = options?.precision || 2;
    return `$${millify(parsedAmount, { precision })}`;
  },
};
