import { ExtractorMap } from "@/extractors/types";
import { CoreExtractors } from "./core";
import { CurrencyExtractors } from "./currency";
import { VentureExtractors } from "./venture";

const Extractors = {
  ...CoreExtractors,
  ...CurrencyExtractors,
  ...VentureExtractors,
} satisfies ExtractorMap;

export default Extractors;
