import { IconProps } from "@/components/icons/types";

export default function LinkedinIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_7357_18246)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM3.08036 7.59825V17.1429H6.04464H6.04911V7.59825H3.08036ZM2.84375 4.57593C2.84375 5.52236 3.61161 6.29468 4.5625 6.29468C5.51339 6.29468 6.28125 5.52682 6.28125 4.57593C6.28125 3.6295 5.50893 2.85718 4.5625 2.85718C3.61161 2.85718 2.84375 3.6295 2.84375 4.57593ZM14.192 17.1429H17.1562V11.9107C17.1562 9.33932 16.5982 7.36164 13.5982 7.36164C12.1563 7.36164 11.1875 8.15182 10.7902 8.90182H10.75V7.59825H7.90625V17.1429H10.8705V12.4197C10.8705 11.1741 11.1071 9.96878 12.6518 9.96878C14.1696 9.96878 14.192 11.3929 14.192 12.5V17.1429Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_7357_18246">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
