import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

/* eslint-disable @next/next/no-img-element */
import Card from "@/components/cards/Card";
import { shouldShowCompanyWebsite } from "@/components/companies/CompanySubtitle";
import useEntityCardData from "@/components/hooks/useEntityCardData";
import Button from "@/components/ui/Button";
import Loader from "@/components/ui/Loader";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import PopoverMenu from "@/components/ui/PopoverMenu";
import Spinner from "@/components/ui/Spinner";
import { useEntitySidebarClick } from "@/hooks/useEntitySidebarClick";
import { useErrorTracker } from "@/hooks/useErrorTracker";
import { formatNumber, pluralize, prettyUrl } from "@/lib/stringUtils";
import { MutualConnectionsData } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { CompanyCardData, Entity, EntityType, PersonCardData, userMeta } from "@/types";
import { maybeEntityUrl } from "@/utils/entityUtils";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import Link from "next/link";

// The degree of connection of this entity to the user is not currently
// available directly in the database, but is sometimes available through
// indirect means (e.g. when showing mutual connections). If it's available
// we might want to show it somehow.
export type EntityForCard = Partial<
  Pick<Entity, "id" | "type" | "imageUrl" | "url" | "name" | "title">
> &
  Pick<Entity, "type"> & { degree?: string };

export type EntityCardProps = {
  entity: EntityForCard;
  // Email will be shown for EntityType.Person if provided, otherwise
  // more facts will take up the space.
  email?: string;
  loading?: boolean;
  border?: boolean;
  active?: boolean;
  entityIconClassName?: string;
  entityDetailsClassName?: string;
  onClick?: (e: React.MouseEvent) => void;
  contextMenu?: React.JSX.Element | boolean;
  actionButton?: React.JSX.Element;
  suggestionMenu?: React.JSX.Element;
  className?: string;
};

export default function EntityCard({
  border = true,
  active = false,
  entity: originalEntity,
  email,
  contextMenu,
  actionButton,
  loading,
  entityIconClassName,
  entityDetailsClassName,
  onClick,
  suggestionMenu,
  className,
}: EntityCardProps) {
  const [entity, setEntity] = useState<EntityForCard>(originalEntity);

  const onCardClick = useEntitySidebarClick(entity.id, entity.url, onClick);
  const user = useStore(uiStore.user);
  const userMetaObject = userMeta(user);
  const errorTracker = useErrorTracker();

  const { data: cardData, isLoading: factsLoading } = useEntityCardData({ entityId: entity.id });

  useEffect(() => {
    // If original entityChanged, update the entity
    if (cardData) {
      if (!originalEntity.id) {
        setEntity({ ...originalEntity } as EntityForCard);
      } else {
        setEntity({ ...originalEntity, ...cardData.entity } as EntityForCard);
      }
    } else {
      setEntity({
        ...originalEntity,
      } as EntityForCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalEntity]);

  useEffect(() => {
    // If cardData changed, update the entity, but never override it with
    // originalEntity.
    if (cardData) {
      setEntity({ ...originalEntity, ...cardData.entity } as EntityForCard);
      if (cardData.entity?.id !== originalEntity.id) {
        errorTracker.sendError(
          `CardData returning different id than requested: ${cardData.entity?.id} !== ${originalEntity.id}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  if (contextMenu && actionButton) {
    throw new Error("Cannot have both contextMenu and actionButton");
  }

  const personCardData =
    entity.type === EntityType.Person ? (cardData as PersonCardData) : undefined;
  const companyCardData =
    entity.type === EntityType.Company ? (cardData as CompanyCardData) : undefined;

  const buttonLabel =
    loading ? <Loader /> : <EllipsisVerticalIcon className="w-4 text-gray-500 ml-auto " />;

  const TwoFacts = ({ fact1, fact2 }: { fact1?: string; fact2?: string }) => {
    // In a flex context this means: starting with 0px grow this element as much as other elements,
    // but never make it larger than necessary. This means that if two facts are ellipsised, they
    // will be equal size, but if only one is, the other one will take as little space
    // as possible.
    const factClass =
      "overflow-hidden text-ellipsis text-nowrap flex-shrink-1 grow basis-0 max-w-[fit-content]";

    if (!fact1 && !fact2) return null;

    return (
      <div className="flex flex-row justify-start">
        {fact1 && <div className={factClass}>{fact1}</div>}
        {fact1 && fact2 && <div className="flex-shrink-0">&nbsp;•&nbsp;</div>}
        {fact2 && <div className={factClass}>{fact2}</div>}
      </div>
    );
  };

  const entityUrl = maybeEntityUrl(entity);

  const handleDragStart = (e: React.DragEvent) => {
    if (entity.url) {
      e.dataTransfer.setData("url", entity.url);
    }
  };

  return (
    <Card
      border={border}
      onClick={onCardClick}
      href={entityUrl}
      draggable={!loading && !!entity.url}
      onDragStart={handleDragStart}
      active={active}
      className={className}
    >
      {suggestionMenu && !loading && email && (
        <PopoverMenu
          buttonClass="absolute top-2 right-6"
          buttonLabel={<QuestionMarkCircleIcon className="h-4 w-4 text-gray-500" />}
          floatingOptions={{ placement: "bottom-end" }}
        >
          {suggestionMenu}
        </PopoverMenu>
      )}
      {contextMenu && (
        <PopoverMenu
          buttonClass="absolute top-2 right-2"
          buttonLabel={buttonLabel}
          floatingOptions={{ placement: "bottom-end" }}
        >
          {contextMenu}
        </PopoverMenu>
      )}
      {actionButton && <div className="absolute top-2 right-2">{actionButton}</div>}
      {!contextMenu && loading && <div className="absolute top-2 right-2">{buttonLabel}</div>}

      <div className="flex items-center gap-4 max-h-full">
        {
          <EntityIconWithPlaceholder
            className={twMerge(
              "h-[80px] w-[80px] shadow-inner overflow-hidden",
              entityIconClassName,
            )}
            imageClassName="w-full h-full"
            entity={entity}
          />
        }

        <div className="flex-1 flex flex-col overflow-hidden text-xs">
          <div
            className={twMerge(
              "overflow-hidden line-clamp-1 text-ellipsis mb-1 font-semibold pr-4",
              entityDetailsClassName,
            )}
          >
            {entity.name}
          </div>
          {email && email !== entity.name && entity.type !== EntityType.Company && (
            <div
              className={twMerge("text-gray-500 line-clamp-1 text-ellipsis overflow-hidden mb-1")}
            >
              {email}
            </div>
          )}
          {email && !entity.id && !loading && (
            <Button className="justify-center mt-2">Add profile</Button>
          )}
          {factsLoading && <Spinner />}
          {entity.type === EntityType.Person && !factsLoading && (
            <TwoFacts fact1={personCardData?.currentPosition || entity.title || ""} />
          )}
          {entity.type === EntityType.Person && !factsLoading && (
            <TwoFacts fact1={personCardData?.currentCompany} fact2={personCardData?.location} />
          )}
          {entity.type === EntityType.Person && !email && (
            <TwoFacts
              fact1={
                entity.id && userMetaObject?.entity_id === entity.id ? "You"
                : personCardData?.directlyConnected || entity.degree === "1st" ?
                  "Connected"
                : !!personCardData?.mutuals ?
                  pluralize(personCardData?.mutuals || 0, "Mutual")
                : entity.degree === "2nd" ?
                  "2nd degree connection"
                : ""
              }
              fact2={personCardData?.pronouns}
            />
          )}
          {entity.type === EntityType.Person && !email && !personCardData && !factsLoading && (
            <TwoFacts fact1={"(click for more details)"} />
          )}
          {entity.type === EntityType.Company && (
            <TwoFacts
              fact1={
                companyCardData?.mutuals ? pluralize(companyCardData?.mutuals || 0, "Mutual") : ""
              }
              fact2={
                companyCardData?.employees ?
                  pluralize(companyCardData?.employees || 0, "Person", "People")
                : ""
              }
            />
          )}
          {entity.type === EntityType.Company && (
            <TwoFacts
              fact1={companyCardData?.location}
              fact2={companyCardData?.founded ? `Founded ${companyCardData?.founded}` : ""}
            />
          )}
          {entity.type === EntityType.Company &&
            companyCardData?.website &&
            shouldShowCompanyWebsite(companyCardData?.website) && (
              <div>
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  href={companyCardData?.website}
                  target="_blank"
                  className="flex flex-row text-gray-500 hover:underline mt-2 w-fit"
                >
                  <div className="overflow-hidden text-ellipsis text-nowrap">
                    {prettyUrl(companyCardData?.website)}
                  </div>
                  <ArrowTopRightOnSquareIcon className="h-3 w-3 self-center ml-1 flex-shrink-0" />
                </Link>
              </div>
            )}
        </div>
      </div>
    </Card>
  );
}

export const makeEntityCardFromMutualConnection = (
  connection: MutualConnectionsData,
): EntityForCard => {
  const makeEntityUrl = (connection: MutualConnectionsData) => {
    if (!connection) {
      return undefined;
    }
    return connection.data?.linkedinUrl;
  };

  const entity: Partial<Entity> = connection.entity || {};

  return {
    ...entity,
    type: EntityType.Person,
    name: entity.name || connection?.data?.name || "",
    url: entity.url || makeEntityUrl(connection),
    imageUrl:
      entity.imageUrl?.startsWith("http") ? entity.imageUrl
      : connection?.data?.profileImage?.startsWith("http") ? connection?.data?.profileImage
      : undefined,
    degree: connection?.data?.degree,
  } as EntityForCard;
};
