import { IconProps } from "@/components/icons/types";

export default function BlueskyIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_47_371)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM10.1801 9.46999C10.9202 8.47744 12.4939 6.91174 13.9652 6.10543C15.1234 5.47098 17 4.98004 17 6.54249C17 6.85461 16.755 9.16391 16.6112 9.53873C16.2249 10.5462 15.0495 10.9735 13.7876 11.0227C13.6674 11.0278 13.6464 11.1439 13.7603 11.1732C15.6617 11.6599 15.9984 12.6576 14.6665 13.6553C12.2317 15.4639 10.9673 13.9558 10.3818 12.9521C10.2399 12.7082 9.76073 12.7082 9.61818 12.9521C9.03336 13.9558 7.76827 15.4639 5.33355 13.6553C4.001 12.6576 4.33827 11.6599 6.23973 11.1732C6.35364 11.1439 6.33327 11.0273 6.21236 11.0227C4.95045 10.9735 3.77445 10.5462 3.38882 9.53873C3.245 9.16345 3 6.85461 3 6.54249C3 4.98004 4.87664 5.47098 6.03418 6.10543C7.50545 6.91174 9.07918 8.47744 9.81927 9.46999C9.89754 9.57496 10.1018 9.57496 10.1801 9.46999Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_371">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
