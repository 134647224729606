"use client";

import { useSidebar } from "@/components/providers/SidebarProvider";
import { ListSelector } from "./ListSelector";

export type BulkAddToListProps = {
  onListSelected: (listId: string) => void | Promise<void>;
};

export const BulkAddToList = ({ onListSelected }: BulkAddToListProps) => {
  const sidebar = useSidebar();

  const handleListSelected = (listId: string) => {
    sidebar.closeSidebar();
    void onListSelected(listId);
  };

  return <ListSelector onListSelected={handleListSelected} />;
};
