import { type Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

import {
  DstInvestmentStage,
  DstInvestmentStageSchema,
  DstVCFirmProfile as FirmProfileSchema,
  DstInvestorProfile as InvestorProfileSchema,
  DstPortfolioCompany as PortfolioCompanySchema,
  DstPortfolioInvestment as PortfolioInvestmentSchema,
  DstVCFund as VCFundSchema,
  DstVCStage as VCStageSchema,
  DstVCTeamMember as VCTeamMemberSchema,
} from "./venture.typebox";

export { DstInvestmentStage };

export type DstInvestorProfile = Static<typeof InvestorProfileSchema>;

export type DstPortfolioCompany = Static<typeof PortfolioCompanySchema>;
export type DstPortfolioInvestment = Static<typeof PortfolioInvestmentSchema>;

export type DstVCFund = Static<typeof VCFundSchema>;
export type DstVCStage = Static<typeof VCStageSchema>;
export type DstVCTeamMember = Static<typeof VCTeamMemberSchema>;
export type DstVCFirmProfile = Static<typeof FirmProfileSchema>;

export function isDstInvestmentStage(u: unknown): u is DstInvestmentStage {
  return Value.Check(DstInvestmentStageSchema, u);
}
