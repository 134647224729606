import { DstInvestmentStage } from "@/dst";
import Extractors from "@/extractors";
import {
  EmploymentType,
  PersonCompanyRelationship,
  RelationshipDataInvestedIn,
  RelationshipDataWorkedAt,
  RelationshipWithEntity,
} from "@/types";
import moment from "moment";

export interface WorkExperienceSummary {
  fulltimeCompaniesCount: number;
  fulltimePositionsCount: number;
  fulltimeWorkDuration: moment.Duration;
  fulltimeAverageDuration: moment.Duration;
  locations: string[];
  // Missing: 6 yrs in Sales, 2 as VP of Sales
  // Missing: 7 years at startups under 50 people
}

export interface InvestmentSummary {
  companiesCount: number;
  firstInvestmentYear?: number;
  lastInvestmentYear?: number;
  preSeedCount: number;
  seedCount: number;
  seriesACount: number;
  seriesBPlusCount: number;
  locations: string[];
  sectors: string[];
}

export function buildWorkExperienceSummaryToShow(
  allGroupedExperiences: RelationshipWithEntity[][],
): WorkExperienceSummary {
  // Initialize the summary object
  const summary: WorkExperienceSummary = {
    fulltimeCompaniesCount: 0,
    fulltimePositionsCount: 0,
    fulltimeWorkDuration: moment.duration(0),
    fulltimeAverageDuration: moment.duration(0),
    locations: [],
  };

  // Counting current positions separately, in order to only add them if they
  // were to increase the average time at a company.
  let currentCompaniesCount = 0;
  const currentWorkDuration = moment.duration(0);

  // Unique set of locations to avoid duplicates
  const uniqueLocations = new Set<string>();

  // Track fulltime relationships for duration calculations
  const fulltimeRelationships: RelationshipWithEntity[] = [];

  // Process each group of experiences
  allGroupedExperiences.forEach((group) => {
    // Find the work-related relationships in this group
    const workRelationships = group.filter(
      (rel) => rel.type === PersonCompanyRelationship.WorkedAt,
    );

    if (workRelationships.length === 0) return;

    const isFulltime = workRelationships.some((rel) => {
      const data = rel.data as RelationshipDataWorkedAt;
      return !data.type || data.type.toLowerCase() === EmploymentType.FullTime.toLowerCase();
    });

    const isCurrent = workRelationships.some((rel) => {
      return rel.endedDate === "Present" || !rel.endedDate;
    });

    if (isFulltime) {
      // Add all relationships from this fulltime company for duration calculation
      fulltimeRelationships.push(...workRelationships);

      if (isCurrent) {
        currentCompaniesCount++;
      } else {
        summary.fulltimeCompaniesCount++;
      }
    }

    // Collect all unique locations
    workRelationships.forEach((rel) => {
      const data = rel.data as RelationshipDataWorkedAt;
      if (data.location) {
        uniqueLocations.add(data.location);
      }
    });
  });

  const totalFulltimeDuration = moment.duration(0);

  fulltimeRelationships.forEach((rel) => {
    summary.fulltimePositionsCount++;
    if (rel.startedDate) {
      const startDate = moment(rel.startedDate);
      // Use current date if no end date is provided (assuming still working there)
      const endDate =
        rel.endedDate && rel.endedDate !== "Present" ? moment(rel.endedDate) : moment();

      const isCurrent = !rel.endedDate || rel.endedDate === "Present";

      if (startDate.isValid() && endDate.isValid()) {
        const duration = moment.duration(endDate.diff(startDate));

        if (isCurrent) {
          currentWorkDuration.add(moment().diff(startDate));
        } else {
          totalFulltimeDuration.add(duration);
        }
      }
    }
  });

  let currentCompaniesAverageDuration = moment.duration(0);
  if (currentCompaniesCount > 0) {
    currentCompaniesAverageDuration = moment.duration(
      currentWorkDuration.asMilliseconds() / currentCompaniesCount,
    );
  }

  // Calculate average duration if there are fulltime companies
  if (summary.fulltimeCompaniesCount > 0) {
    summary.fulltimeAverageDuration = moment.duration(
      totalFulltimeDuration.asMilliseconds() / summary.fulltimeCompaniesCount,
    );
  }

  // We add the current company count after calculating average.
  summary.fulltimeCompaniesCount += currentCompaniesCount;
  if (
    currentCompaniesAverageDuration.asMilliseconds() >
    summary.fulltimeAverageDuration.asMilliseconds()
  ) {
    // Current companies would increase the average duration, so we add them
    summary.fulltimeAverageDuration = moment.duration(
      (totalFulltimeDuration.asMilliseconds() + currentWorkDuration.asMilliseconds()) /
        summary.fulltimeCompaniesCount,
    );
  }

  // Create time periods for merging overlapping work experiences
  const timePeriods: { start: moment.Moment; end: moment.Moment }[] = [];

  // Create normalized time periods from relationships
  fulltimeRelationships.forEach((rel) => {
    if (rel.startedDate) {
      const startDate = moment(rel.startedDate);
      // Use current date if no end date is provided (assuming still working there)
      const endDate =
        rel.endedDate && rel.endedDate !== "Present" ? moment(rel.endedDate) : moment();

      if (startDate.isValid() && endDate.isValid()) {
        timePeriods.push({
          start: startDate,
          end: endDate,
        });
      }
    }
  });

  // Sort time periods by start date
  timePeriods.sort((a, b) => a.start.valueOf() - b.start.valueOf());

  // Merge overlapping periods
  const mergedPeriods: { start: moment.Moment; end: moment.Moment }[] = [];
  if (timePeriods.length > 0) {
    let currentPeriod = { ...timePeriods[0] };

    for (let i = 1; i < timePeriods.length; i++) {
      const period = timePeriods[i];

      // If current period overlaps with the next one
      if (period.start <= currentPeriod.end) {
        // Extend current period if needed
        if (period.end > currentPeriod.end) {
          currentPeriod.end = period.end;
        }
      } else {
        // No overlap, add current period to merged list and start new one
        mergedPeriods.push(currentPeriod);
        currentPeriod = { ...period };
      }
    }

    // Add the last period
    mergedPeriods.push(currentPeriod);
  }

  // Calculate total duration from merged periods
  const totalFulltimeDurationNormalized = moment.duration(0);
  mergedPeriods.forEach((period) => {
    totalFulltimeDurationNormalized.add(moment.duration(period.end.diff(period.start)));
  });

  summary.fulltimeWorkDuration = totalFulltimeDurationNormalized;

  // Convert locations set to array
  summary.locations = Array.from(uniqueLocations);

  return summary;
}

export function buildInvestmentSummaryToShow(
  allGroupedExperiences: RelationshipWithEntity[][],
): InvestmentSummary {
  // Initialize the summary object
  const summary: InvestmentSummary = {
    companiesCount: 0,
    firstInvestmentYear: undefined,
    lastInvestmentYear: undefined,
    preSeedCount: 0,
    seedCount: 0,
    seriesACount: 0,
    seriesBPlusCount: 0,
    locations: [],
    sectors: [],
  };

  // Unique sets for locations and sectors to avoid duplicates
  const uniqueLocations = new Set<string>();
  const uniqueSectors = new Set<string>();

  // Process each group of experiences
  allGroupedExperiences.forEach((group) => {
    // Find the investment-related relationships in this group
    const investmentRelationships = group.filter(
      (rel) => rel.type === PersonCompanyRelationship.InvestedIn,
    );

    if (investmentRelationships.length === 0) return;

    // Count this as a company with investments
    summary.companiesCount++;

    // Process each investment in this company
    investmentRelationships.forEach((rel) => {
      const data = rel.data as RelationshipDataInvestedIn;

      // Extract year from date or relationship dates
      let investmentYear: number | null = null;

      if (data.date) {
        // Try to extract year from the investment data
        const yearMatch = data.date.match(/^(\d{4})/);
        if (yearMatch) {
          investmentYear = parseInt(yearMatch[1], 10);
        }
      } else if (rel.startedDate) {
        // Use the relationship start date as fallback
        const yearMatch = rel.startedDate.match(/^(\d{4})/);
        if (yearMatch) {
          investmentYear = parseInt(yearMatch[1], 10);
        }
      }

      // Update first and last investment years
      if (investmentYear) {
        summary.firstInvestmentYear = Math.min(
          summary.firstInvestmentYear || Number.MAX_SAFE_INTEGER,
          investmentYear,
        );
        summary.lastInvestmentYear = Math.max(summary.lastInvestmentYear || 0, investmentYear);
      }

      // Count funding rounds
      if (data.fundingRound) {
        const stage = Extractors.InvestmentStage(data.fundingRound);
        if (stage) {
          switch (stage) {
            case DstInvestmentStage.PreSeed:
              summary.preSeedCount++;
              break;
            case DstInvestmentStage.Seed:
              summary.seedCount++;
              break;
            case DstInvestmentStage.SeriesA:
              summary.seriesACount++;
              break;
            default:
              summary.seriesBPlusCount++;
              break;
          }
        }
      }
    });
  });

  // Convert sets to arrays
  summary.locations = Array.from(uniqueLocations);
  summary.sectors = Array.from(uniqueSectors);

  return summary;
}
