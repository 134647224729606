import { useRouter } from "next/compat/router";
import { twMerge } from "tailwind-merge";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  border?: boolean;
  active?: boolean;
}

export default function Card({
  border,
  href,
  onClick,
  children,
  className,
  active,
  ...rest
}: Props) {
  const router = useRouter();
  return (
    <div
      onClick={(e: React.MouseEvent) => {
        if (onClick) {
          onClick?.(e);
        } else if (href) {
          void router?.push(href);
        }
      }}
      {...rest}
    >
      <div className="h-full w-full">
        <div
          className={twMerge(
            "rounded-md relative border-gray-100 w-full h-full p-2",
            border && "border",
            active ? "bg-brand-700 text-white hover:bg-brand-600" : "bg-white hover:bg-blue-100",
            `cursor-pointer`,
            className,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
