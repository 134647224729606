"use client";
import { useEntityChatStore } from "@/stores/entityChatStore";
import { EntityIdContext, useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { Attribute, Entity, FactSet, GenericProfile, PipelineRunStatus } from "@/types";
import { useStore } from "@nanostores/react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

interface Props {
  children: React.ReactNode;
  entity: Entity;
  snapshot: GenericProfile;
  props: {
    attributes?: Attribute[];
    facts?: FactSet;
    sourcesOnly?: boolean;
    aliases?: string[];
    status?: PipelineRunStatus;
  };
}

export default function EntityStoresProvider({ children, entity, ...rest }: Props) {
  return (
    <EntityIdContext.Provider value={entity.id}>
      <EntityStoresProviderInternal entity={entity} {...rest}>
        {children}
      </EntityStoresProviderInternal>
    </EntityIdContext.Provider>
  );
}

function EntityStoresProviderInternal({ children, entity, snapshot, props }: Props) {
  const entityStore = useEntityStore();
  const entityChatStore = useEntityChatStore();
  const entityInStore = useStore(entityStore.entity);
  const user = useStore(uiStore.user);
  const uiLoaded = !!useStore(uiStore.loadedAt);
  const posthog = usePostHog();

  useEffect(() => {
    if (!uiLoaded) {
      // entityStore.load depends on uiStore being loaded, but it's a hidden
      // requirement (subscription to ably requires the user to be set).
      // A better solution to this would be loading the UI (using the useUI hook)
      // in one place only, and not loading anything else until it's done, but
      // that requires a larger refactor and potentially app router migration.
      return;
    }
    if (!entityInStore || entityInStore.id !== entity.id) {
      void entityStore.load(entity, snapshot, props);
      posthog?.capture("view-entity", {
        entityId: entity.id,
        type: entity.type,
        name: entity.name,
      });
    }
  }, [uiLoaded, entity, entityInStore, snapshot, props]);

  useEffect(() => {
    if (user && entity) {
      entityChatStore.init(entity);
    }
  }, [user, entity, entityChatStore]);

  return <>{children}</>;
}

export function EntityStoresProviderForTest({ children }: { children: React.ReactNode }) {
  return <EntityIdContext.Provider value={"test"}>{children}</EntityIdContext.Provider>;
}
