import { useSession } from "next-auth/react";
import { useRouter as useCompatRouter } from "next/compat/router";
import { useRouter as useAppRouter } from "next/navigation";

import { useEffect } from "react";

import { loggerWithPrefix } from "@/lib/logger";
import { ServerProps } from "@/shared/serverProps";
import { listStore } from "@/stores/listStore";
import { uiStore } from "@/stores/uiStore";
import { FeatureFlag, UserWithMeta } from "@/types";
import { useStore } from "@nanostores/react";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";

const logger = loggerWithPrefix("useUIStore");

export const useDevTools = () => {
  return useStore(uiStore.isDev);
};

export function useLoggedOutUI() {
  useUI({});
}

export function useUI(props?: ServerProps) {
  const session = useSession();
  const compatRouter = useCompatRouter();
  const appRouter = useAppRouter();

  const maxSizeFlag = useFeatureFlagPayload(FeatureFlag.ListMaxSize);

  const user = props?.user || (session?.data?.dbUser as UserWithMeta | undefined);

  if (!props) {
    props = {
      userId: user?.id,
      user,
    };
  }

  useEffect(() => {
    uiStore.init(props || {});
  }, [props]);

  useEffect(() => {
    uiStore.router = compatRouter || appRouter;
  }, [compatRouter, appRouter]);

  useEffect(() => {
    if (props?.originalUser) {
      uiStore.originalUser.set(props.originalUser);
    }
    if (user) {
      uiStore.setUser(user);
    } else {
      uiStore.setNoUser();
    }
  }, [props?.originalUser, user]);

  useEffect(() => {
    if (typeof maxSizeFlag === "number") {
      logger.debug("Setting max list size to", maxSizeFlag);
      listStore.maxSize.set(maxSizeFlag);
    }
  }, [maxSizeFlag]);

  return session;
}
