import {
  DamerauLevenshteinDistanceOptions,
  JaroWinklerDistance,
  LevenshteinDistanceSearch,
  SubstringDistanceResult,
} from "natural/lib/natural/distance";
import { WordTokenizer } from "natural/lib/natural/tokenizers";

import { DistillLogger, Level, loggerWithPrefix } from "@/lib/logger";

const systemLogger = loggerWithPrefix("utils/similarityUtils");

type Name = string;

type HasName = string | { name?: string };

/**
 * Similarity thresholds for string matching.
 *
 * These are an abstraction of Levenshtein distance:
 *
 * Threshold of 1 (STRICT): Allows for a single-character typo or variation. Suitable for very distinct names or when precision is critical.
 * Threshold of 2 (DEFAULT): Allows for minor variations, such as a couple of typos or a small word difference. This is a common choice for moderate tolerance.
 * Threshold of 3 (LOOSE): Allows for more significant variations, which might be suitable if the names are longer or if you want to capture more potential matches at the risk of some false positives.
 * Start with a threshold of 1 or 2, especially if false positives are a concern, and adjust based on testing and the specific characteristics of your data.
 */
export enum SimilarityThreshold {
  STRICT = 1,
  DEFAULT = 2,
  LOOSE = 3,
}

class SimilarityUtils {
  readonly tokenizer = new WordTokenizer();

  /**
   * Get the maximum similarity between a source and a list of targets.
   */
  private getMaxSimilarity(source: string, targets: string[]): number {
    return Math.max(...targets.map((target) => JaroWinklerDistance(source, target)));
  }

  /**
   * Compare two arrays of parts and return the similarity score.
   */
  private compareParts(partsA: string[], partsB: string[]): number {
    const [leftParts, rightParts] =
      partsA.length > partsB.length ? [partsA, partsB] : [partsB, partsA];
    const partWeight = 1 / leftParts.length;
    return leftParts.reduce<number>((totalScore, leftPart) => {
      const similarityScore = this.getMaxSimilarity(leftPart, rightParts);
      return totalScore + similarityScore * partWeight;
    }, 0);
  }

  /**
   * Determines if two names are similar enough.
   *
   * Uses Jaro-Winkler distance for name comparison. ChatGPT says:
   * "For person names, I recommend Jaro-Winkler Distance or Metaphone
   * as the most appropriate approaches. They handle common misspellings,
   * transpositions, and slight differences in how names are written
   * while prioritizing the most relevant parts of the name."
   *
   * The default threshold is 0.9. ChatGPT says:
   * "For comparing names, I recommend using a threshold of 0.90. This
   * allows you to catch names with minor differences while maintaining a
   * high degree of confidence that the names are the same."
   *
   */
  isNameSimilarEnough(
    left: Name,
    right: Name,
    {
      threshold: thresholdOption,
      logger = systemLogger,
    }: { threshold?: number; logger?: DistillLogger } = {},
  ): {
    isSimilar: boolean;
    nameScore?: number;
  } {
    if (!left || !right) {
      logger.trace(`No names to compare, returning false`);
      return { isSimilar: false };
    }

    // Split names into parts because Jaro-Winkler favors the beginning of words.
    // By splitting we get a better match for nicknames, like "Jon Doe" and "Jonathan Doe".
    const leftParts = this.tokenizer.tokenize(left);
    const rightParts = this.tokenizer.tokenize(right);

    // Use a more forgiving threshold for names with more parts to account for
    // titles and middle names.
    const partsCount = Math.max(leftParts.length, rightParts.length);
    const threshold = thresholdOption ?? (partsCount > 2 ? 0.8 : 0.9);

    logger.trace(`Comparing email names: ${left} vs ${right}, threshold: ${threshold}`);

    const nameScore = this.compareParts(leftParts, rightParts);
    if (nameScore < threshold) {
      logger.trace(`Name similarity score is below threshold: ${nameScore} < ${threshold}`);
      return { isSimilar: false, nameScore };
    }

    logger.trace(`Names are similar, returning true: score = ${nameScore}`);
    return { isSimilar: true, nameScore };
  }

  /**
   * Rank targets by similarity to a source.
   */
  sort<T extends HasName = string>({
    source,
    targets,
    options,
  }: {
    source: string;
    targets: readonly T[];
    options?: DamerauLevenshteinDistanceOptions;
  }): { target: T; result?: SubstringDistanceResult }[] {
    const result = targets
      .map((target) => {
        const name = typeof target === "object" ? target.name : String(target);
        if (!name) {
          return {
            target,
          };
        }
        return {
          target,
          result: LevenshteinDistanceSearch(source, name, options),
        };
      })
      .sort((targetA, targetB) => {
        const resultA = targetA.result;
        const resultB = targetB.result;
        if (!resultA && !resultB) {
          return 0;
        }
        if (!resultA) {
          return 1;
        }
        if (!resultB) {
          return -1;
        }
        const distanceDiff = resultA.distance - resultB.distance;
        if (distanceDiff === 0) {
          return resultA.offset - resultB.offset;
        }
        return distanceDiff;
      });
    if (systemLogger.level <= Level.TRACE) {
      systemLogger.trace(`Sorted targets: ${JSON.stringify(result)}`);
    }
    return result;
  }

  /**
   * Find the first target that is similar to a source.
   *
   * Threshold details:
   * Threshold of 1: Allows for a single-character typo or variation. Suitable for very distinct names or when precision is critical.
   * Threshold of 2: Allows for minor variations, such as a couple of typos or a small word difference. This is a common choice for moderate tolerance.
   * Threshold of 3: Allows for more significant variations, which might be suitable if the names are longer or if you want to capture more potential matches at the risk of some false positives.
   * Start with a threshold of 1 or 2, especially if false positives are a concern, and adjust based on testing and the specific characteristics of your data.
   */
  findFirst<T extends HasName = string>({
    source,
    targets,
    threshold = SimilarityThreshold.DEFAULT,
    options,
  }: {
    source: string;
    targets: readonly T[];
    threshold?: SimilarityThreshold;
    options?: DamerauLevenshteinDistanceOptions;
  }): { target: T; result: SubstringDistanceResult } | null {
    for (const target of targets) {
      const name = typeof target === "string" ? target : target.name;
      if (!name) {
        return null;
      }
      const result = LevenshteinDistanceSearch(source, name, options);
      if (result && result.distance <= Number(threshold)) {
        return { target, result };
      }
    }
    return null;
  }

  /**
   * Find the best match from a list of targets.
   *
   * Threshold details:
   * Threshold of 1: Allows for a single-character typo or variation. Suitable for very distinct names or when precision is critical.
   * Threshold of 2 (default): Allows for minor variations, such as a couple of typos or a small word difference. This is a common choice for moderate tolerance.
   * Threshold of 3: Allows for more significant variations, which might be suitable if the names are longer or if you want to capture more potential matches at the risk of some false positives.
   * Start with a threshold of 1 or 2, especially if false positives are a concern, and adjust based on testing and the specific characteristics of your data.
   */
  findBest<T extends HasName = string>({
    source,
    targets,
    threshold = SimilarityThreshold.DEFAULT,
    options,
  }: {
    source: string;
    targets: readonly T[];
    threshold?: SimilarityThreshold;
    options?: DamerauLevenshteinDistanceOptions;
  }): { target: T; result: SubstringDistanceResult } | null {
    const matches = this.sort({ source, targets, options });
    const bestMatch = matches.find(({ result }) => result && result.distance <= Number(threshold));
    if (bestMatch && !!bestMatch.result) {
      return bestMatch as { target: T; result: SubstringDistanceResult };
    }
    return null;
  }
}

const utils = new SimilarityUtils();

export default utils;
