import { uiStore } from "@/stores/uiStore";
import { Question } from "@/types/assessments";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";

export default function AssessmentQuestions({
  questions,
  removeQuestion,
  addQuestion,
}: {
  questions: Question[];
  removeQuestion: (id: string) => void;
  addQuestion: (question: string) => void;
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="w-full flex flex-row">
        <div className="text-sm text-gray-500 mb-2">Questions</div>
        <div className="grow"></div>
        <div
          className="flex flex-row rounded-md p-1 bg-gray-100 hover:bg-gray-200 border border-gray-200 h-6 w-6"
          onClick={() => {
            uiStore.showInputModal.set({
              title: "Add ranking question",
              type: "add",
              fields: [
                {
                  currentValue: "",
                },
              ],
              onSubmit: (values: string[]) => {
                addQuestion(values[0]);
              },
            });
          }}
        >
          <PlusIcon className="flex-shrink-0 cursor-pointer hover:text-gray-600 text-gray-500" />
        </div>
      </div>
      {questions.map((question, index) => (
        <AssessmentQuestion
          key={question.id}
          id={question.id}
          index={index}
          question={question.question}
          removeQuestion={removeQuestion}
        />
      ))}
    </div>
  );
}

function AssessmentQuestion({
  id,
  index,
  question,
  removeQuestion,
}: {
  id: string;
  index: number;
  question: string;
  removeQuestion: (id: string) => void;
}) {
  return (
    <div className="flex flex-row items-center">
      <div className="bg-gray-100 text-xs text-gray-500 rounded p-1 mr-2 aspect-square w-6 text-center">
        {index + 1}
      </div>
      <div className="text-sm text-gray-500 hover:text-black">{question}</div>
      <div
        className="flex flex-row rounded-md p-1 bg-gray-100 hover:bg-gray-200 border border-gray-200 h-5 w-5 ml-2"
        onClick={() => {
          removeQuestion(id);
        }}
      >
        <XMarkIcon className="flex-shrink-0 cursor-pointer hover:text-gray-600 text-gray-500" />
      </div>
    </div>
  );
}
