import FactInFactTable from "@/components/companies/FactInFactTable";
import GroupedExperienceRow from "@/components/experiences/GroupedExperienceRow";
import InvestmentSummaryHeader from "@/components/experiences/InvestmentSummaryHeader";
import { ExperienceLabels } from "@/components/experiences/utils";
import WorkExperienceSummaryHeader from "@/components/experiences/WorkExperienceSummaryHeader";
import { useSidebar } from "@/components/providers/SidebarProvider";
import ProfileBubble from "@/components/sections/ProfileBubble";
import IconList from "@/components/ui/IconList";
import Spinner from "@/components/ui/Spinner";
import { pluralize } from "@/lib/stringUtils";
import { useEntityStore } from "@/stores/entityStore";
import { profilePageUiStore } from "@/stores/profilePageUiStore";
import {
  CompanyFact,
  EntityType,
  FactType,
  ProfilePageSection,
  RelationshipWithEntity,
  sectionToExperienceNoun,
  VCFact,
} from "@/types";
import {
  buildInvestmentSummaryToShow,
  buildWorkExperienceSummaryToShow,
} from "@/utils/relationshipUtils";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { uniq } from "lodash";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

interface Props {
  section: ProfilePageSection;
  allGroupedExperiences: RelationshipWithEntity[][];
  hideStats?: boolean;
}

const INITIAL_COMPANIES = 3;
const COMPANY_PICS_TO_SHOW = 15;

export default function ExperienceSection({ section, allGroupedExperiences, hideStats }: Props) {
  const entityStore = useEntityStore();
  const addingProfileSections = useStore(profilePageUiStore.addingProfileSections);
  const entity = useStore(entityStore.entity);
  const snapshot = useStore(entityStore.snapshot);
  const facts = useStore(entityStore.facts);
  const [addingNewCompany, setAddingNewCompany] = useState(!!addingProfileSections[section]);
  const [loadingNewCompany, setLoadingNewCompany] = useState(false);
  const canEdit = useStore(entityStore.canEdit);
  const [expanded, setExpanded] = useState(false);
  const sidebar = useSidebar();

  const firstExperienceMinified = section === ProfilePageSection.Education;

  const allGroupedExperiencesToShow = (
    (addingNewCompany ? [[]] : []) as RelationshipWithEntity[][]
  ).concat(allGroupedExperiences);

  const skipExperienceSection =
    entity.type === EntityType.Person &&
    allGroupedExperiencesToShow.length === 0 &&
    !entity.url.includes("linkedin.com") &&
    !addingProfileSections[section];

  if (skipExperienceSection) {
    return null;
  }

  const companyPics = uniq(
    allGroupedExperiencesToShow.map((group) => group[0]?.to?.imageUrl).filter(Boolean),
  ).slice(INITIAL_COMPANIES) as string[];

  const companyInvestmentFacts: FactType[] = [];

  const experienceNoun = sectionToExperienceNoun(section);

  let titleSuffix = "";
  if (section === ProfilePageSection.Investments && !hideStats) {
    const summary = buildInvestmentSummaryToShow(allGroupedExperiences);
    titleSuffix = " • " + pluralize(summary.companiesCount, "company", "companies");

    if (entity.type === EntityType.Company) {
      const factTypes = [
        VCFact.InvestmentSectors,
        VCFact.InvestmentRounds,
        VCFact.FamousInvestments,
        VCFact.InvestmentGeographies,
      ];

      for (const factType of factTypes) {
        if (facts[factType]) {
          companyInvestmentFacts.push(factType);
        }
      }
    }
  }

  return (
    <div className="group">
      <ProfileBubble
        title={ExperienceLabels[section] + titleSuffix}
        key={section}
        section={section}
        className="flex flex-col"
        titleButtons={
          <>
            {canEdit && !addingNewCompany && !sidebar.isOpen && (
              <>
                <PlusIcon
                  className="flex-shrink-0 cursor-pointer hover:text-brand-600 h-5 w-5 text-gray-500 group-hover:block hidden"
                  onClick={() => {
                    setAddingNewCompany(true);
                  }}
                />
              </>
            )}
          </>
        }
      >
        <>
          {allGroupedExperiencesToShow.length === 0 && (
            <div className="text-center text-gray-500">No work history listed yet</div>
          )}
          {loadingNewCompany && (
            <div className="my-2 w-full flex flex-row justify-center">
              <Spinner />
            </div>
          )}
          {section === ProfilePageSection.WorkHistory && allGroupedExperiences.length > 2 && (
            <div className="mb-4">
              <WorkExperienceSummaryHeader
                summary={buildWorkExperienceSummaryToShow(allGroupedExperiences)}
              />
              <hr className="mt-4" />
            </div>
          )}

          {section === ProfilePageSection.Investments && allGroupedExperiences.length > 2 && (
            <div className="mb-4">
              <InvestmentSummaryHeader
                summary={buildInvestmentSummaryToShow(allGroupedExperiences)}
              />
              <hr className="mt-4" />
            </div>
          )}
          {section === ProfilePageSection.Investments && entity.type === EntityType.Company && (
            <>
              <div className="flex flex-wrap justify-between">
                {companyInvestmentFacts.map((type) => (
                  <FactInFactTable key={type} fact={facts[type]} type={type} snapshot={snapshot} />
                ))}
              </div>

              <div className="mt-4" />
            </>
          )}
          {allGroupedExperiencesToShow.length > 0 && (
            <div className="list-inside text-gray-700 gap-4 flex flex-col">
              {allGroupedExperiencesToShow
                .slice(0, expanded ? undefined : INITIAL_COMPANIES)
                .map((groupedExperiences: RelationshipWithEntity[], index) => (
                  <div
                    key={
                      (groupedExperiences[0]?.toId || groupedExperiences[0]?.toName || "empty") +
                      index.toString()
                    }
                  >
                    <GroupedExperienceRow
                      groupedExperiences={groupedExperiences}
                      section={section}
                      addMode={index === 0 && addingNewCompany}
                      mini={(firstExperienceMinified || index > 0) && !expanded}
                      forceExpanded={expanded}
                      onCancelAddMode={() => {
                        setAddingNewCompany(false);
                        if (addingProfileSections[section]) {
                          profilePageUiStore.endAddingProfileSection(section);
                        }
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
          {allGroupedExperiencesToShow.length > 0 && (
            <div
              className="text-blue-600 cursor-pointer mt-6 font-medium flex flex-row gap-1 items-center"
              onClick={() => setExpanded(!expanded)}
            >
              {!expanded && (
                <>
                  <span className="mr-4">
                    Expand all
                    {allGroupedExperiencesToShow.length > INITIAL_COMPANIES && (
                      <span>
                        {" and show "}
                        {pluralize(
                          allGroupedExperiencesToShow.length - INITIAL_COMPANIES,
                          `more ${experienceNoun}`,
                        )}
                      </span>
                    )}
                  </span>
                  <IconList iconUrls={companyPics} count={COMPANY_PICS_TO_SHOW} />
                </>
              )}
              {expanded && <span>Hide details</span>}
            </div>
          )}
        </>
      </ProfileBubble>
    </div>
  );
}
