import { classNames } from "@/lib/utils";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export type SelectOption<T = string> = {
  label: string;
  value: T;
  disabled?: boolean;
  className?: string;
};

type Props<T> = {
  options: SelectOption<T>[];
  value?: T;
  hideChevrons?: boolean;
  placeholder?: string;
  className?: string;
  dropdownClassName?: string;
  onChange: (value: T) => void;
};

export default function Select<T = string>({
  options,
  hideChevrons,
  value,
  onChange,
  className,
  dropdownClassName,
  placeholder,
}: Props<T>) {
  const [selected, _setSelected] = useState<T | undefined>();

  useEffect(() => {
    _setSelected(value);
  }, [options, value]);

  const setSelected = (value: string | T) => {
    _setSelected(value as T);
    onChange(value as T);
  };

  const selectedOption = options.find((o) => o.value == selected);

  return (
    <Listbox value={selected || ""} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={twMerge(
                "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900",
                className,
              )}
            >
              <span className="block truncate">{selectedOption?.label || placeholder}</span>
              {!hideChevrons && (
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={twMerge(
                  "absolute z-10 mt-1 max-h-[20rem] w-full min-w-[12rem] overflow-scroll rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm",
                  dropdownClassName,
                )}
              >
                {options.map((option, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      twMerge(
                        active ? "bg-blue-600 text-white"
                        : option.disabled ? "text-gray-400"
                        : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                        option.className,
                      )
                    }
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate",
                          )}
                        >
                          {option.label}
                        </span>

                        {selected ?
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
