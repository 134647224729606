import SourceTooltip, { USER_FEEDBACK_TOOLTIP_TITLE } from "@/components/entities/SourceTooltip";
import SourceTooltipLink, {
  getReferenceDataToShowFromCitationId,
} from "@/components/entities/SourceTooltipLink";
import HighlightsFeedbackModal from "@/components/modals/HighlightsFeedbackModal";
import ExpandoList from "@/components/ui/ExpandoList";
import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { EntityUIType, SnapshotHighlight } from "@/types";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { useSession } from "next-auth/react";
import { AnchorHTMLAttributes, HTMLAttributes } from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

export default function EntityHighlights() {
  const isDev = useStore(uiStore.isDev);
  const entityStore = useEntityStore();
  const highlights = useStore(entityStore.highlights);
  const session = useSession();

  const canGiveFeedback = session.status === "authenticated";

  if (!highlights?.length) return null;

  return isDev ?
      <div className="group">
        <div className="flex items-center gap-1"></div>
        <div className="overview markdown">
          <Bullets bullets={highlights} />
        </div>
        {canGiveFeedback && <HighlightsFeedbackModal />}
      </div>
    : <>
        <hr className="my-2" />
        <div className="group">
          <div className="flex items-center gap-1">
            <h2 className="text-gray-400 text-base">Highlights</h2>
            {canGiveFeedback && (
              <PencilIcon
                onClick={entityStore.updateHighlightsFromFeedback}
                className="ml-2 -mt-1 w-4 h-4 text-gray-400 hover:text-brand-600 hidden group-hover:block cursor-pointer"
              />
            )}
          </div>
          <div className="overview markdown">
            <Bullets bullets={highlights} />
          </div>
          {canGiveFeedback && <HighlightsFeedbackModal />}
        </div>
      </>;
}

function Bullets({ bullets }: { bullets: SnapshotHighlight[] }) {
  const entityStore = useEntityStore();
  const snapshot = useStore(entityStore.snapshot);
  if (!bullets) return null;

  return (
    <div className="flex flex-col gap-2">
      <SourceTooltip />
      <ExpandoList
        items={bullets}
        seeMoreClassName="border-t mt-4 pt-4"
        expanderText={{
          more: "View more highlights",
          less: "View fewer highlights",
        }}
        renderItem={(bullet, i) => (
          <div key={i} className="flex flex-row mb-3 items-start">
            <span className="hidden sm:inline font-bold text-xl text-gray-300 ml-4 mr-8">•</span>
            <div className="mt-[7px]">
              <ReactMarkdown
                remarkPlugins={[remarkBreaks]}
                components={{
                  strong(props: HTMLAttributes<HTMLBaseElement>) {
                    return <span className="font-medium">{props.children}</span>;
                  },
                  p(props: HTMLAttributes<HTMLParagraphElement>) {
                    return <p className="inline">{props.children}</p>;
                  },
                }}
              >
                {bullet.description + " "}
              </ReactMarkdown>
              <span className="inline-flex items-baseline">
                {bullet.citationIds?.map((citationId, j) => {
                  const referenceData = getReferenceDataToShowFromCitationId(snapshot, citationId);
                  if (!referenceData) return null;
                  const { idx, url, citationUrl } = referenceData;
                  return (
                    <SourceTooltipLink key={j} href={citationUrl} title={url}>
                      {idx}
                    </SourceTooltipLink>
                  );
                })}
                {bullet.userSources?.length ?
                  <SourceTooltipLink href="#" title={USER_FEEDBACK_TOOLTIP_TITLE}>
                    u
                  </SourceTooltipLink>
                : null}
              </span>
            </div>
          </div>
        )}
      />
    </div>
  );
}
