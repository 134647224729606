import { BoltIcon } from "@heroicons/react/24/solid";

export default function AISummaryBox({ content }: { content: string }) {
  return (
    <div className="rounded-lg p-3 bg-brand-50 flex flex-row">
      <div className="mr-2 text-justify">{content}</div>
      <div>
        <BoltIcon className={"h-4 w-4 text-brand-500"} />
      </div>
    </div>
  );
}
