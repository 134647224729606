import { IconProps } from "@/components/icons/types";

export default function PinterestIcon({ className = "", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`fill-current ${className}`}
      {...props}
    >
      <g clipPath="url(#clip0_47_368)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM3 10C3 6.13403 6.13401 3 9.99997 3C13.866 3 17 6.13403 17 10C17 13.866 13.866 17 9.99997 17C9.27835 17 8.58233 16.8906 7.92738 16.6878C8.21274 16.2229 8.63936 15.4608 8.79739 14.8528C8.88248 14.5252 9.23348 13.1887 9.23348 13.1887C9.46174 13.624 10.1286 13.9925 10.8379 13.9925C12.9492 13.9925 14.4703 12.051 14.4703 9.6385C14.4703 7.326 12.5832 5.59562 10.1549 5.59562C7.1341 5.59562 5.52974 7.62352 5.52974 9.83165C5.52974 10.8583 6.07634 12.1365 6.9506 12.5435C7.08326 12.6052 7.15418 12.578 7.18471 12.4499C7.19482 12.4074 7.22298 12.2931 7.25593 12.1592C7.29854 11.9862 7.34915 11.7807 7.37911 11.656C7.39616 11.5853 7.38778 11.5246 7.33057 11.4552C7.04137 11.1045 6.80961 10.4594 6.80961 9.85775C6.80961 8.31386 7.97868 6.81996 9.97042 6.81996C11.6901 6.81996 12.8944 7.99179 12.8944 9.66782C12.8944 11.5614 11.938 12.8733 10.6939 12.8733C10.0067 12.8733 9.49238 12.3051 9.65729 11.6084C9.72869 11.3073 9.82431 10.9976 9.91728 10.6965C10.0813 10.1654 10.237 9.661 10.237 9.27769C10.237 8.74003 9.94856 8.29166 9.35134 8.29166C8.64889 8.29166 8.08472 9.01833 8.08472 9.99168C8.08472 10.6116 8.2941 11.031 8.2941 11.031C8.2941 11.031 7.60049 13.9641 7.47327 14.5104C7.33252 15.115 7.38784 15.9668 7.449 16.5206C4.84507 15.5007 3 12.9656 3 10Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_368">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
