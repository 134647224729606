/* eslint-disable @next/next/no-img-element */
import { useState } from "react";

import AssociateSearchResultRow from "@/components/search/AssociateSearchResultRow";
import { useSearchCache } from "@/hooks/useSearchCache";
import { FoundEntity, searchStore } from "@/stores/searchStore";
import { EntityType } from "@/types";
import { useStore } from "@nanostores/react";

interface AssociatSearchResultsProps {
  onSelect: (url: string) => Promise<void>;
  hideCompanies?: boolean;
}

/**
 * This component pulls heavily from /search/SearchResults.tsx
 */
export default function AssociateSearchResults({
  onSelect,
  hideCompanies,
}: AssociatSearchResultsProps) {
  const [visiblePeople, setVisiblePeople] = useState(5);
  const [visibleCompanies, setVisibleCompanies] = useState(5);

  const entities = useStore(searchStore.entities);
  const hasMorePeople = useStore(searchStore.hasMorePeople);
  const hasMoreCompanies = useStore(searchStore.hasMoreCompanies);
  const searching = useStore(searchStore.searching);

  const people = entities
    .filter((e) => e.type == EntityType.Person)
    .sort((a, b) =>
      a.degree && b.degree ? a.degree.localeCompare(b.degree)
      : a.degree ? -1
      : 1,
    );
  const companies = hideCompanies ? [] : entities.filter((e) => e.type == EntityType.Company);

  const displayedPeople = people.slice(0, visiblePeople);
  const displayedCompanies = companies.slice(0, visibleCompanies);

  const loadMore = async (type: "people" | "companies") => {
    if (type === "people") {
      if (visiblePeople >= people.length && hasMorePeople) {
        await searchStore.loadMore("people");
        setVisiblePeople((prev) => prev + 10);
      } else {
        setVisiblePeople((prev) => Math.min(prev + 5, people.length));
      }
    } else {
      if (visibleCompanies >= companies.length && hasMoreCompanies) {
        await searchStore.loadMore("companies");
        setVisibleCompanies((prev) => prev + 10);
      } else {
        setVisibleCompanies((prev) => Math.min(prev + 5, companies.length));
      }
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {people.length > 0 && (
        <div className="">
          <h2 className="text-sm font-semibold py-2">People</h2>
          <EntityList type="people" entities={displayedPeople} onSelect={onSelect} />
          {(visiblePeople < people.length || hasMorePeople) && (
            <div
              className="text-brand-600 hover:underline p-4 flex-1 text-center cursor-pointer"
              onClick={() => loadMore("people")}
            >
              {searching ? "Searching..." : "See more people"}
            </div>
          )}
        </div>
      )}
      {companies.length > 0 && (
        <div className="">
          <h2 className="text-sm font-semibold py-2">Companies</h2>
          <EntityList type="companies" entities={displayedCompanies} onSelect={onSelect} />
          {(visibleCompanies < companies.length || hasMoreCompanies) && (
            <div
              className="text-brand-600 hover:underline p-4 flex-1 text-center cursor-pointer"
              onClick={() => loadMore("companies")}
            >
              {searching ? "Searching..." : "See more companies"}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function EntityList({
  type,
  entities,
  onSelect,
}: {
  type: string;
  entities: FoundEntity[];
  onSelect: (url: string) => Promise<void>;
}) {
  const { resolveEntityCache, uniqueEntities } = useSearchCache(type, entities);

  return (
    <ul className="flex flex-col divide-y p-0">
      {uniqueEntities.map((entity) => (
        <li key={entity.pageUrl}>
          <AssociateSearchResultRow
            foundEntity={entity}
            actualEntity={resolveEntityCache[entity.pageUrl]?.entity}
            onSelect={onSelect}
          />
        </li>
      ))}
    </ul>
  );
}
