import { EntityType } from "@/types";
import { Tag, tagId } from "./tags";

export const StartupCompanyTags = {
  Startup: {
    id: tagId("startup"),
    name: "Startup",
    description: "A startup is a company that is in the early stages of development.",
  },
} as const;

Object.values(StartupCompanyTags).forEach((tag) => {
  (tag as Tag).type = EntityType.Company;
});

export const StartupPersonTags = {
  Founder: {
    id: tagId("founder"),
    name: "Founder",
    description: "Someone who has founded a company.",
  },
};

Object.values(StartupPersonTags).forEach((tag) => {
  (tag as Tag).type = EntityType.Person;
});
