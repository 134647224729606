import { atom, map } from "nanostores";
import { ProfilePageSection } from "@/types";

class ProfilePageUiStore {
  // Set to true if user signaled intention to create an otherwise non-existent
  // profile page section. (e.g. when there are no investments, and the user wants
  // to add one).
  addingProfileSections = map<Partial<Record<ProfilePageSection, boolean>>>({});

  sourcesExpanded = atom<boolean>(false);

  beginAddingProfileSection = (section: ProfilePageSection) => {
    this.addingProfileSections.setKey(section, true);
  };

  endAddingProfileSection = (section: ProfilePageSection) => {
    this.addingProfileSections.setKey(section, undefined);
  };
}

export const profilePageUiStore = new ProfilePageUiStore();
