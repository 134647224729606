import EntityCard, {
  EntityForCard,
  makeEntityCardFromMutualConnection,
} from "@/components/cards/EntityCard";
import useSubmitButton from "@/components/hooks/useSubmitButton";
import Checkbox from "@/components/inputs/Checkbox";
import { useSidebar } from "@/components/providers/SidebarProvider";
import EntityConnectionScore from "@/components/sections/EntityConnectionScore";
import Accordion from "@/components/ui/Accordion";
import { ButtonLink, ButtonVariant } from "@/components/ui/Button";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import Spinner from "@/components/ui/Spinner";
import { prettyError } from "@/lib/miscUtils";
import { MutualConnectionsData, useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import {
  CompanyPersonRelationship,
  Entity,
  PersonCompanyRelationship,
  RelationshipWithEntity,
  User,
  UserWithMeta,
} from "@/types";
import { entityIsUser, extractImageId } from "@/utils/entityUtils";
import { getHeadcountFromFacts } from "@/utils/facts";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { JsonObject } from "@prisma/client/runtime/library";
import { uniqBy } from "lodash";
import { useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

// This is intermediate type for collecting all the employee data used in
// this file only. Please do not export.
interface EmployeeFromRelationship {
  id: string;
  title?: string;
  isImportant?: boolean;
}

const toEntityForCard = (
  employee: EmployeeFromRelationship,
  resolveCache: Record<string, Entity>,
) => {
  return {
    ...resolveCache[employee.id],
    title: employee.title ?? resolveCache[employee.id].title,
  } as EntityForCard;
};

interface Props {
  // If true will show a link that takes you to /people page
  showMoreLink?: boolean;
  // If true will show a button that lets you show more connections
  showMoreButton?: boolean;
  peopleNumberStep: number;
  // Sadly tailwind does not support dynamic grid-cols
  cols: 2 | 3;
  // If true will show the connection score unit
  showConnectionScore?: boolean;
}

export default withErrorBoundary(function CompanyPeople({
  showMoreLink,
  showMoreButton,
  peopleNumberStep,
  showConnectionScore,
  cols,
}: Props) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const user = useStore(uiStore.user);
  const smallScreen = useStore(uiStore.smallScreen);
  const relationships = useStore(entityStore.relationships);
  const facts = useStore(entityStore.facts);
  const linkedEntities = useStore(entityStore.linkedEntities);
  const mutualConnections = useStore(entityStore.mutualConnections);
  const entityLoading = useStore(entityStore.entityLoading);
  const hasCookie = useStore(uiStore.liCookieStatus) === "valid";
  // How many times "Load more" has been clicked
  const [loadMoreClicked, setLoadMoreClicked] = useState(0);
  const headcount = getHeadcountFromFacts(facts);
  const [employeeView, setEmployeeView] = useState<"current" | "former">("current");

  const sidebar = useSidebar();
  const { submitting, SubmitButton, setSubmitting } = useSubmitButton("Loading...");
  if (!relationships.length && !mutualConnections.length) {
    if (entityLoading) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  // There are 3 sources of data we can pull in employee information from.
  // 1. Work history relationships (WorkedAt, maybe later we could use InvestedAt,
  //    or similar). fromId relates to the person, toId is the company.
  // 2. Employee relationships (CurrentEmployee, FormerEmployee). fromId is the
  //    company, toId is the person.
  // 3. Mutual connections. This has different format and is created per-user.
  //
  // Relationships and MutualConnectionData have different formats, so we need
  // to do a lot of data massaging here.
  const currentEmployeesFromWorkedAtRelationships: EmployeeFromRelationship[] = relationships
    .filter(
      (relationship) =>
        (!relationship.endedDate || relationship.endedDate === "Present") &&
        relationship.type === PersonCompanyRelationship.WorkedAt &&
        relationship.toId === entity.id &&
        !!relationship.fromId &&
        !isUser(user, relationship),
    )
    .map((r) => ({
      id: r.fromId,
      title: (r.data as JsonObject)?.title as string,
      isImportant: (r.data as JsonObject)?.isImportant as boolean,
    }));

  const currentEmployeesFromCurrentEmployeeRelationships = relationships
    .filter(
      (relationship) =>
        (!relationship.endedDate || relationship.endedDate === "Present") &&
        relationship.type === CompanyPersonRelationship.CurrentEmployee &&
        relationship.fromId === entity.id &&
        !!relationship.toId &&
        !isUser(user, relationship),
    )
    .map((r) => ({
      id: r.toId,
    })) as EmployeeFromRelationship[];

  const currentEmployeesFromRelationships = uniqBy(
    currentEmployeesFromWorkedAtRelationships.concat(
      currentEmployeesFromCurrentEmployeeRelationships,
    ),
    "id",
  );

  const currentEmployeesFromRelationshipsIds = new Set(
    currentEmployeesFromRelationships.map((e) => e.id),
  );

  const formerEmployeesFromWorkedAtRelationships: EmployeeFromRelationship[] = relationships
    .filter(
      (relationship) =>
        !!relationship.endedDate &&
        relationship.endedDate !== "Present" &&
        relationship.type === PersonCompanyRelationship.WorkedAt &&
        relationship.toId === entity.id &&
        !!relationship.fromId &&
        !currentEmployeesFromRelationshipsIds.has(relationship.fromId),
    )
    .map((r) => {
      return {
        id: r.fromId,
        title: (r.data as JsonObject)?.title as string,
      };
    });

  const formerEmployeesFromFormerEmployeeRelationships: EmployeeFromRelationship[] = relationships
    .filter(
      (relationship) =>
        relationship.type === CompanyPersonRelationship.FormerEmployee &&
        relationship.fromId === entity.id &&
        !!relationship.toId &&
        !currentEmployeesFromRelationshipsIds.has(relationship.toId),
    )
    .map((r) => {
      return {
        id: r.toId,
      };
    }) as EmployeeFromRelationship[];

  const formerEmployeesFromRelationships = uniqBy(
    formerEmployeesFromWorkedAtRelationships.concat(formerEmployeesFromFormerEmployeeRelationships),
    "id",
  );
  const formerEmployeesFromRelationshipsIds = new Set(
    formerEmployeesFromRelationships.map((e) => e.id),
  );

  const currentConnectedPeople = Object.values(mutualConnections)
    .filter(
      (connection) =>
        (connection?.data?.isCurrentEmployee ||
          connection?.data?.isCurrentEmployee === undefined) &&
        !(connection?.entity?.id && formerEmployeesFromRelationshipsIds.has(connection.entity.id)),
    )
    .toSorted(compareConnection);

  const currentFirstDegreeConnection = getNthDegreeRelationships(currentConnectedPeople, "1st");
  const currentSecondDegreeConnection = getNthDegreeRelationships(currentConnectedPeople, "2nd");
  const currentOtherEmployees = getThirdPlusDegreeRelationships(currentConnectedPeople);

  const formerConnectedPeople = Object.values(mutualConnections)
    .filter(
      (connection) =>
        connection?.data?.isCurrentEmployee === false &&
        !(connection?.entity?.id && currentEmployeesFromRelationshipsIds.has(connection.entity.id)),
    )
    .toSorted(compareConnection);

  const formerFirstDegreeConnectedPeople = getNthDegreeRelationships(formerConnectedPeople, "1st");
  const formerSecondDegreeConnectedPeople = getNthDegreeRelationships(formerConnectedPeople, "2nd");
  const formerOtherEmployees = getThirdPlusDegreeRelationships(formerConnectedPeople);

  const isCurrent = employeeView === "current";
  const firstDegreeEmployeesToShow =
    isCurrent ? currentFirstDegreeConnection : formerFirstDegreeConnectedPeople;
  const secondDegreeEmployeesToShow =
    isCurrent ? currentSecondDegreeConnection : formerSecondDegreeConnectedPeople;
  const remainingEmployeesToShow = isCurrent ? currentOtherEmployees : formerOtherEmployees;

  const processedEmployeesSet = new Set(
    firstDegreeEmployeesToShow.concat(secondDegreeEmployeesToShow).map((x) => x.id),
  );

  const employeesToFilter =
    isCurrent ? currentEmployeesFromRelationships : formerEmployeesFromFormerEmployeeRelationships;
  const remainingCurrentEmployees = employeesToFilter.filter(
    (x) => !processedEmployeesSet.has(x.id),
  );

  const notConnectedEmployeesToShow = [
    ...getImportantPeople(remainingCurrentEmployees, linkedEntities),
    ...remainingEmployeesToShow,
    ...getUnImportantPeople(remainingCurrentEmployees, linkedEntities),
  ];

  const radioButtons: { id: "current" | "former"; label: string }[] = [
    { id: "current", label: "Current Employees" },
    { id: "former", label: "Former Employees" },
  ];

  const accordionData = [
    {
      title: getConnectionTitle("1ST CONNECTION DEGREE", firstDegreeEmployeesToShow),
      people: firstDegreeEmployeesToShow,
    },
    {
      title: getConnectionTitle("2ND CONNECTION DEGREE", secondDegreeEmployeesToShow),
      people: secondDegreeEmployeesToShow,
    },
    {
      title: getConnectionTitle("NOT CONNECTED", notConnectedEmployeesToShow),
      people: notConnectedEmployeesToShow,
    },
  ];

  const seenIds = new Set<string>();
  const seenNames = new Set<string>();
  const seenImageIds = new Set<string>();
  const uniquePeopleEntities: EntityForCard[] = [];

  const peopleToShow = firstDegreeEmployeesToShow
    .concat(secondDegreeEmployeesToShow)
    .concat(notConnectedEmployeesToShow);

  for (const entity of peopleToShow) {
    if (entity.imageUrl) {
      const imageId = extractImageId(entity.imageUrl);
      if (imageId) {
        if (seenImageIds.has(imageId)) {
          continue;
        }
        seenImageIds.add(imageId);
      }
    }
    if (entity.id) {
      if (!seenIds.has(entity.id)) {
        seenIds.add(entity.id);
        uniquePeopleEntities.push(entity);
      }
    } else if (entity.name) {
      // This might be a false positive, but if we have two people with
      // the same name and no id, it will be very rare for them to be two
      // different people.
      if (!seenNames.has(entity.name)) {
        seenNames.add(entity.name);
        uniquePeopleEntities.push(entity);
      }
    }
  }

  if (peopleToShow.length === 0 && headcount === 0) {
    if (entityLoading) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  let amountToShow =
    smallScreen ? 2
    : sidebar.isOpen ? 4
    : 6;

  amountToShow = hasCookie ? amountToShow : amountToShow * 2;
  const urlImages = uniquePeopleEntities.slice(0, amountToShow);
  const peopleEntities = uniquePeopleEntities.slice(0, peopleNumberStep * (loadMoreClicked + 1));
  const isThereMoreToLoad = uniquePeopleEntities.length !== headcount;
  const totalConnections = Object.values(mutualConnections).length;
  const handleFetchMore = async () => {
    if (peopleEntities.length < headcount) {
      setSubmitting(true);
      try {
        await entityStore.fetchMoreEmployeesForCompany(50);
      } catch (e) {
        toast.error("Unable to fetch more employees " + prettyError(e));
      } finally {
        setSubmitting(false);
      }
      setLoadMoreClicked((prev) => prev + 1);
    }
  };

  const gridClassName = twMerge(
    "grid grid-cols-1 gap-4",
    cols === 2 ? "md:grid-cols-2"
    : cols === 3 ? "md:grid-cols-3"
    : "",
  );

  return (
    <>
      <div className="p-4 bg-white rounded-lg shadow">
        {showMoreLink && (
          <>
            {showConnectionScore && <EntityConnectionScore className="my-2" />}

            <div className="border-t pt-4 text-sm">
              <div className="flex items-center justify-between">
                {hasCookie && (
                  <div>
                    <p className="mb-2 font-semibold">
                      Connections with employees:{" "}
                      <span className="text-blue-600">{totalConnections}</span>
                    </p>
                    <p>
                      1st degree: <strong>{currentFirstDegreeConnection.length}</strong> Current,{" "}
                      <strong>{formerFirstDegreeConnectedPeople.length}</strong> Former
                    </p>
                    <p>
                      2nd degree: <strong>{currentSecondDegreeConnection.length}</strong> Current,{" "}
                      <strong>{formerSecondDegreeConnectedPeople.length}</strong> Former
                    </p>
                  </div>
                )}

                <div className="flex space-x-2">
                  {urlImages.map((person) => {
                    return (
                      <EntityIconWithPlaceholder
                        className="h-[45px] w-[45px] shadow-inner overflow-hidden"
                        imageClassName="w-full h-full"
                        entity={person}
                        key={person.id || person.name}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}

        {showMoreButton && (
          <>
            <div className="flex space-x-6 border-b border-gray-300 pb-2">
              {radioButtons.map(({ id, label }) => {
                return (
                  <Checkbox
                    key={id}
                    name="type"
                    id="companies"
                    label={label}
                    className={"flex space-x-4 pb-2"}
                    checked={employeeView === id}
                    inputClassName={twMerge(
                      "w-5 h-5 flex items-center justify-center rounded-full border-2",
                      employeeView === id ? "border-blue-600" : (
                        "border-gray-300 bg-white hover:border-gray-400 hover:bg-gray-100"
                      ),
                    )}
                    labelClassName={twMerge(
                      "flex items-center cursor-pointer text-sm font-medium",
                      employeeView === id ? "text-blue-600" : "text-gray-600",
                    )}
                    radio
                    onChange={() => setEmployeeView(id)}
                  ></Checkbox>
                );
              })}
            </div>

            {accordionData.map(({ title, people }) => (
              <Accordion
                key={title}
                title={title}
                contentClassName={gridClassName}
                defaultExpanded={people.length > 0}
              >
                {people.length > 0 ?
                  people.map((person) => (
                    <EntityCard key={person.id || person.name} entity={person} />
                  ))
                : <div>No people found in this category</div>}
              </Accordion>
            ))}
          </>
        )}
      </div>

      {showMoreLink && (
        <ButtonLink
          className="mt-2 w-full text-brand-800 font-semibold text-center"
          variant={ButtonVariant.Clear}
          href={`${entity.slug}/people`}
          target="_blank"
        >
          See people and how you&apos;re connected
          <ArrowRightIcon className="w-4 h-4 ml-2 inline" />
        </ButtonLink>
      )}

      {showMoreButton && isThereMoreToLoad && (
        <SubmitButton
          className="my-2 w-full text-brand-800 font-semibold"
          variant={ButtonVariant.Clear}
          onClick={() => handleFetchMore()}
        >
          View more people
        </SubmitButton>
      )}
    </>
  );
});

// Should a relationship utils be made incase this functionality will be used else where?
function compareConnection(a: MutualConnectionsData, b: MutualConnectionsData) {
  const degreeA = parseInt(a.data?.degree || "") || 5;
  const degreeB = parseInt(b.data?.degree || "") || 5;
  return degreeA - degreeB;
}

function getNthDegreeRelationships(connectedPeople: MutualConnectionsData[], degree: string) {
  return connectedPeople
    .filter((c) => c.data?.degree === degree)
    .map((e) => makeEntityCardFromMutualConnection(e));
}

function getThirdPlusDegreeRelationships(connectedPeople: MutualConnectionsData[]) {
  return connectedPeople
    .filter((c) => c.data?.degree !== "1st" && c.data?.degree !== "2nd")
    .map((e) => makeEntityCardFromMutualConnection(e));
}

function getImportantPeople(
  employees: EmployeeFromRelationship[],
  linkedEntities: Record<string, Entity>,
) {
  return employees.filter((e) => e.isImportant).map((e) => toEntityForCard(e, linkedEntities));
}

// This function sounds mean
function getUnImportantPeople(
  employees: EmployeeFromRelationship[],
  linkedEntities: Record<string, Entity>,
) {
  return employees.filter((e) => !e.isImportant).map((e) => toEntityForCard(e, linkedEntities));
}

function getConnectionTitle(title: string, employees: EntityForCard[]) {
  return `${title} (${employees.length > 100 ? "100+" : employees.length})`;
}

function isUser(user: UserWithMeta | null, relationship: RelationshipWithEntity) {
  if (!user) {
    return false;
  }

  for (const entity of [relationship.from, relationship.to]) {
    if (!entity) {
      continue;
    }

    if (entityIsUser(entity, user)) {
      return true;
    }
  }

  return false;
}
