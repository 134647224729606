import Button, { ButtonVariant } from "@/components/ui/Button";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

interface AccordionProps {
  title: string;
  children: ReactNode;
  contentClassName?: string;
  defaultExpanded?: boolean;
}

export default function Accordion({
  title,
  children,
  contentClassName,
  defaultExpanded = false,
}: AccordionProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <div className="border-b border-gray-300">
      <Button
        variant={ButtonVariant.Clear}
        className="flex justify-between items-center w-full text-left font-semibold text-sm text-gray-500 uppercase py-2"
        onClick={() => setExpanded(!expanded)}
      >
        {title}
        {expanded ?
          <ChevronUpIcon className="w-5 h-5 text-gray-500" />
        : <ChevronDownIcon className="w-5 h-5 text-gray-500" />}
      </Button>
      {expanded && (
        <div className={twMerge("pt-2 pb-4 text-gray-700 text-sm", contentClassName)}>
          {children}
        </div>
      )}
    </div>
  );
}
