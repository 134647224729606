"use client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import useAutosizeTextArea from "@/components/hooks/useAutosizeTextArea";
import TextArea from "@/components/inputs/TextArea";
import { messageStore } from "@/stores/messageStore";
import { useStore } from "@nanostores/react";
import { useRouter as usePagesRouter } from "next/compat/router";
import { useRouter } from "next/navigation";

export default function ChatInput({
  createNewSearchOnSubmit,
  variant = "default",
}: {
  createNewSearchOnSubmit?: boolean;
  variant?: "default" | "entity";
}) {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const inProgress = useStore(messageStore.inProgress);
  const currentMessage = useStore(messageStore.currentMessage);
  const fakeMessage = useStore(messageStore.fakeMessage);

  const textAreaHeight = variant === "entity" ? 30 : 100;

  useEffect(() => {
    if (currentMessage) {
      ref.current?.focus();
    }
  }, [currentMessage]);

  useEffect(() => {
    //ref.current?.focus();
  }, []);

  // Add typing simulation when fakeMessage changes
  useEffect(() => {
    if (!fakeMessage || !ref.current) return;

    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fakeMessage.length) {
        // Directly set the textarea value
        if (ref.current) {
          ref.current.value = fakeMessage.slice(0, currentIndex);
          // Trigger the onChange to keep React state in sync
          ref.current.dispatchEvent(new Event("input", { bubbles: true }));
        }
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        messageStore.onFakeMessageDone?.();
      }
    }, 50);

    return () => clearInterval(typingInterval);
  }, [fakeMessage]);

  const send = useCallback(() => {
    if (!ref.current) return;
    void messageStore.sendCurrentMessage(true);
  }, []);

  useAutosizeTextArea(ref.current, currentMessage?.content || "", textAreaHeight);
  const firstMessage = useStore(messageStore.messages).length == 0;

  const placeholder =
    inProgress ? "Sending..."
    : firstMessage ? "Describe what you want to find"
    : "Type your reply";

  return (
    <div className="">
      <div
        className={`${variant === "entity" ? "border border-brand-300 flex items-center p-1" : "bg-gray-100"} rounded relative`}
      >
        <TextArea
          value={currentMessage?.content || ""}
          onChange={(e) => {
            messageStore.setCurrentMessageContent(ref.current?.value || "");
          }}
          placeholder={placeholder}
          disabled={inProgress}
          ref={ref}
          className={`bg-white w-full focus:ring-0 focus-visible:ring-0 rounded text-sm ${variant === "entity" ? "resize-none border-none" : `h-[${textAreaHeight}px] min-h-[${textAreaHeight}px] sm:min-h-[3rem] border border-brand-300 p-4`}`}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              messageStore.setCurrentMessageContent(ref.current?.value || "");
              send();
            }
          }}
        />
        {variant === "entity" && (
          <button
            onClick={send}
            className="bg-brand-600 text-white h-10 w-[107px] p-2 rounded disabled:opacity-50 flex-shrink-0"
          >
            Ask AI
          </button>
        )}
      </div>
    </div>
  );
}
